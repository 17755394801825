import { ApiError, api } from '@/lib/api-client.ts';
import { UserAppointmentDto } from '@/models/call-system/dtos/user-appointment-dto.ts';
import CallSystemUser from '@/models/call-system/models/CallSystemUser.ts';

import { ServiceUrl } from '@/services/ServiceURL.ts';

import { getCustomerId } from '@/utils/customerUtils.ts';

export const updateUserByNumber = async (
    user_info: UserAppointmentDto
): Promise<[boolean, CallSystemUser | ApiError | undefined]> => {
    const config = {
        headers: {
            customer_id: getCustomerId(),
        },
    };
    try {
        const res = await api.post<CallSystemUser>(
            `${ServiceUrl.PATIENT_API_DOMAIN.SECURE_ADMIN.CALL_SYSTEM_API}/users`,
            user_info,
            config
        );
        console.debug(res);
        return [true, res.data];
    } catch (error) {
        return [false, error as ApiError];
    }
};

export const getExtAuthToken = async (instance_id: string): Promise<[boolean, string | undefined]> => {
    const config = {
        headers: {
            customer_id: getCustomerId(),
        },
    };

    try {
        const response = await api.get(
            `${ServiceUrl.PATIENT_API_DOMAIN.SECURE_ADMIN.CALL_SYSTEM_API}/ext/auth?instance_id=${instance_id}`,
            config
        );
        return [true, response.data?.token];
    } catch (error) {
        console.debug(error);
        return [false, undefined];
    }
};
