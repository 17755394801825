// TODO: Edit and Resuse AppointmentDetails component instead of this component
import { Insurance, Priority } from '@/models/appointment/Appointment.ts';
import { AppointmentType, Practitioner, UnMappedScheduleType } from '@/models/appointment/EnrichedPractitionerData.ts';
import { ActionIcon, Tooltip } from '@mantine/core';
import { motion } from 'framer-motion';
import { Pencil, Trash2 } from 'lucide-react';
import { FC, useMemo, useState } from 'react';

import EternoBadge from '@/components/EternoBadge.tsx';
import OverlaySection from '@/components/OverlaySection.tsx';
import PriorityMarker from '@/components/PriorityMarker.tsx';
import Paragraph from '@/components/text/Paragraph.tsx';

import { dayjs } from '@/utils/dayjsSetup.ts';

import DeleteAppointment from './DeleteAppointment.tsx';

// TODO: reuse an existing type
export interface CollapsedAppointmentData {
    isMapped: boolean;
    practitioner: Practitioner;
    appointmentType?: AppointmentType;
    unmappedScheduleType?: UnMappedScheduleType;
    dateTime: string;
    duration: number;
    priority: Priority;
    insurance: Insurance;
    notes: string;
}

interface Props {
    appointment?: CollapsedAppointmentData;
    onEdit: () => void;
    appointmentCount?: string;
    onDelete: () => void;
    disableEdit: boolean;
    disableCancel: boolean;
}

const CollapsedAppointment: FC<Props> = ({
    appointment,
    onEdit,
    appointmentCount,
    onDelete,
    disableEdit,
    disableCancel,
}) => {
    const [show_delete, setShowDelete] = useState(false);
    const formatted_date = useMemo(() => dayjs(appointment?.dateTime).format('DD.MM.YY'), [appointment?.dateTime]);
    const formatted_time = useMemo(() => dayjs(appointment?.dateTime).format('HH:mm'), [appointment?.dateTime]);

    const appointment_type = appointment?.appointmentType?.name || appointment?.unmappedScheduleType?.name;

    if (!appointment) {
        return <div>No Data available</div>;
    }

    return (
        <OverlaySection className="relative overflow-hidden shadow-sm">
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2, delay: 0.3 }}
                className="w-full"
            >
                <PriorityMarker priority={appointment.priority} />
                <div className="flex w-full items-center justify-between">
                    <div className="flex flex-col gap-2">
                        <div className="flex items-center gap-2">
                            <Paragraph className="text-lg text-slate-500">
                                {`(${appointment.insurance}) ${appointment_type} - ${appointment.duration} min`}
                            </Paragraph>
                            {appointmentCount && (
                                <Paragraph className="inline-block w-auto whitespace-nowrap text-sm text-slate-500">
                                    {appointmentCount}
                                </Paragraph>
                            )}
                            <EternoBadge text={appointment.priority ?? ''} size="lg" backgroundColor="red" />
                        </div>
                        <Paragraph>
                            {/*TODO: use practitioner name*/}
                            {formatted_date} um {formatted_time} mit {appointment.practitioner.name}
                        </Paragraph>
                        {appointment.notes && <div className="text-sm italic text-slate-400">{appointment.notes}</div>}
                    </div>
                    <div className="flex items-center gap-2">
                        {!disableCancel && (
                            <ActionIcon
                                variant="transparent"
                                aria-label="Delete Appointment"
                                onClick={() => setShowDelete(true)}
                                className="center"
                            >
                                <Trash2 className="size-6 cursor-pointer text-red-600" />
                            </ActionIcon>
                        )}

                        {/* TODO: i18n + correct test on tooltip */}
                        <Tooltip disabled={!disableEdit} label="Finish to edit the open form 1st">
                            <ActionIcon
                                disabled={disableEdit}
                                variant="transparent"
                                aria-label="Edit Appointment"
                                onClick={onEdit}
                                className="center disabled:bg-transparent disabled:opacity-40"
                            >
                                <Pencil className="size-6 text-black" />
                            </ActionIcon>
                        </Tooltip>
                    </div>
                </div>
                {show_delete && <DeleteAppointment onDelete={onDelete} onCancel={() => setShowDelete(false)} />}
            </motion.div>
        </OverlaySection>
    );
};

export default CollapsedAppointment;
