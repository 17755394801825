export const isLocalEnvironment = (): boolean => {
    return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
};

export const isDevEnvironment = (): boolean => {
    return import.meta.env.VITE_ENVIRONMENT === 'dev';
};

export const isProdEnvironment = (): boolean => {
    return import.meta.env.VITE_ENVIRONMENT === 'prod';
};

export const isEternoDomain = (): boolean => window.location.hostname.includes(import.meta.env.VITE_ETERNO_DOMAIN);

export const isCorrectWhiteLabelDomain = (domain: string): boolean => window.location.hostname.includes(domain);
