import { Chip, ChipProps, Tooltip } from '@mantine/core';
import { FC } from 'react';

interface Props extends Omit<ChipProps, 'children'> {
    label: string;
}

const EternoChip: FC<Props> = ({ label, ...props }) => {
    return (
        <Tooltip label={label} multiline position="top">
            <div>
                <Chip {...props} size="md">
                    <div className="max-w-[200px] truncate text-sm font-medium">{label}</div>
                </Chip>
            </div>
        </Tooltip>
    );
};

export default EternoChip;
