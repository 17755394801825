import { GetAppointmentsForTodayDto } from '@/models/appointment/GetAppointmentsForTodayDto.ts';
import useSWR from 'swr';

import useConfig from '@/hooks/useConfig.ts';

import { fetchAppointmentsForToday } from '@/services/appointmentService.ts';

const useAppointmentsForToday = (): { data?: GetAppointmentsForTodayDto; is_loading: boolean } => {
    const { customer_id, instance_id: doc_cirrus_instance_id } = useConfig();
    const cache_key = `todays-appointment-cached-data-by-customer-id-${customer_id}-dc-instance-id-${doc_cirrus_instance_id}`;

    const fetchTodaysAppointmentData = async (
        customer_id: string,
        dc_instance_id: string
    ): Promise<GetAppointmentsForTodayDto> => {
        const raw_data: GetAppointmentsForTodayDto = await fetchAppointmentsForToday(customer_id, dc_instance_id);
        return raw_data;
    };

    const { data, isLoading: is_loading } = useSWR<GetAppointmentsForTodayDto>(cache_key, () =>
        fetchTodaysAppointmentData(customer_id, doc_cirrus_instance_id)
    );

    return { data: data, is_loading: is_loading };
};

export default useAppointmentsForToday;
