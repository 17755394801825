import { Appointment, Insurance } from '@/models/appointment/Appointment.ts';

export const getPatientAppUrl = () => {
    return `https://${import.meta.env.DEV ? 'dev.' : ''}patients.eterno-health.io`;
};

export const appointmentPatient = (appointment: Appointment): string | undefined => {
    return appointment.user_id && appointment.user_id !== '-' ? appointment.user_id : appointment.data?.generated_id;
};

// insurance_coverage_type being returned as lowercase on dev while it's returned as uppercase on prod
export const getInsuranceByKey = (key?: string): Insurance | undefined => {
    if (!key) return undefined;

    return Insurance[key.toUpperCase() as keyof typeof Insurance];
};
