import { ApiError, api } from '@/lib/api-client.ts';
import https from 'https';

export const printPcsTicket = async (
    auth_token: string,
    endpoint: string,
    certificate_text: string
): Promise<[boolean, string | ApiError]> => {
    try {
        const response = await api.get(`${import.meta.env.VITE_PROTOCOL}://${endpoint}/`, {
            httpsAgent: new https.Agent({
                rejectUnauthorized: false,
                cert: certificate_text,
            }),
            headers: {
                token: auth_token,
            },
        });
        console.debug(response);
        return [true, response.data?.id];
    } catch (error) {
        const api_error = error as ApiError;
        console.debug(api_error);
        return [false, api_error];
    }
};

export const isPrinterLive = async (endpoint: string): Promise<boolean> => {
    try {
        const response = await api.get(`${import.meta.env.VITE_PROTOCOL}://${endpoint}/`, {
            timeout: 1000,
        });
        console.debug({ response });
        return true;
    } catch (error) {
        console.debug(error);
        return false;
    }
};
