import { CloseButton, Combobox, FocusTrap, Input, InputWrapper, useCombobox } from '@mantine/core';
import { FC, useEffect, useState } from 'react';

interface Props {
    autoFocusActive: boolean;
    data: PractitionerData[];
    defaultCalendarDocCirrusId?: string;
    label: string;
    setSelectedCalendarId: (value: string | undefined) => void;
}

interface PractitionerData {
    id: string;
    name: string;
    calendars: {
        doc_cirrus_id: string;
        doc_cirrus_name: string;
    }[];
}

export const CustomCalendarSelect: FC<Props> = ({
    data,
    defaultCalendarDocCirrusId,
    label,
    setSelectedCalendarId,
    autoFocusActive = false,
}) => {
    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
    });

    const [value, setValue] = useState<string | undefined>('');
    const [search, setSearch] = useState<string>('');
    const [filtered_groups, setFilteredGroups] = useState<PractitionerData[]>([]);
    const [total_options, setTotalOptions] = useState<number>(0);

    useEffect(() => {
        if (search && search !== '') {
            // Ensure searched value are part of data, data might change and we need to re-validate
            try {
                getCalendarIdFromName(data, search);
            } catch {
                // Provide feedback -> needs to change search
                setSearch('');
                setValue(undefined);
                setSelectedCalendarId(undefined);
            }
        }

        if (defaultCalendarDocCirrusId && !value && !search) {
            // If only 1 calendar set the professional name value otherwise the matched calendar name
            data.forEach((p) => {
                const matched_calendar = p.calendars.find((c) => c.doc_cirrus_id === defaultCalendarDocCirrusId);
                if (matched_calendar) {
                    if (p.calendars.length > 1) {
                        setSearch(matched_calendar.doc_cirrus_name);
                        return;
                    } else {
                        setSearch(p.name);
                        return;
                    }
                }
            });
        }
    }, [data]);

    // Given a calendar name or a practitioner name (when only 1 calendar available)
    // Return id practitioner, and calendar
    const getCalendarIdFromName = (
        data: PractitionerData[],
        name: string
    ): [calendar_id: string, practitioner_id: string] => {
        for (const practitioner of data) {
            if (practitioner.calendars.length === 1) {
                // Match practitioner name
                if (name === practitioner.name) {
                    return [practitioner.calendars[0].doc_cirrus_id, practitioner.id];
                }
            } else {
                // Match calendar name
                const matched_calendar = practitioner.calendars.find((calendar) => calendar.doc_cirrus_name === name);
                if (matched_calendar) {
                    return [matched_calendar.doc_cirrus_id, practitioner.id];
                }
            }
        }

        // Throw error if no match is found
        throw new Error(`Calendar with name "${name}" not found`);
    };

    // Search into calendar name or practitioner when only 1 calendar is available
    useEffect(() => {
        const new_filtered_groups = data.reduce<PractitionerData[]>((acc, practitioner) => {
            if (practitioner.calendars.length > 1) {
                const filtered_options = practitioner.calendars.filter((item) =>
                    item.doc_cirrus_name.toLowerCase().includes(search.toLowerCase().trim())
                );

                if (filtered_options.length > 0) {
                    acc.push({ ...practitioner, calendars: filtered_options });
                }
            } else {
                // if 1 calendar only, search into practitioner name
                if (practitioner.name.toLowerCase().includes(search.toLowerCase().trim())) {
                    acc.push(practitioner);
                }
            }

            return acc; // Return the accumulator
        }, []);

        setFilteredGroups(new_filtered_groups);
        setTotalOptions(new_filtered_groups.length);
    }, [data, search, defaultCalendarDocCirrusId]);

    // TODO: memo when logic is fixed
    const groups = () => {
        return (
            <>
                {filtered_groups.map((practitioner) => {
                    if (practitioner.calendars.length > 1) {
                        // Render a grouped Combobox
                        return (
                            <Combobox.Group
                                classNames={{
                                    groupLabel: 'text-sm font-semibold text-black',
                                }}
                                label={practitioner.name}
                                key={practitioner.name}
                            >
                                {practitioner.calendars.map((calendar) => (
                                    <Combobox.Option
                                        classNames={{ option: 'ml-2' }}
                                        value={calendar.doc_cirrus_name}
                                        key={calendar.doc_cirrus_id}
                                    >
                                        <div className="text-sm">{calendar.doc_cirrus_name}</div>
                                    </Combobox.Option>
                                ))}
                            </Combobox.Group>
                        );
                    }

                    // Render ungrouped Combobox.Options
                    return (
                        <Combobox.Option
                            className="text-sm font-semibold text-black"
                            value={practitioner.name}
                            key={practitioner.calendars[0].doc_cirrus_id}
                        >
                            {practitioner.name}
                        </Combobox.Option>
                    );
                })}
            </>
        );
    };

    return (
        <FocusTrap active={autoFocusActive}>
            <InputWrapper label={label}>
                <Combobox
                    store={combobox}
                    withinPortal={false}
                    onOptionSubmit={(val) => {
                        setValue(val);
                        const [calendar_id] = getCalendarIdFromName(data, val);
                        setSelectedCalendarId(calendar_id); // TODO: change to support practitioner selection as well
                        setSearch(val);
                        combobox.closeDropdown();
                    }}
                >
                    <Combobox.Target>
                        <Input
                            defaultValue={value}
                            value={search}
                            onChange={(event) => {
                                combobox.openDropdown();
                                combobox.updateSelectedOptionIndex();
                                setSearch(event.currentTarget.value);
                            }}
                            onClick={() => combobox.openDropdown()}
                            onFocus={() => combobox.openDropdown()}
                            onBlur={() => {
                                combobox.closeDropdown();
                                setSearch(value || '');
                            }}
                            placeholder="Search value"
                            rightSection={
                                value !== null ? (
                                    <CloseButton
                                        size="sm"
                                        onMouseDown={(event) => event.preventDefault()}
                                        onClick={() => {
                                            setValue(undefined);
                                            setSelectedCalendarId(undefined);
                                            setSearch('');
                                            combobox.closeDropdown();
                                        }}
                                        aria-label="Clear value"
                                    />
                                ) : (
                                    <Combobox.Chevron />
                                )
                            }
                            rightSectionPointerEvents={value === null ? 'none' : 'all'}
                        />
                    </Combobox.Target>

                    <Combobox.Dropdown className="shadow-sm">
                        <Combobox.Options>
                            {total_options > 0 ? groups() : <Combobox.Empty>Nothing found</Combobox.Empty>}
                        </Combobox.Options>
                    </Combobox.Dropdown>
                </Combobox>
            </InputWrapper>
        </FocusTrap>
    );
};
