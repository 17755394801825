interface CallSystemUser {
    group: string;
    number: string;
    name: string;
    user_id: string;
    appointment_id: string;
    location_key: string;
    sub_loc_key: string;
    user_status: UserStatus;
    day: string;

    customer_id: string;
    instance_id: string;

    appointment: {
        id: string;
        doctor_name: string;
        scheduled_start_time: string;
        scheduled_end_time: string;
        checkin_time: string;
        checkin_status?: CheckinStatus;
    };
    meta: {
        created_at: string;
        updated_at: string;
    };
}

export enum CheckinStatus {
    PENDING = 'pending',
    ARRIVED = 'arrived',
    CHECKED_IN = 'checked_in',
    MANUALLY_CHECKED_IN = 'manually_checked_in',
}

export enum UserStatus {
    KNOWN = 'known',
    UNKNOWN = 'unknown',
}

export default CallSystemUser;
