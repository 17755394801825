import { randomId } from '@mantine/hooks';

export const cards = [
    {
        id: randomId(),
        time: '12:40',
        name: 'John Doe',
        type: 'Consultation',
        practitioner: 'Dr. Susan Tolkemitt',
    },
    {
        id: randomId(),
        time: '12:45',
        name: 'Max Mustermann',
        type: 'Physiotherapy session',
        practitioner: 'Practitioner name',
    },
    {
        id: randomId(),
        time: '12:50',
        name: 'Jane Doe',
        type: 'Initial visit',
        practitioner: 'Dr. Susan Tolkemitt',
    },
];
