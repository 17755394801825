export enum DocumentCodeErrorResponse {
    INVALID_OR_EXPIRED = 'invalid_or_expired',
    EXPIRED_CODE = 'expired_code',
    MISSING_DATA = 'missing_data',
    WAIT_TO_RESEND = 'wait_to_resend',
}

export interface ErrorResponseDto {
    error_code: DocumentCodeErrorResponse;
    error_msg: string;
}
