import { CheckListItem } from '@/models/CheckListItem.tsx';
import { Checkbox, ScrollArea, Tooltip } from '@mantine/core';
import { UseListStateHandlers } from '@mantine/hooks';
import { FC, useMemo } from 'react';

import Heading2 from '@/components/text/Heading2.tsx';

interface Props {
    title?: string;
    allSelectedLabel?: string;
    values: CheckListItem[];
    handlers: UseListStateHandlers<CheckListItem>;
}

const ListSelection: FC<Props> = ({ title, allSelectedLabel, values, handlers }: Props) => {
    const all_checked = useMemo(() => values.every((value) => value.checked), [values]);
    const indeterminate = useMemo(() => values.some((value) => value.checked) && !all_checked, [values, all_checked]);

    const items = useMemo(
        () =>
            values.map((value, index) => (
                <Tooltip label={value.label} key={value.key} refProp="rootRef">
                    <Checkbox
                        classNames={{
                            label: 'truncate', // TODO: make it better, issue is the max width that exceed parents component
                        }}
                        label={value.label}
                        key={value.key}
                        checked={value.checked}
                        onChange={(event) => handlers.setItemProp(index, 'checked', event.currentTarget.checked)}
                    />
                </Tooltip>
            )),
        [values, handlers]
    );

    return (
        <div className="flex h-full flex-col gap-4">
            {title && <Heading2>{title}</Heading2>}
            <ScrollArea scrollbars="y" type="auto">
                <div className="flex flex-col gap-2">
                    <Checkbox
                        checked={all_checked}
                        indeterminate={indeterminate}
                        label={allSelectedLabel}
                        onChange={() =>
                            handlers.setState((current) =>
                                current.map((value) => ({ ...value, checked: !all_checked }))
                            )
                        }
                    />
                    {items}
                </div>
            </ScrollArea>
        </div>
    );
};

export default ListSelection;
