import { ColumnType, EnrichedDCConfigPlusRooms, KanbanColumn } from '@/models/RoomView.ts';
import { Appointment } from '@/models/appointment/Appointment.ts';
import { CheckInDataMap } from '@/models/call-system/dtos/user-appointment-dto.ts';
import { Button, ComboboxItem, Loader, ScrollArea } from '@mantine/core';
import { FC, useMemo, useState } from 'react';

import AppointmentScheduler from '@/components/AppointmentScheduler/AppointmentScheduler.tsx';
import Clock from '@/components/Clock.tsx';
import DroppableColumn from '@/components/DroppableColumn.tsx';
import ListViewHeader from '@/components/ListView/ListViewHeader.tsx';
import { ListViewMode } from '@/components/ListView/ListViewMode.ts';
import Page from '@/components/Page.tsx';
import PatientModal from '@/components/PatientModal/PatientModal.tsx';
import WalkInModal from '@/components/WalkInModal.tsx';
import Heading1 from '@/components/text/Heading1.tsx';

import useAppointmentsForToday from '@/hooks/useAppointmentsForToday.ts';
import useCheckInData from '@/hooks/useCheckInData.ts';

import { useConfigStore } from '@/stores/configStore.ts';

import { generateColumns, populateColumnsWithAppointments } from '@/utils/roomViewUtils.ts/column.ts';

const practitioner_options: ComboboxItem[] = [
    {
        value: '',
        label: 'Alle Behandler:in',
    },
    {
        value: 'nicht',
        label: 'nicht Behandler',
    },
];
const room_options: ComboboxItem[] = [
    {
        label: 'zimmer 1',
        value: '1',
    },
    {
        label: 'zimmer 2',
        value: '2',
    },
];

const RoomViewPage: FC = () => {
    const [walk_in_modal_opened, setWalkInModalOpened] = useState<boolean>(false);
    const [is_appointment_scheduler_open, setIsAppointmentSchedulerOpen] = useState<boolean>(false);
    const [open_patient_modal, setOpenPatientModal] = useState<boolean>(false);
    const [selected_appointment, setSelectedAppointment] = useState<Appointment | null>(null);

    const { data: appointments_data, is_loading } = useAppointmentsForToday();
    const { check_in_data } = useCheckInData(
        appointments_data ? [...appointments_data.data.active, ...appointments_data.data.inactive] : []
    );

    const selected_location = useConfigStore((state) => state.selected_location);
    const rooms = (selected_location?.config as EnrichedDCConfigPlusRooms)?.rooms;

    const initial_columns = generateColumns(rooms);

    const [collapsed_map, setCollapsedMap] = useState<Map<string, boolean>>(
        new Map(Array.from(initial_columns.keys()).map((key) => [key, false]))
    );

    const openPatientCard = (appointment: Appointment) => {
        setSelectedAppointment(appointment);
        setOpenPatientModal(true);
    };

    // TODO: optimize when drag and drop
    const filled_columns: Map<string, KanbanColumn> | undefined = useMemo(() => {
        if (appointments_data?.data) {
            return populateColumnsWithAppointments(appointments_data.data, initial_columns);
        }

        return undefined;
    }, [appointments_data, rooms]);

    const toggleCollapse = (id: string) => {
        setCollapsedMap((prev) => {
            const new_map = new Map(prev);
            new_map.set(id, !prev.get(id));
            return new_map;
        });
    };

    const renderColumn = (column_type: ColumnType, collapsable: boolean, check_in_data: CheckInDataMap) => {
        if (!filled_columns) {
            return undefined;
        }

        return Array.from(filled_columns.values())
            .filter((col) => col.type === column_type)
            .map((col) => (
                <DroppableColumn
                    key={col.id}
                    column={col}
                    collapsed={collapsable ? collapsed_map.get(col.id) : undefined}
                    toggleCollapse={collapsable ? toggleCollapse : undefined}
                    collapsable={collapsable}
                    onAppointmentClick={openPatientCard}
                    checkInData={check_in_data}
                />
            ));
    };

    const first_column = useMemo(() => {
        return renderColumn(ColumnType.EXPECTED, false, check_in_data);
    }, [filled_columns, collapsed_map, check_in_data]);

    const second_column = useMemo(() => {
        return <div className="flex flex-col gap-4">{renderColumn(ColumnType.WAITING_ROOM, true, check_in_data)}</div>;
    }, [filled_columns, collapsed_map, check_in_data]);

    const third_column = useMemo(() => {
        return (
            <div className="flex flex-col gap-4">
                {renderColumn(ColumnType.TREATMENT_ROOM, false, check_in_data)}
                {renderColumn(ColumnType.DISCHARGED, true, check_in_data)}
            </div>
        );
    }, [filled_columns, collapsed_map, check_in_data]);

    return (
        <Page className="gap-10 p-10">
            {/* TODO: create dedicated reusable component for this page and the list view page */}
            <div className="flex w-full items-center">
                <Heading1 className="flex-grow text-4xl font-semibold">Termine</Heading1>
                <Clock />
                <Button onClick={() => setWalkInModalOpened(true)}>Termin erstellen</Button>
            </div>
            <div className="w-full">
                {/* TODO: create dedicated reusable component for this page and the list view page */}
                <ListViewHeader
                    practitionerOptions={practitioner_options}
                    roomOptions={room_options}
                    defaultView={ListViewMode.KANBAN}
                />
            </div>
            {is_loading ? (
                <Loader />
            ) : (
                <div className="flex w-full gap-3">
                    <ScrollArea
                        scrollHideDelay={0}
                        classNames={{ root: 'h-[70vh] basis-1/5', scrollbar: 'opacity-10' }}
                    >
                        {first_column}
                    </ScrollArea>
                    <ScrollArea
                        scrollHideDelay={0}
                        classNames={{ root: 'h-[70vh] basis-1/5', scrollbar: 'opacity-10' }}
                    >
                        {second_column}
                    </ScrollArea>
                    <ScrollArea
                        scrollHideDelay={0}
                        classNames={{ root: 'h-[70vh] basis-3/5', scrollbar: 'opacity-10' }}
                    >
                        {third_column}
                    </ScrollArea>
                </div>
            )}
            <WalkInModal
                opened={walk_in_modal_opened}
                onClose={() => setWalkInModalOpened(false)}
                onConfirm={() => setIsAppointmentSchedulerOpen(true)}
            />
            <AppointmentScheduler
                opened={is_appointment_scheduler_open}
                onClose={() => setIsAppointmentSchedulerOpen(false)}
            />
            {selected_appointment && (
                <PatientModal
                    opened={open_patient_modal}
                    onClose={() => setOpenPatientModal(false)}
                    appointment={selected_appointment}
                />
            )}
        </Page>
    );
};

export default RoomViewPage;
