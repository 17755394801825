import { isDevEnvironment } from '@/utils/envUtils.ts';

const use_dev_prefix: boolean = isDevEnvironment();

const patients_base_url = `https://api.${use_dev_prefix ? 'dev.' : ''}patients.eterno-health.io`;
const practitioners_base_url = `https://api.${use_dev_prefix ? 'dev.' : ''}practitioners.eterno-health.io`;

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ServiceUrl = {
    PRACTITIONER_API_DOMAIN: {
        SECURE_ADMIN: {
            USER: {
                FORM_DATA: `${practitioners_base_url}/secure-admin/user/form-data`,
                AVAILABILITY_CACHED_DATA: `${practitioners_base_url}/secure-admin/users/availability/cached-data`,
            },
        },
    },
    PATIENT_API_DOMAIN: {
        SECURE_ADMIN: {
            CALL_SYSTEM_API: `${patients_base_url}/secure-admin/call-system`,
            USER: {
                FORM_DATA: `${patients_base_url}/secure-admin/user/form-data`,
                DOCUMENTS_DATA: `${patients_base_url}/secure-admin/user/documents`,
                GET_APPOINTMENTS_FOR_TODAY: `${patients_base_url}/secure-admin/user/appointments/today`,
            },
        },
    },
};
