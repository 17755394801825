import { Insurance, Priority } from '@/models/appointment/Appointment.ts';
import { AppointmentBookingFormValues } from '@/models/appointment/AppointmentBookingFormValues.ts';
import { Button } from '@mantine/core';
import { randomId, useListState } from '@mantine/hooks';
import { AnimatePresence, motion } from 'framer-motion';
import { Plus } from 'lucide-react';
import { FC, useEffect, useMemo, useState } from 'react';

import BaseModal from '@/components/BaseModal.tsx';

import useAppointmentBookingFormOptions from '@/hooks/useEnrichedPractitionerData.ts';

import { dayjs } from '@/utils/dayjsSetup.ts';
import { filterEnrichedDataBySubLocationId } from '@/utils/practitioner/cachedDataUtils.ts';

import AppointmentForm from './AppointmentForm.tsx';
import CollapsedAppointment, { CollapsedAppointmentData } from './CollapsedAppointment.tsx';

interface Props {
    opened: boolean;
    onClose: () => void;
}

const initial_values: AppointmentBookingFormValues = {
    sub_location_doc_cirrus_id: '',
    insurance: Insurance.PUBLIC,
    apt_type_id_or_schedule_type_dc_id: '',
    calendar_doc_cirrus_id: '',
    priority: Priority.NONE,
    duration: 0,
    date_time: new Date().toISOString(),
    notes: '',
};

const AppointmentScheduler: FC<Props> = ({ opened, onClose }) => {
    const [appointments_form_data, apt_list_handlers] = useListState<AppointmentBookingFormValues & { id: string }>([]);
    const [current_appointment_id, setCurrentAppointmentId] = useState<string | null>(null);
    const [initial_form_values, setInitialFormValues] = useState<AppointmentBookingFormValues>(initial_values);
    const [is_last_form_valid, setIsLastFormValid] = useState(false);

    // Reset all states when the modal is closed
    const resetAllData = () => {
        const new_id = randomId(); // Generate a consistent new ID
        apt_list_handlers.setState([{ id: new_id, ...initial_values }]);
        setCurrentAppointmentId(new_id);
        setInitialFormValues(initial_values);
        setIsLastFormValid(false);
    };

    const handleClose = () => {
        resetAllData(); // Clear data on modal close
        onClose(); // Trigger the external close handler
    };

    useEffect(() => {
        if (!opened) {
            resetAllData(); // Reset states when the modal is closed
        }
    }, [opened]);

    const submit_btn_text = useMemo(() => {
        if (appointments_form_data.length > 1) return `${appointments_form_data.length} Termine speichern`;

        if (dayjs(appointments_form_data[0]?.date_time).isSame(dayjs(), 'day')) return 'Speichern und Check-in starten';

        return 'Speichern';
    }, [appointments_form_data]);

    // All structured enriched data un filtered.
    // TODO: if we pass data here, pass it to the form...
    const { data } = useAppointmentBookingFormOptions();

    const addNewAppointment = () => {
        const id = randomId();
        apt_list_handlers.append({ id: id, ...initial_values });
        setCurrentAppointmentId(id);
        setInitialFormValues(initial_values);
    };

    const setCurrentFormData = (values: AppointmentBookingFormValues) => {
        if (!current_appointment_id) {
            return;
        }

        apt_list_handlers.applyWhere(
            (item) => item.id === current_appointment_id,
            () => ({ id: current_appointment_id, ...values })
        );
    };

    const handleEdit = (id: string) => {
        const appointment = appointments_form_data.find((item) => item.id === id);
        if (appointment) setInitialFormValues(appointment);
    };

    const handleDelete = (id: string) => {
        apt_list_handlers.filter((item) => item.id !== id);
    };

    const onFormsSubmit = (forms: Record<string, unknown>[]) => {
        console.debug(forms);
    };

    const modal_footer = (
        <div className="flex w-full justify-between">
            <Button variant="outline" onClick={() => handleClose()}>
                {/* TODO: add translation */}
                Abbrechen
            </Button>
            {/* This should be unlocked and close the modal */}
            <Button onClick={() => onFormsSubmit(appointments_form_data)} disabled={!is_last_form_valid}>
                {submit_btn_text}
            </Button>
        </div>
    );

    // TODO: move this function somewhere else and make it more efficient
    // Issue: form is returning ids also useful for api call but to render them we need values.
    const getAppointmentDataFromIds = (
        apt_forms_data: AppointmentBookingFormValues
    ): CollapsedAppointmentData | undefined => {
        if (!data) {
            return undefined;
        }

        const filtered_data_by_sub_location = filterEnrichedDataBySubLocationId(
            data,
            apt_forms_data.sub_location_doc_cirrus_id
        );

        const practitioner = filtered_data_by_sub_location?.practitioners.find((p) =>
            p.calendars.some((c) => c.doc_cirrus.id === apt_forms_data.calendar_doc_cirrus_id)
        );

        const appointment_type = filtered_data_by_sub_location?.appointment_types.find(
            (a) => a.id === apt_forms_data.apt_type_id_or_schedule_type_dc_id
        );

        const unmapped_schedule_type = filtered_data_by_sub_location?.unmapped_schedule_types.find(
            (a) => a.doc_cirrus.dc_schedule_type_id === apt_forms_data.apt_type_id_or_schedule_type_dc_id
        );

        if (!practitioner || !(appointment_type || unmapped_schedule_type)) {
            console.debug(`Issue on rendering collapsed appointment`);
            return undefined;
        }

        const apt: CollapsedAppointmentData = {
            isMapped: Boolean(appointment_type),
            practitioner: practitioner,
            appointmentType: appointment_type,
            unmappedScheduleType: unmapped_schedule_type,
            dateTime: apt_forms_data.date_time,
            duration: apt_forms_data.duration,
            priority: apt_forms_data.priority,
            notes: apt_forms_data.notes ?? '',
            insurance: apt_forms_data.insurance,
        };

        return apt;
    };

    return (
        <BaseModal opened={opened} onClose={handleClose} title="Termindetails eingeben" footer={modal_footer} size="xl">
            <div className="flex min-h-[71vh] flex-col gap-4">
                <div className="flex flex-grow flex-col gap-4">
                    <AnimatePresence>
                        {appointments_form_data.map(({ id, ...appointment }, index) =>
                            id !== current_appointment_id ? (
                                <motion.div key={id} layoutId={id} transition={{ type: 'tween' }} exit={{ opacity: 0 }}>
                                    <CollapsedAppointment
                                        disableEdit={!is_last_form_valid}
                                        disableCancel={appointments_form_data.length <= 1}
                                        appointment={getAppointmentDataFromIds(appointment)}
                                        appointmentCount={`(${index + 1} of ${appointments_form_data.length})`}
                                        onEdit={() => {
                                            setCurrentAppointmentId(id);
                                            handleEdit(id);
                                        }}
                                        onDelete={() => handleDelete(id)}
                                    />
                                </motion.div>
                            ) : (
                                <motion.div
                                    key={id}
                                    layoutId={id}
                                    transition={{ duration: 0.3 }}
                                    initial={{
                                        translateY: appointments_form_data.length > 1 ? '150%' : 0,
                                        opacity: 0,
                                    }}
                                    animate={{
                                        translateY: 0,
                                        opacity: 1,
                                    }}
                                    exit={{ opacity: 0 }}
                                >
                                    <AppointmentForm
                                        setIsFormValid={setIsLastFormValid}
                                        setFormData={setCurrentFormData}
                                        initialValues={initial_form_values}
                                        disableCancel={appointments_form_data.length <= 1}
                                        onDelete={() => {
                                            handleDelete(id);
                                            setIsLastFormValid(true);
                                        }}
                                        allowTransition={appointments_form_data.length > 1}
                                        // Check if form has different value of default one
                                        enableSteps={initial_form_values === initial_values}
                                    />
                                </motion.div>
                            )
                        )}
                    </AnimatePresence>
                </div>
                <Button
                    disabled={!is_last_form_valid}
                    leftSection={<Plus />}
                    className="w-full shadow-sm"
                    variant="outline"
                    onClick={addNewAppointment}
                >
                    {/* TODO: add translation */}
                    Termin hinzufügen
                </Button>
            </div>
        </BaseModal>
    );
};

export default AppointmentScheduler;
