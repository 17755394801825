import Location from '@/models/Location.ts';
import Printer from '@/models/Printer.ts';
import { Appointment } from '@/models/appointment/Appointment.ts';
import { UserAppointmentDto } from '@/models/call-system/dtos/user-appointment-dto.ts';
import { CheckinStatus } from '@/models/call-system/models/CallSystemUser.ts';

import { getExtAuthToken, updateUserByNumber } from '@/services/callSystemService.ts';
import { printPcsTicket } from '@/services/printerService.ts';

import { usePrinterStore } from '@/stores/printerStore.ts';

export const loadPrinterToken = async (location: Location) => {
    let { printer_token } = usePrinterStore.getState();

    if (
        !printer_token?.token ||
        printer_token.instanceId !== location.instanceId ||
        Date.now() > printer_token.validUntil
    ) {
        const [success, new_token] = await getExtAuthToken(location.instanceId);
        if (!success || !new_token) {
            return null;
        }

        printer_token = {
            token: new_token,
            validUntil: Date.now() + 3600000, // 1 hour
            instanceId: location.instanceId,
        };

        usePrinterStore.setState({ printer_token });
    }

    return printer_token?.token;
};

export const markCheckinAsDone = async (
    appointment: Appointment,
    location: Location,
    ticket: string
): Promise<boolean> => {
    const practitioner = { display_name: { text: 'Dr. Max Mustermann' } }; // todo get practitioner

    const data: UserAppointmentDto = {
        group: location.key,
        number: ticket,
        name: appointment.name,
        user_id: appointment.id,
        location_key: location.key,
        appointment: {
            id: appointment.id,
            checkin_status: CheckinStatus.CHECKED_IN,
            doctor_name: practitioner.display_name.text,
        },
        instance_id: location.instanceId,
    };

    const [success] = await updateUserByNumber(data);
    return success;
};

export const printTicket = async (location: Location, selected_printer: Printer): Promise<[boolean, null | string]> => {
    const auth_token = await loadPrinterToken(location);
    if (!auth_token) return [false, null];

    const [success, ticket] = await printPcsTicket(
        auth_token,
        selected_printer.ip_address ??
            location.config.pcs_printers?.[0]?.address[0].text ??
            location.config.patient_call_system_ip[0].text,
        selected_printer.certificate ?? location.config.pcs_printers?.[0]?.certificate[0].text
    );

    if (success) {
        return [success, ticket as string];
    } else {
        return [success, null];
    }
};
