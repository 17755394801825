import { AuthUser } from 'aws-amplify/auth';
import { create } from 'zustand';

const initial_state = {
    user: null,
    is_user_set: false,
    is_admin: false,
    user_groups: [],
    user_scopes: [],
    user_instance_ids: [],
    user_customer_ids: [],
    user_email: '',
};

export const useAuthStore = create<{
    user: AuthUser | null;
    is_user_set: boolean;
    is_admin: boolean;
    user_groups: string[];
    user_scopes: string[];
    user_instance_ids: string[];
    user_customer_ids: string[];
    user_email: string;
    reset: () => void;
}>((set) => ({
    ...initial_state,
    reset: () => set({ ...initial_state }),
}));
