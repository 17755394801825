import { FocusTrap } from '@mantine/core';
import { Calendar, TimeInput } from '@mantine/dates';
import { useToggle } from '@mantine/hooks';
import { ChangeEvent, FC, ReactNode, useState } from 'react';

import { dayjs } from '@/utils/dayjsSetup.ts';

interface Props {
    value?: string;
    onChange: (value: string) => void;
    onTimeSelect?: () => void;
    additionalComponents?: ReactNode;
}

const DateTimePickerInput: FC<Props> = ({ value, onChange, onTimeSelect, additionalComponents }) => {
    const [selected_day, setSelectedDay] = useState<Date>(value ? new Date(value) : new Date());
    const [selected_time, setSelectedTime] = useState(value ? dayjs(value).format('HH:mm') : '01:00');
    const [is_time_input_focused, toggleTimeInputFocus] = useToggle([false, true]);

    const setDateTime = (day: Date, time: string) => {
        const [hour, minute] = time.split(':').map((i: string) => parseInt(i));
        onChange(dayjs(day).hour(hour).minute(minute).toISOString());
    };

    const onDaySelect = (day: Date) => {
        setSelectedDay(day);
        setDateTime(day, selected_time);
        toggleTimeInputFocus();
    };

    const onTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSelectedTime(event.target.value);
        setDateTime(selected_day, event.target.value);
        if (onTimeSelect) onTimeSelect();
    };

    return (
        <div className="flex gap-4">
            <Calendar
                getDayProps={(date) => ({
                    selected: dayjs(date).isSame(selected_day, 'date'),
                    onClick: () => onDaySelect(date),
                })}
            />
            <div className="flex flex-col gap-4">
                <FocusTrap active={is_time_input_focused}>
                    <TimeInput label="Uhrzeit" value={selected_time} onChange={onTimeChange} />
                    {additionalComponents}
                </FocusTrap>
            </div>
        </div>
    );
};

export default DateTimePickerInput;
