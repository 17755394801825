import { clsx } from 'clsx';
import { FC, ReactNode } from 'react';

interface Props {
    children: ReactNode;
    text?: string; // only for storybook
    className?: string;
}

const Heading4: FC<Props> = ({ children, text, className }) => {
    return <h4 className={clsx('', className)}>{text ?? children}</h4>;
};
export default Heading4;
