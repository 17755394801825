import { Patient } from '@/models/Patient.ts';

export const mocked_patient: Patient = {
    user_id: '76059a94-88ec-4cdd-9f61-2c508e7a3672',
    instance_id: '',
    meta: {
        updated_at: '2024-10-30T13:46:42.385Z',
        created_at: '2024-10-30T13:46:31.788Z',
        synced_at: '2024-10-30T13:46:31.788Z',
    },
    account: {
        date_of_birth: '1946-02-12',
        patient_number: '+49 151321654987',
    },
    primary_email: 'lucas.maffazioli+carina.lohse@eterno.health',
    name: {
        last_name: 'Lohse',
        first_name: 'Carina',
        gender: '',
        salutation: '',
        middle_name: '',
    },
    contact: {
        emails: [],
    },
    addresses: [
        {
            type: 'haus',
            city: 'berlin',
            street: 'Pohlstrasse 49',
            id: 'lol',
            country: 'br',
            zip: '95010',
        },
    ],
    communications: [],
    insurances: [
        {
            type: 'GKV',
            name: 'BAMER',
            id: '91822',
            insurance_print_name: 'string',
            company_id: 'string',
            number: 'string',
            membership_type: 'string',
            ktab: 'string',
            group_id: 'string',
            fee_schedule: 'string',
            expiry_date: 'string',
            card_swipe_date: 'string',
        },
    ],
    other_locations: {},
    sync_state: 'lol',
};
