import { FC, ReactNode } from 'react';

import { cn } from '@/utils/styleUtils.ts';

interface Props {
    children: ReactNode;
    className?: string;
}

const OverlaySection: FC<Props> = ({ children, className }) => {
    return (
        <div
            className={cn(
                'flex w-full flex-col items-center justify-between gap-4 rounded-lg border bg-white p-4',
                className
            )}
        >
            {children}
        </div>
    );
};

export default OverlaySection;
