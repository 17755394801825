import { notifications } from '@mantine/notifications';

export const showSuccessNotification = (message: string, title?: string) => {
    notifications.show({ title, message });
};

export const showWarningNotification = (message: string, title?: string) => {
    notifications.show({ title: title, message: message, color: 'yellow' });
};

export const showErrorNotification = (message: string, title?: string) => {
    notifications.show({ title: title, message: message, color: 'red' });
};
