import { Appointment } from '@/models/appointment/Appointment.ts';
import { FormKeyObject as FormTemplateAppointment } from '@/models/forms/backend-types/FormDataAppointment.ts';
import { useMemo } from 'react';
import useSWR from 'swr';

import {
    getFormTemplates,
    getRequiredFormsForAppointment,
    getSelectedFormsForAppointment,
} from '@/services/formDataService.ts';

import { getUserIdFromAppointment } from '@/utils/appointmentUtils.ts';
import { formatRequiredForms } from '@/utils/formUtils.ts';

export const usePatientAppointmentRequiredForms = (appointment: Appointment) => {
    const user_id = getUserIdFromAppointment(appointment);

    const professional_form_templates = useSWR(['professional_form_templates', appointment.professional.id], () =>
        getFormTemplates(appointment.professional.id)
    );

    const required_forms_for_appointment = useSWR(['getRequiredFormsForAppointment', appointment], () =>
        getRequiredFormsForAppointment(appointment)
    );

    // This object can be empty since only sometimes we will have custom selected forms for an appointment
    const selected_appointment_forms = useSWR<FormTemplateAppointment[] | undefined>(
        ['selected_appointment_forms', user_id, appointment.id],
        async () => {
            if (user_id !== '-') return await getSelectedFormsForAppointment(user_id, appointment.id);
            return undefined;
        }
    );

    const formatted_forms_for_appointment = useMemo(() => {
        if (!professional_form_templates.data || !required_forms_for_appointment.data) return undefined;
        const formatted_forms = formatRequiredForms(
            selected_appointment_forms.data,
            required_forms_for_appointment.data,
            professional_form_templates.data
        );
        return formatted_forms;
    }, [required_forms_for_appointment, professional_form_templates, selected_appointment_forms]);

    return {
        professional_form_templates: professional_form_templates.data,
        formatted_forms_for_appointment: formatted_forms_for_appointment,
        mutateSelectedAppointmentForms: selected_appointment_forms.mutate,
        selected_appointment_forms: selected_appointment_forms.data,
        is_loading:
            professional_form_templates.isLoading ||
            required_forms_for_appointment.isLoading ||
            selected_appointment_forms.isLoading,
    };
};
