import { ColumnType, KanbanColumn } from '@/models/RoomView.ts';
import { Appointment } from '@/models/appointment/Appointment.ts';
import { CheckInDataMap } from '@/models/call-system/dtos/user-appointment-dto.ts';
import { Collapse } from '@mantine/core';
import { ChevronDown } from 'lucide-react';
import { FC } from 'react';

import AppointmentCard from '@/components/room-view/AppointmentCard/AppointmentCard.tsx';

import { generateAppointmentKey } from '@/utils/appointmentUtils.ts';

interface DroppableColumnProps {
    column: KanbanColumn;
    collapsed?: boolean;
    collapsable?: boolean;
    toggleCollapse?: (id: string) => void;
    checkInData: CheckInDataMap;
    onAppointmentClick: (appointment: Appointment) => void;
}

const DroppableColumn: FC<DroppableColumnProps> = ({
    column,
    collapsed = false,
    collapsable = false,
    toggleCollapse,
    onAppointmentClick,
    checkInData,
}) => {
    return (
        <div className={`rounded-lg bg-gray-50 p-3`}>
            <h2 className="mb-4 text-sm font-semibold">
                <button
                    className="flex w-full justify-between text-left"
                    onClick={() => {
                        if (collapsable && toggleCollapse) {
                            toggleCollapse(column.id);
                        }
                    }}
                >
                    <p>
                        {column.title} {column.appointments.length > 0 && `(${column.appointments.length})`}
                    </p>
                    {collapsable && <ChevronDown className={`transition-transform ${collapsed ? 'rotate-180' : ''}`} />}
                </button>
            </h2>

            <Collapse in={!collapsed}>
                <div className="space-y-3">
                    {column.appointments.length === 0 ? (
                        <div className="rounded border-2 border-dashed border-gray-200 p-4 text-center text-gray-400">
                            {column.id === 'treatmentRoom' ? 'Patienten hier ziehen' : 'Keine Patienten'}
                        </div>
                    ) : (
                        column.appointments.map((appointment) => (
                            <AppointmentCard
                                key={appointment.id}
                                isPending={
                                    column.type === ColumnType.EXPECTED || column.type === ColumnType.WAITING_ROOM
                                }
                                appointment={appointment}
                                onClick={onAppointmentClick}
                                checkInData={checkInData?.get(
                                    generateAppointmentKey(appointment.links.dc_instance_id, appointment.id)
                                )}
                            />
                        ))
                    )}
                </div>
            </Collapse>
        </div>
    );
};

export default DroppableColumn;
