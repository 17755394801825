import { FC } from 'react';

import Router from '@/components/Router.tsx';

import useLoadUser from '@/hooks/useLoadUser.ts';

const App: FC = () => {
    useLoadUser();

    return <Router />;
};

export default App;
