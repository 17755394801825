import { NavLink } from '@mantine/core';
import { randomId } from '@mantine/hooks';
import { Calendar, ConciergeBell, List, Thermometer } from 'lucide-react';
import { FC } from 'react';

const HeaderNavigation: FC = () => {
    const routes = [
        {
            label: 'Termine',
            icon: ConciergeBell,
            path: randomId(),
        },
        {
            label: 'Kalender',
            icon: Calendar,
            path: randomId(),
        },
        {
            label: 'Aufgaben',
            icon: List,
            path: randomId(),
        },
        {
            label: 'Tech Status',
            icon: Thermometer,
            path: randomId(),
        },
    ];

    return (
        <div className="flex items-center gap-8">
            {routes.map(({ label, path, icon: Icon }) => (
                <NavLink
                    classNames={{
                        root: 'flex w-36 justify-center opacity-50 transition hover:opacity-100',
                        section: 'opacity-100',
                    }}
                    key={path}
                    leftSection={<Icon className="size-5" />}
                    label={label}
                />
            ))}
        </div>
    );
};

export default HeaderNavigation;
