import { clsx } from 'clsx';
import { FC, ReactNode } from 'react';

interface Props {
    children: ReactNode;
    text?: string; // only for storybook
    className?: string;
}

const Heading2: FC<Props> = ({ children, text, className }) => {
    return <h2 className={clsx('', className)}>{text ?? children}</h2>;
};

export default Heading2;
