import { CardStatus } from '@/models/CardStatus.ts';
import { Button, Tooltip } from '@mantine/core';
import { clsx } from 'clsx';
import { FC } from 'react';

interface Props {
    status: CardStatus;
    onClick?: () => void;
}

const CardButton: FC<Props> = ({ status, onClick }) => {
    const status_color = {
        [CardStatus.READ]: 'bg-green-500',
        [CardStatus.UNREAD]: 'bg-red-500',
        [CardStatus.BRING_LATER]: 'bg-yellow-500',
    };

    const status_message = {
        [CardStatus.READ]: 'Patient scanned card 10 days ago',
        [CardStatus.UNREAD]: "Patient didn't scan card in last 3 months",
        [CardStatus.BRING_LATER]: 'Patient needs to scan card',
    };

    return (
        <Tooltip label={status_message[status]}>
            <Button
                leftSection={<div className={clsx('size-3 min-w-3 rounded-full', status_color[status])} />}
                variant="outline"
                onClick={onClick}
            >
                eGK lesen
            </Button>
        </Tooltip>
    );
};

export default CardButton;
