import { Table } from '@mantine/core';
import { clsx } from 'clsx';
import { Children, FC, MouseEvent, ReactNode } from 'react';

interface Props {
    row: ReactNode;
    tableId: string;
    rowIndex: number;
    onHover?: (event: MouseEvent<HTMLTableRowElement>) => void;
    className?: string;
    onClick?: () => void;
}

const TableRow: FC<Props> = ({ row, tableId, rowIndex, onHover, className, onClick }) => (
    <Table.Tr
        className={clsx('h-20 cursor-pointer transition hover:bg-slate-200', className)}
        onMouseOver={onHover}
        onClick={onClick}
    >
        {Children.toArray(row).map((cell, index) => (
            // TODO remove index from key
            // eslint-disable-next-line react/no-array-index-key
            <Table.Td key={`${tableId}_row_${rowIndex}_cell_${index}`} className="px-0 py-4 align-baseline">
                {cell}
            </Table.Td>
        ))}
    </Table.Tr>
);

export default TableRow;
