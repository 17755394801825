import { FC } from 'react';

import Paragraph from '@/components/text/Paragraph.tsx';

interface Props {
    label: string;
    value?: string;
}

const PatientInfoItem: FC<Props> = ({ label, value }) => {
    return (
        <div className="flex gap-2">
            <Paragraph className="whitespace-nowrap">{`${label}:`}</Paragraph>
            <Paragraph className="font-medium">{value ?? '--'}</Paragraph>
        </div>
    );
};

export default PatientInfoItem;
