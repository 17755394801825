import { PrismicDataType } from '@/models/PrismicHookTypes.ts';
import { Appointment, Priority } from '@/models/appointment/Appointment.ts';
import { UserAppointmentDto } from '@/models/call-system/dtos/user-appointment-dto.ts';
import { Card } from '@mantine/core';
import { FileCheck, FileX } from 'lucide-react';
import { FC, useMemo } from 'react';

import PriorityMarker from '@/components/PriorityMarker.tsx';
import AppointmentCardHeader from '@/components/room-view/AppointmentCard/AppointmentCardHeader.tsx';
import Paragraph from '@/components/text/Paragraph.tsx';

import usePrismic from '@/hooks/usePrismic.tsx';

import { getInsuranceByKey } from '@/utils/patientUtils.ts';

interface Props {
    appointment: Appointment;
    checkInData?: UserAppointmentDto;
    onClick: (appointment: Appointment) => void;
}

const PendingAppointmentCard: FC<Props> = ({ appointment, onClick, checkInData }) => {
    const insurance = useMemo(() => getInsuranceByKey(appointment.data.insurance_coverage_type), [appointment]);
    const { data: professional_profiles } = usePrismic(PrismicDataType.PROFESSIONAL_PROFILE);

    return (
        <Card
            className="cursor-pointer gap-2 rounded-lg p-6 shadow-md transition duration-100 hover:bg-slate-50"
            withBorder
            onClick={() => onClick(appointment)}
        >
            <PriorityMarker priority={appointment.priority ?? Priority.NORMAL} />
            <AppointmentCardHeader
                patientName={appointment.data.patient_name}
                insurance={insurance}
                isInsuranceCardActive={appointment.data.is_insurance_card_active}
                date={appointment.start}
                ticketNumber={checkInData?.number}
                showDate
            />
            <div className="flex items-center">
                <div className="flex-grow">
                    <Paragraph>{appointment.data.services[0].name}</Paragraph>
                    <Paragraph className="text-slate-500">
                        {professional_profiles?.get(appointment.professional.key)?.display_name[0]?.text}
                    </Paragraph>
                </div>
                {appointment.data.is_anamnesis_shared ? (
                    <FileCheck className="text-green-700" />
                ) : (
                    <FileX className="text-red-700" />
                )}
            </div>
        </Card>
    );
};

export default PendingAppointmentCard;
