import { PrismicDataType, PrismicReturnType } from '@/models/PrismicHookTypes.ts';
import { BuildQueryURLArgs } from '@prismicio/client';
import useSWR, { SWRResponse } from 'swr';

import { defaultFetcher, fetchers, isPrismicDataType } from '@/utils/prismicUtils.ts';

// generic hook to handle all prismic calls
// add the calls in the enum 'PrismicReturnTypes', and if needed, add a custom fetcher in 'fetchers' to handle custom logic
const usePrismic = <T extends PrismicDataType>(
    type: T,
    prismic_params?: Partial<BuildQueryURLArgs> | undefined
): SWRResponse<PrismicReturnType[T]> => {
    console.debug(`[START] usePrismic with type ${type} and params ${prismic_params}`);

    if (!isPrismicDataType(type)) {
        console.debug(`Error: Invalid Prismic data type: ${type}, will not fetch.`);
    }
    const swr_key: [T, typeof prismic_params] | null = isPrismicDataType(type) ? [type, prismic_params] : null;

    // Use the specific fetcher if available, otherwise use the default fetcher
    const fetcher = fetchers[type] ?? (() => defaultFetcher(type));

    return useSWR<PrismicReturnType[T]>(swr_key, () => fetcher(type, prismic_params), {
        revalidateOnFocus: false,
        shouldRetryOnError: false,
    });
};

export default usePrismic;
