import { mocked_patient } from '@/constants/mocked_patient.ts';
import { CardStatus } from '@/models/CardStatus.ts';
import { Appointment } from '@/models/appointment/Appointment.ts';
import { Divider } from '@mantine/core';
import { FC, useState } from 'react';

import BaseModal from '@/components/BaseModal.tsx';
import EternoBadge from '@/components/EternoBadge.tsx';
import ExternalLink from '@/components/ExternalLink.tsx';
import AppointmentHistory from '@/components/PatientModal/AppointmentHistory.tsx';
import CardButton from '@/components/PatientModal/CardButton.tsx';
import PatientInformation from '@/components/PatientModal/PatientInformation.tsx';
import Heading2 from '@/components/text/Heading2.tsx';
import Paragraph from '@/components/text/Paragraph.tsx';

import CircularLoader from '../CircularLoader.tsx';
import AppointmentDetails from './AppointmentDetails.tsx';

interface Props {
    opened: boolean;
    onClose: () => void;
    appointment: Appointment;
}

const PatientModal: FC<Props> = ({ opened, onClose, appointment }) => {
    const patient = mocked_patient;
    // TEMPORARY STATE FOR PRESENTATION
    const [egk_status, setEgkStatus] = useState<CardStatus>(CardStatus.UNREAD);

    const cycleEgkStatus = () => {
        switch (egk_status) {
            case CardStatus.UNREAD:
                setEgkStatus(CardStatus.BRING_LATER);
                break;
            case CardStatus.BRING_LATER:
                setEgkStatus(CardStatus.READ);
                break;
            case CardStatus.READ:
                setEgkStatus(CardStatus.UNREAD);
                break;
            default:
                setEgkStatus(CardStatus.BRING_LATER);
                break;
        }
    };

    return (
        <BaseModal
            opened={opened}
            onClose={onClose}
            title={
                <div className="flex items-center gap-2">
                    <Heading2>{appointment.data.patient_name}</Heading2>
                    <EternoBadge text="eGK gelesen" size="lg" />
                </div>
            }
            size="xl"
        >
            <div className="flex flex-col gap-5">
                <div className="mt-4 flex items-center gap-4">
                    <div className="flex gap-1">
                        <Paragraph>DC ID:</Paragraph>
                        <Paragraph>4567</Paragraph>
                    </div>
                    <Paragraph>17.11.75</Paragraph>
                    <ExternalLink
                        text="Patientenakte"
                        href="https://www.eterno.health"
                        classNames={{ anchor: 'text-black' }}
                    />
                    <CardButton status={egk_status} onClick={cycleEgkStatus} />
                </div>
                {appointment ? <AppointmentDetails appointment={appointment} onEdit={() => {}} /> : <CircularLoader />}
                <section className="flex gap-5">
                    <PatientInformation patient={patient} />
                    <Divider orientation="vertical" className="h-auto" />
                    <AppointmentHistory appointments={[]} />
                </section>
            </div>
        </BaseModal>
    );
};

export default PatientModal;
