// TODO: Can merge features with SelectableList component
import { Button } from '@mantine/core';
import { FC, ReactNode, useEffect, useState } from 'react';

import { cn } from '@/utils/styleUtils.ts';

interface Option {
    id: string;
    label: ReactNode;
    value: string;
}

interface Props {
    selected?: string;
    onSelect: (value: string) => void;
    options: Option[];
    numberOfOptionsInRow?: number;
    allowDeselect?: boolean;
    classNames?: {
        container?: string;
        option?: string;
    };
}

const ButtonSelection: FC<Props> = ({
    selected,
    onSelect,
    options,
    numberOfOptionsInRow = 5,
    allowDeselect = false,
    classNames,
}) => {
    const [selected_option, setSelectedOption] = useState<string | undefined>();

    useEffect(() => {
        setSelectedOption(selected);
    }, [selected]);

    const onSelectHandler = (option: Option) => {
        if (allowDeselect && selected_option === option.id) {
            setSelectedOption(undefined);
            onSelect('');
            return;
        }

        setSelectedOption(option.id);
        onSelect(option.value);
    };

    return (
        <div className={cn('grid gap-3', `grid-cols-${numberOfOptionsInRow}`, classNames?.container)}>
            {options.map((option) => (
                <Button
                    onClick={() => onSelectHandler(option)}
                    key={option.id}
                    variant="default"
                    classNames={{ label: 'w-full justify-center' }}
                    className={cn(
                        'h-10 rounded-lg px-2 font-medium',
                        classNames?.option,
                        selected_option === option.id &&
                            'border-green border-2 border-green-300 bg-green-300 bg-opacity-30 hover:bg-green-300 hover:bg-opacity-30'
                    )}
                >
                    {option.label}
                </Button>
            ))}
        </div>
    );
};

export default ButtonSelection;
