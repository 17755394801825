import { Insurance } from '@/models/appointment/Appointment.ts';
import { Pill } from '@mantine/core';
import { FC } from 'react';

import Paragraph from '@/components/text/Paragraph.tsx';

import { dayjs } from '@/utils/dayjsSetup.ts';
import { cn } from '@/utils/styleUtils.ts';

interface Props {
    patientName?: string;
    insurance?: Insurance;
    isInsuranceCardActive: boolean;
    ticketNumber?: string;
    date: string;
    showDate?: boolean;
}

const AppointmentCardHeader: FC<Props> = ({
    patientName,
    insurance,
    isInsuranceCardActive,
    ticketNumber,
    date,
    showDate = false,
}) => (
    <div className="flex items-center gap-1">
        <Paragraph className={cn('font-semibold underline', patientName ?? 'text-slate-400 no-underline')}>
            {patientName ?? 'Nicht angegeben'}
        </Paragraph>
        {insurance && (
            <Pill
                className={cn(
                    'bg-blue-100 text-blue-700',
                    insurance === Insurance.PUBLIC && 'bg-red-100 text-red-700',
                    isInsuranceCardActive && 'bg-green-100 text-green-700'
                )}
            >
                {insurance}
            </Pill>
        )}
        {ticketNumber && <Pill>{ticketNumber}</Pill>}
        {showDate && (
            <Paragraph className="flex-grow text-right text-sm text-slate-500">{dayjs(date).format('HH:mm')}</Paragraph>
        )}
    </div>
);

export default AppointmentCardHeader;
