import { generateColors } from '@mantine/colors-generator';
import { Input, createTheme } from '@mantine/core';

export const theme = createTheme({
    fontFamily: 'MessinaSans-Regular, sans-serif',
    headings: {
        fontFamily: 'MessinaSans-Regular, sans-serif',
    },
    colors: {
        eterno: generateColors('#4c726d'),
    },
    primaryColor: 'eterno',
    primaryShade: 9,
    components: {
        Input: Input.extend({
            classNames: {
                input: 'bg-slate-100 disabled:border-transparent disabled:text-slate-400 disabled:opacity-100 disabled:bg-slate-100',
            },
        }),
    },
});
