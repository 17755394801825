import Location from '@/models/Location.ts';
import { Appointment } from '@/models/appointment/Appointment.ts';
import { DocumentType } from '@/models/documents/Document.ts';
import { DocumentCodeErrorResponse } from '@/models/documents/document-error-dto.ts';
import FormattedForm from '@/models/forms/FormattedForm.ts';
import { FormKeyObject } from '@/models/forms/backend-types/FormDataAppointment.ts';
import { FormKeyObject as FormTemplateAppointment } from '@/models/forms/backend-types/FormDataAppointment.ts';
import { FormDataCodeDto } from '@/models/forms/backend-types/FormDataCode.ts';
import { FormDataRequiredTemplate } from '@/models/forms/backend-types/FormDataRequiredTemplates.ts';
import { FormTemplate, FormTemplateContent } from '@/models/forms/backend-types/form-template/FormTemplate.ts';
import { MedicalRecordsDocument, MedicalRecordsDocumentDataTypeOfDocResponseItem } from '@/models/prismicTypes.ts';

import { getDocumentDownloadUrl } from '@/services/documentsService.ts';
import { showErrorNotification } from '@/services/notificationService.ts';

import { getUserIdFromAppointment } from '@/utils/appointmentUtils.ts';
import { genericIdReducer } from '@/utils/arrayUtils.ts';

/**
 * Formats required forms by merging selected forms, required forms,
 * and professional templates into a unified structure.
 * selected_forms have precedent over required_forms_for_appointment
 */
export const formatRequiredForms = (
    selected_forms: FormTemplateAppointment[] | undefined,
    required_forms: FormDataRequiredTemplate[],
    professional_forms: FormTemplate[]
): FormattedForm[] => {
    const form_ids: string[] = (selected_forms ?? required_forms).map(({ id }) => id);
    const formatted_forms: FormattedForm[] = [];

    const professional_forms_map = genericIdReducer(professional_forms);
    const required_forms_map = genericIdReducer(required_forms);

    for (const form_id of form_ids) {
        const professional_form = professional_forms_map[form_id];
        if (!professional_form) continue;

        const required_form = required_forms_map[form_id];
        const formatted_form = {
            id: professional_form.id,
            name: getFormNameFromFormattedForm(professional_form),
            template_key: professional_form.template_key, // todo: can this really be undefined??
            is_completed: !!required_form?.is_completed,
        };

        formatted_forms.push(formatted_form);
    }

    return formatted_forms.filter((form) => !!form);
};

export const toggleSelectedForm = (current_selected_forms: string[], form_id: string): string[] => {
    const is_already_selected = current_selected_forms.includes(form_id);
    if (is_already_selected) {
        // Remove form_id from the selected forms
        return current_selected_forms.filter((id: string) => id !== form_id);
    } else {
        // Add form_id to the selected forms
        return [...current_selected_forms, form_id];
    }
};

const formatFormToSave = (professional_form_templates: FormTemplate[], form_id: string): FormKeyObject | undefined => {
    const professional_form = professional_form_templates.find(({ id }) => id === form_id);
    if (professional_form) {
        return { id: professional_form.id, key: professional_form.template_key ?? '' };
    } else {
        return undefined;
    }
};

export const filterAndFormatFormsToSave = (
    professional_form_templates: FormTemplate[],
    selected_forms: string[]
): FormKeyObject[] => {
    return selected_forms
        .map((form_id) => formatFormToSave(professional_form_templates, form_id))
        .filter((form) => !!form);
};

export const getDocumentType = (
    medical_records_responses: MedicalRecordsDocumentDataTypeOfDocResponseItem[] | undefined,
    document_type: DocumentType
) => {
    const found_translation: MedicalRecordsDocumentDataTypeOfDocResponseItem | undefined =
        medical_records_responses?.find((translation) => translation.type_of_doc_responses === document_type);
    const type_of_document = found_translation?.type_of_document[0];

    return type_of_document?.text ?? 'Unbekannter Dokumenttyp';
};

export const downloadUrl = (url: string) => {
    window.open(url, '_blank', 'noreferrer');
};

export const getErrorMessageFromPrismicContent = (
    code: DocumentCodeErrorResponse,
    content: MedicalRecordsDocument['data']
) => {
    const medical_record = content[code as keyof MedicalRecordsDocument['data']];
    // @ts-expect-error the medical record could be anything in Prismic, but we know is RichText
    return medical_record[0]?.text ?? 'Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut';
};

export const downloadDocument = async (
    appointment: Appointment,
    document_id: string,
    onNeedToInsertOtpCb: () => void
): Promise<void> => {
    const [success, document_download_data, error] = await getDocumentDownloadUrl(appointment.user_id, document_id);

    if (success && document_download_data && document_download_data.download_url) {
        downloadUrl(document_download_data.download_url);
        return;
    }

    if (error?.status === 403) {
        const error_data = error.data;
        if (
            error_data?.error_code === DocumentCodeErrorResponse.INVALID_OR_EXPIRED ||
            error_data?.error_code === DocumentCodeErrorResponse.EXPIRED_CODE
        ) {
            onNeedToInsertOtpCb();
            return;
        }
    }
    const fallback_error = error?.data ?? error?.message;
    showErrorNotification(`unexpected_error_sending_email: ${JSON.stringify(fallback_error)}`);
};

export const getFormNameFromFormattedForm = (form: FormTemplate) =>
    (form.is_custom ? form.form_name_de : (form.content as FormTemplateContent).name) ?? '';

export const prepareGenerateCodeBody = (
    location: Location,
    appointment: Appointment,
    formatted_forms: FormattedForm[]
): FormDataCodeDto => {
    return {
        forms: formatted_forms.map((form) => ({
            id: form.id,
            key: form.template_key ?? '', // todo: can this really be undefined or empty?
        })),
        location: {
            location_key: location.key,
        },
        user: {
            user_id: getUserIdFromAppointment(appointment),
        },
        professional: {
            professional_id: appointment.professional.id,
            professional_key: appointment.professional.key,
        },
        appointment_id: appointment.id,
    };
};
