import { appointments } from '@/constants/appointments.ts';
import { TableGroup } from '@/models/TableGroup.ts';
import { Button, ComboboxItem } from '@mantine/core';
import { FC, useState } from 'react';

import AppointmentTable from '@/components/AppointmentTable.tsx';
import Clock from '@/components/Clock.tsx';
import ListViewHeader from '@/components/ListView/ListViewHeader.tsx';
import { ListViewMode } from '@/components/ListView/ListViewMode.ts';
import Page from '@/components/Page.tsx';
import WalkInModal from '@/components/WalkInModal.tsx';
import Heading1 from '@/components/text/Heading1.tsx';

const practitioner_options: ComboboxItem[] = [
    {
        value: '',
        label: 'Alle Behandler:in',
    },
    {
        value: 'nicht',
        label: 'nicht Behandler',
    },
];
const room_options: ComboboxItem[] = [
    {
        label: 'zimmer 1',
        value: '1',
    },
    {
        label: 'zimmer 2',
        value: '2',
    },
];

const ListViewPage: FC = () => {
    const [walk_in_modal_opened, setWalkInModalOpened] = useState<boolean>(false);

    return (
        <Page className="gap-10 p-10">
            <div className="flex w-full items-center">
                <Heading1 className="flex-grow text-4xl font-semibold">Termine</Heading1>
                <Clock />
                <Button onClick={() => setWalkInModalOpened(true)}>Termin erstellen</Button>
            </div>
            <div className="w-full">
                <ListViewHeader
                    practitionerOptions={practitioner_options}
                    roomOptions={room_options}
                    defaultView={ListViewMode.LIST}
                />
            </div>
            <AppointmentTable
                title="Patienten in Behandlung"
                group={TableGroup.IN_PROGRESS}
                appointments={appointments.in_progress}
            />
            <AppointmentTable
                title="Erwartete Patienten"
                group={TableGroup.EXPECTED}
                appointments={appointments.expected}
            />
            <AppointmentTable
                title="Entlassene Patienten"
                group={TableGroup.DISMISSED}
                appointments={appointments.dismissed}
            />

            <WalkInModal opened={walk_in_modal_opened} onClose={() => setWalkInModalOpened(false)} />
        </Page>
    );
};

export default ListViewPage;
