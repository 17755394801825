import {
    AuthSession,
    signOut as amplifySignOut,
    confirmSignIn,
    fetchAuthSession,
    getCurrentUser,
    signIn,
    signInWithRedirect,
} from 'aws-amplify/auth';

import { useAuthStore } from '@/stores/authStore.ts';

export const googleSignIn = async () => {
    await signInWithRedirect({
        provider: 'Google',
    });
};

export const magicLinkSignIn = async (email: string, token: string) => {
    const { nextStep: next_step } = await signIn({
        username: email,
        options: {
            authFlowType: 'CUSTOM_WITHOUT_SRP',
        },
    });

    if (next_step.signInStep !== 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE') {
        return;
    }

    await confirmSignIn({ challengeResponse: token });
    const user = await getCurrentUser();
    await loadUserProperties();
    useAuthStore.setState({ user });
};

export const loadUserProperties = async () => {
    const session: AuthSession = await fetchAuthSession();
    const token = session?.tokens?.idToken?.payload;
    if (!token) return;

    const groups = (token?.['cognito:groups'] as string[]) ?? [];

    useAuthStore.setState({
        is_admin: groups.includes('admin'),
        user_email: token.email as string,
        user_groups: groups,
        user_scopes: (token?.['custom:scopes'] as string)?.split(',') ?? [],
        user_instance_ids: (token?.['custom:instance_ids'] as string)?.split(',') ?? [],
        user_customer_ids: (token?.['custom:active_customer_ids'] as string)?.split(',') ?? [],
    });
};

export const signOut = async () => {
    await amplifySignOut();
    useAuthStore.getState().reset();
};
