import { Select, TextInput } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { FC } from 'react';

import Form, { Props as FormProps } from './Form.tsx';
import FormSection from './FormSection.tsx';

export type PatientFormData = {
    cost_center: string;
    name: string;
    surname: string;
    date_of_birth: Date;
    street: string;
    house_number: string;
    postal_code: number;
    city: string;
    email: string;
    phone: string;
    practice: string;
};

type Props = Pick<FormProps<PatientFormData>, 'initialValues' | 'handleSubmit'>;

const cost_center: string[] = ['opt 1', 'opt 2']; // TODO: make list of enum
const practices: string[] = ['practice 1', 'practice 2', 'practice 3']; // TODO: make list of enum

const PatientDataForm: FC<Props> = ({ initialValues, handleSubmit }) => {
    return (
        <Form initialValues={initialValues} handleSubmit={handleSubmit}>
            {(form) => (
                <>
                    <FormSection title="Cost selector">
                        <Select
                            label="Cost center" // TODO: translation
                            key={form.key('cost_center')} // TODO: translation
                            data={cost_center}
                            {...form.getInputProps('cost_center')}
                        />
                    </FormSection>
                    <FormSection title="Personal info">
                        <TextInput
                            label="Name"
                            placeholder="Name"
                            key={form.key('name')}
                            {...form.getInputProps('name')}
                        />
                        <TextInput
                            label="Surname" // TODO: translation
                            placeholder="Surname" // TODO: translation
                            key={form.key('surname')}
                            {...form.getInputProps('surname')}
                        />
                        <DatePickerInput
                            label="Date of Birth" // TODO: translation
                            key={form.key('date_of_birth')}
                            {...form.getInputProps('date_of_birth')}
                        />
                    </FormSection>
                    <FormSection>
                        <TextInput
                            label="Street" // TODO: translation
                            placeholder="Street" // TODO: translation
                            key={form.key('street')}
                            {...form.getInputProps('street')}
                        />
                        <TextInput
                            label="House Number" // TODO: translation
                            placeholder="House Number" // TODO: translation
                            key={form.key('house_number')}
                            {...form.getInputProps('house_number')}
                        />
                        <TextInput
                            label="Postal Code" // TODO: translation
                            placeholder="Postal Code" // TODO: translation
                            key={form.key('postal_code')}
                            {...form.getInputProps('postal_code')}
                        />
                        <TextInput
                            label="City"
                            placeholder="City"
                            key={form.key('city')}
                            {...form.getInputProps('city')}
                        />
                    </FormSection>
                    <FormSection>
                        <TextInput
                            label="Email" // TODO: translation
                            placeholder="Email" // TODO: translation
                            key={form.key('email')}
                            {...form.getInputProps('email')}
                        />
                        <TextInput // TODO: make it telephone number
                            label="Phone Number"
                            placeholder="+49 0123456789"
                            key={form.key('phone')}
                            {...form.getInputProps('phone')}
                        />
                    </FormSection>
                    <FormSection>
                        <Select
                            label="Practice" // TODO: translation
                            key={form.key('practice')}
                            data={practices}
                            {...form.getInputProps('practice')}
                        />
                    </FormSection>
                </>
            )}
        </Form>
    );
};

export default PatientDataForm;
