import { FuseResultMatch } from '@/models/Fuse.ts';
import { NormalizedPatient, Patient } from '@/models/Patient.ts';
import { Button, Loader, TextInput } from '@mantine/core';
import axios from 'axios';
import { debounce } from 'lodash-es';
import { Search } from 'lucide-react';
import { ChangeEvent, FC, useState } from 'react';
import useSWR from 'swr';

import Paragraph from '@/components/text/Paragraph.tsx';

import useAuth from '@/hooks/useAuth.ts';

import { useAppointmentStore } from '@/stores/appointmentStore.ts';

import SelectableList from '../SelectableList.tsx';
import Heading3 from '../text/Heading3.tsx';
import PatientRow from './PatientRow.tsx';

// TODO: add memoization
// TODO: move search patient outside and pass it as children
const SearchPatient: FC = () => {
    const { getIdToken } = useAuth();
    const [selected_item, setSelectedItem] = useState<string>('');
    const [search, setSearch] = useState<string>('');

    const fetcher = async (): Promise<{
        patients: {
            user: NormalizedPatient;
            matches: FuseResultMatch[];
        }[];
        duration: number;
        results_count: number;
        total_count: number;
    }> => {
        const url = new URL('https://api.dev.patients.eterno-health.io/secure-admin/doc-cirrus/users/search');
        url.search = new URLSearchParams({
            instance_id: 'c019c6fa-ca3c-4a6a-8a50-99e687a07e0c',
            search: encodeURIComponent(search.toLowerCase().toString()),
        }).toString();

        const token = await getIdToken();

        // TODO error catching
        const start = performance.now();
        const response = await axios.get(url.toString(), {
            headers: {
                Authorization: token,
                customer_id: '73c13030-9253-44be-a530-b77f69923ebf',
            },
        });

        return {
            patients: response.data.results.map(({ user, matches }: { user: Patient; matches: FuseResultMatch[] }) => ({
                user: {
                    ...user,
                    _normalized: {
                        user_id: user.user_id.toLowerCase().trim(),
                        primary_email: user.primary_email?.toLowerCase().trim() ?? '',
                        user_name: `${user.name.first_name} ${user.name.last_name}`.toLowerCase().trim(),
                        date_of_birth: user.account.date_of_birth.toLowerCase().trim(),
                    },
                },
                matches: matches,
            })),
            duration: performance.now() - start,
            results_count: response.data.meta.results_count,
            total_count: response.data.meta.total_count,
        };
    };
    const {
        data,
        isLoading: is_loading,
        isValidating: is_validating,
    } = useSWR(search ? `patient-search-${search.toLowerCase().trim()}` : null, fetcher);

    const handleSelect = (value: string) => {
        setSelectedItem(selected_item === value ? '' : value);
        useAppointmentStore.setState({ patient_id: selected_item === value ? undefined : value });
    };

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const handleSearch = debounce((search_value: string) => {
        setSearch(search_value);
    }, 400);

    const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        // TODO add minimum characters constant
        if (value.length < 3) return;

        handleSearch(value);
    };

    return (
        <div className="flex w-full flex-col gap-3">
            <div className="flex gap-5">
                <Heading3>{`Search Patient (${data?.results_count ?? 0} out of ${data?.total_count ?? 0} matches)`}</Heading3>
                {data?.duration && <p>{`${Math.round(data.duration / 10) / 100}s`}</p>}
            </div>
            <div className="flex justify-stretch gap-3">
                <TextInput
                    className="w-3/4"
                    placeholder="Patient name"
                    rightSectionPointerEvents="none"
                    leftSection={(is_loading || is_validating) && <Loader size="xs" />}
                    rightSection={<Search className="size-5" />}
                    onChange={onChangeSearch}
                />
                <Button className="w-1/4" variant="filled">
                    Search
                </Button>
            </div>
            <div className="h-96 overflow-scroll">
                {data?.patients && data?.patients?.length > 0 ? (
                    <SelectableList handleSelect={handleSelect} selectedItem={selected_item}>
                        {data?.patients.map((patient) => (
                            <PatientRow matches={patient.matches} patient={patient.user} key={patient.user.user_id} />
                        ))}
                    </SelectableList>
                ) : (
                    <div className="flex h-48 w-full items-center justify-center rounded-md bg-slate-300">
                        <Paragraph className="text-center text-gray-700">
                            Suchen Sie mit Name, Geburtsdatum oder ID
                        </Paragraph>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SearchPatient;
