import { clsx } from 'clsx';
import { FC, ReactNode } from 'react';

interface Props {
    children: ReactNode;
    text?: string; // only for storybook
    className?: string;
}

const Heading3: FC<Props> = ({ children, text, className }) => {
    return <h3 className={clsx('', className)}>{text ?? children}</h3>;
};

export default Heading3;
