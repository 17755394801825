// eslint-disable-next-line no-restricted-imports
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

dayjs.locale('de');

export { dayjs };
