import { PractitionerDto, PractitionerDtoWrapper } from '@/models/PractitionerDto.ts';
import { EnrichedPractitionerData } from '@/models/appointment/EnrichedPractitionerData.ts';
import useSWR from 'swr';

import useConfig from '@/hooks/useConfig.ts';

import { fetchPractitionerByCustomerId } from '@/services/appointmentService.ts';

import { enrichPractitionerCachedData } from '@/utils/practitioner/cachedDataUtils.ts';

// Issue = we don't have a prismic mapping for practices... (sub_locations)
const useEnrichedPractitionerData = (): { data?: EnrichedPractitionerData; is_loading: boolean } => {
    const { customer_id, instance_id: doc_cirrus_instance_id } = useConfig();
    const cache_key = `practitioner-cached-data-by-customer-id-${customer_id}-dc-instance-id-${doc_cirrus_instance_id}`;

    const fetchPractitionerData = async (
        customer_id: string,
        dc_instance_id: string
    ): Promise<EnrichedPractitionerData> => {
        const raw_data: PractitionerDtoWrapper = await fetchPractitionerByCustomerId(customer_id);

        const linked_user_filtered_by_dc_instance_id: PractitionerDto[] = dc_instance_id
            ? raw_data.linked_users.filter((user) => user.dc_user_mapping.dc_instance_id === dc_instance_id)
            : raw_data.linked_users;

        return enrichPractitionerCachedData(linked_user_filtered_by_dc_instance_id, customer_id);
    };

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { data, isLoading } = useSWR<EnrichedPractitionerData>(cache_key, () =>
        fetchPractitionerData(customer_id, doc_cirrus_instance_id)
    );

    return { data: data, is_loading: isLoading };
};

export default useEnrichedPractitionerData;
