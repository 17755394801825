import { clsx } from 'clsx';
import useCarousel from 'embla-carousel-react';
import { Children, FC, ReactElement, ReactNode, useEffect, useState } from 'react';

interface Props {
    children: ReactNode;
    isInfinite?: boolean;
    prevButton?: ReactNode;
    nextButton?: ReactNode;
    gap?: number;
    classNames?: {
        root?: string;
        card?: string;
        controls?: {
            container?: string;
            disabled?: string;
            previous?: string;
            next?: string;
        };
    };
}

const CardFlipper: FC<Props> = ({
    children,
    isInfinite = false,
    gap = 0,
    classNames,
    prevButton = 'Previous',
    nextButton = 'Next',
}) => {
    const [ref, api] = useCarousel({ loop: isInfinite });
    const [disabled, setDisabled] = useState({
        prev: false,
        next: false,
    });

    const scrollPrev = () => {
        api?.scrollPrev();
    };

    const scrollNext = () => {
        api?.scrollNext();
    };

    const checkIfDisabled = () => {
        if (api) setDisabled({ prev: !api.canScrollPrev(), next: !api.canScrollNext() });
    };

    useEffect(() => {
        if (!api) return;

        setDisabled({ prev: !api.canScrollPrev(), next: !api.canScrollNext() });

        api.on('select', checkIfDisabled);
        api.on('reInit', checkIfDisabled);
    }, [api]);

    return (
        <div className={clsx('relative', classNames?.root)}>
            <div className="overflow-hidden" ref={ref}>
                <div className={clsx('flex', `gap-${gap}`)}>
                    {Children.toArray(children).map((child) => (
                        <div
                            key={`wrapper_${(child as ReactElement).key}`}
                            className={clsx('flex-shrink-0 basis-full', classNames?.card)}
                        >
                            {child}
                        </div>
                    ))}
                </div>
            </div>
            <div className={clsx(classNames?.controls?.container)}>
                <button
                    onClick={scrollPrev}
                    disabled={disabled.prev}
                    className={clsx(classNames?.controls?.previous, disabled.prev && classNames?.controls?.disabled)}
                >
                    {prevButton}
                </button>
                <button
                    onClick={scrollNext}
                    disabled={disabled.next}
                    className={clsx(classNames?.controls?.next, disabled.next && classNames?.controls?.disabled)}
                >
                    {nextButton}
                </button>
            </div>
        </div>
    );
};

export default CardFlipper;
