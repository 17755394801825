import { motion } from 'framer-motion';
import { FC } from 'react';
import QRCode from 'react-qr-code';

import Heading1 from '@/components/text/Heading1.tsx';
import Paragraph from '@/components/text/Paragraph.tsx';

import { getPatientAppUrl } from '@/utils/patientUtils.ts';

export interface Props {
    code: string;
}

const forms_url = `${getPatientAppUrl()}/all-forms-code`;

const FormQRCode: FC<Props> = ({ code }) => {
    return (
        <div className="flex flex-col items-center gap-8 py-28">
            <QRCode value={forms_url} />
            <Paragraph>Haben Sie Probleme mit dem Lesegerät? Geben Sie den Code manuell ein</Paragraph>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.2, delay: 0.3 }}>
                <Heading1>{code}</Heading1>
            </motion.div>
        </div>
    );
};

export default FormQRCode;
