import { Loader, MantineSize } from '@mantine/core';
import { FC } from 'react';

interface Props {
    size?: number | MantineSize;
}

const CircularLoader: FC<Props> = ({ size = 'md' }) => {
    return <Loader size={size} />;
};

export default CircularLoader;
