import { Button, Tooltip } from '@mantine/core';
import { LucideIcon } from 'lucide-react';
import { FC, MouseEventHandler } from 'react';

import { cn } from '@/utils/styleUtils.ts';

import Paragraph from './text/Paragraph.tsx';

interface Props {
    icon: LucideIcon;
    text: string;
    disabled?: boolean;
    tooltip?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    classNames?: {
        icon?: string;
        text?: string;
    };
}

const IconButton: FC<Props> = ({ icon: Icon, text, classNames, disabled, tooltip, onClick }) => {
    const disabled_class = disabled ? 'text-gray-500' : '';

    const button = (
        <Button
            variant="transparent"
            className="disabled:text-red-50, h-auto text-black disabled:bg-transparent disabled:accent-red-600"
            disabled={disabled}
            onClick={onClick}
        >
            <div className="flex flex-col items-center gap-2">
                <Icon className={cn(disabled_class, 'size-6', classNames?.icon)} />
                <Paragraph className={cn(disabled_class, classNames?.text)}>{text}</Paragraph>
            </div>
        </Button>
    );

    return tooltip ? <Tooltip label={tooltip}>{button}</Tooltip> : button;
};

export default IconButton;
