import { clsx } from 'clsx';
import { FC, ReactNode } from 'react';

interface Props {
    children: ReactNode;
    text?: string; // only for storybook
    className?: string;
}

const Paragraph: FC<Props> = ({ children, text, className }) => {
    return <p className={clsx('', className)}>{text ?? children}</p>;
};

export default Paragraph;
