import { PrismicDataType } from '@/models/PrismicHookTypes.ts';
import { Appointment, Priority } from '@/models/appointment/Appointment.ts';
import { UserAppointmentDto } from '@/models/call-system/dtos/user-appointment-dto.ts';
import { Button, Card } from '@mantine/core';
import { FC, useMemo } from 'react';

import PriorityMarker from '@/components/PriorityMarker.tsx';
import AppointmentCardHeader from '@/components/room-view/AppointmentCard/AppointmentCardHeader.tsx';
import Paragraph from '@/components/text/Paragraph.tsx';

import usePrismic from '@/hooks/usePrismic.tsx';

import { dayjs } from '@/utils/dayjsSetup.ts';
import { getInsuranceByKey } from '@/utils/patientUtils.ts';

interface Props {
    appointment: Appointment;
    checkInData?: UserAppointmentDto;
    onClick: (appointment: Appointment) => void;
}

const ResolvedAppointmentCard: FC<Props> = ({ appointment, onClick, checkInData }) => {
    const insurance = useMemo(() => getInsuranceByKey(appointment.data.insurance_coverage_type), [appointment]);
    const { data: professional_profiles } = usePrismic(PrismicDataType.PROFESSIONAL_PROFILE);

    return (
        <Card className="flex-row items-center justify-between gap-2 rounded-lg p-6 shadow-md" withBorder>
            <PriorityMarker priority={appointment.priority ?? Priority.NORMAL} />
            <div className="flex flex-col gap-1.5">
                <AppointmentCardHeader
                    patientName={appointment.data.patient_name}
                    insurance={insurance}
                    isInsuranceCardActive={appointment.data.is_insurance_card_active}
                    date={appointment.start}
                    ticketNumber={checkInData?.number}
                />
                <Paragraph className="text-sm text-slate-500">{`${dayjs(appointment.start).format('HH:mm')} | ${appointment.data.services[0].name} | ${professional_profiles?.get(appointment.professional.key)?.display_name[0]?.text}`}</Paragraph>
            </div>
            <Button variant={appointment.is_active ? 'filled' : 'outline'} onClick={() => onClick(appointment)}>
                {appointment.is_active ? 'Entlassen' : 'Folgetermin'}
            </Button>
        </Card>
    );
};

export default ResolvedAppointmentCard;
