import { EnrichedCustomer, EnrichedLocation } from '@/models/EnrichedPrismicDocuments.ts';
import { Button } from '@mantine/core';
import { Spotlight, spotlight } from '@mantine/spotlight';
import { Pencil, Search } from 'lucide-react';
import { FC, ReactElement, useMemo, useState } from 'react';

import Heading4 from '@/components/text/Heading4.tsx';

import { useConfigStore } from '@/stores/configStore.ts';

// TODO add recommendation logic
const CustomerPracticeSelection: FC = () => {
    const [query, setQuery] = useState<string>('');
    const { selected_customer, selected_location, user_customers: customers } = useConfigStore((state) => state);

    const filtered_results: ReactElement[] = useMemo(() => {
        if (!query) {
            return customers.map((customer: EnrichedCustomer) => (
                <Spotlight.ActionsGroup key={customer.key} label={customer.customer_name}>
                    {customer.locations.map((location: EnrichedLocation) => (
                        <Spotlight.Action
                            onClick={() => {
                                useConfigStore.setState({
                                    selected_customer: customer,
                                    selected_location: location,
                                });
                            }}
                            key={`${customer.key}_${location.key}`}
                            label={location.title}
                        />
                    ))}
                </Spotlight.ActionsGroup>
            ));
        }

        const formatted_query = query.toLowerCase().trim();

        const filtered_options: ReactElement[] = [];

        customers.forEach((customer: EnrichedCustomer) => {
            const is_customer_name_match = customer.customer_name.toLowerCase().trim().includes(formatted_query);
            const filtered_locations = customer.locations.filter((location) =>
                location.title.toLowerCase().trim().includes(formatted_query)
            );

            if (!is_customer_name_match && filtered_locations.length == 0) {
                return;
            }

            filtered_options.push(
                <Spotlight.ActionsGroup key={customer.key} label={customer.customer_name}>
                    {(is_customer_name_match ? customer.locations : filtered_locations).map(
                        (location: EnrichedLocation) => (
                            <Spotlight.Action
                                onClick={() => {
                                    useConfigStore.setState({
                                        selected_customer: customer,
                                        selected_location: location,
                                    });
                                }}
                                key={`${customer.key}_${location.key}`}
                                label={location.title}
                            />
                        )
                    )}
                </Spotlight.ActionsGroup>
            );
        });

        return filtered_options;
    }, [query, customers]);

    return (
        <>
            <div className="flex items-center">
                <Heading4 className="font-bold tracking-wider">
                    {selected_customer && selected_location
                        ? `${selected_customer.customer_name} - ${selected_location.title}`
                        : 'Keine Praxis ausgewählt'}
                </Heading4>
                <Button
                    classNames={{
                        section: 'mr-1',
                    }}
                    variant="transparent"
                    onClick={spotlight.open}
                    leftSection={<Pencil className="size-3.5" />}
                >
                    Ändern
                </Button>
            </div>
            <Spotlight.Root query={query} onQueryChange={setQuery}>
                <Spotlight.Search placeholder="Suche..." leftSection={<Search className="size-6" />} />
                <Spotlight.ActionsList>
                    {filtered_results.length > 0 ? (
                        filtered_results
                    ) : (
                        <Spotlight.Empty>Für diese Suche wurden keine Ergebnisse gefunden!</Spotlight.Empty>
                    )}
                </Spotlight.ActionsList>
            </Spotlight.Root>
        </>
    );
};

export default CustomerPracticeSelection;
