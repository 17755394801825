import { AuthSession, fetchAuthSession } from 'aws-amplify/auth';
import axios, { AxiosError } from 'axios';

// eslint-disable-next-line no-restricted-syntax
export class ApiError<T = unknown> extends Error {
    constructor(
        public status: number,
        public message: string,
        public data?: T
    ) {
        super(message);
        this.name = 'ApiError';
    }
}

export const api = axios.create({
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptors.request.use(async (config) => {
    const session: AuthSession = await fetchAuthSession();

    const token = session.tokens?.idToken?.toString();
    if (token) {
        config.headers.Authorization = `${token}`;
    }
    return config;
});

api.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
        if (error.response) {
            throw new ApiError(
                error.response.status,
                //@ts-expect-error TS2339
                error.response?.data?.message || 'An error occurred',
                error.response?.data
            );
        }
        throw new ApiError(0, 'Network error occurred');
    }
);
