import { ConfigHook } from '@/models/Hooks.ts';

import { useConfigStore } from '@/stores/configStore.ts';

const useConfig = (): ConfigHook => {
    const { selected_location, selected_customer } = useConfigStore((state) => state);

    return {
        customer_id: selected_customer?.customer_id ?? '',
        instance_id: selected_location?.config.instance_id ?? '',
    };
};

export default useConfig;
