import { PrismicDataType } from '@/models/PrismicHookTypes.ts';
import { Appointment } from '@/models/appointment/Appointment.ts';
import { Document } from '@/models/documents/Document.ts';
import { FC, useState } from 'react';
import useSWR from 'swr';

import CircularLoader from '@/components/CircularLoader.tsx';
import DocumentCodePopup from '@/components/PatientModal/DocumentCodePopup.tsx';
import Paragraph from '@/components/text/Paragraph.tsx';

import usePrismic from '@/hooks/usePrismic.tsx';

import { getDocumentsByUserAndAppointment } from '@/services/documentsService.ts';

import { downloadDocument } from '@/utils/formUtils.ts';

import DocumentExchangeItem from './DocumentExchangeItem.tsx';

interface Props {
    appointment: Appointment;
}

const DocumentExchange: FC<Props> = ({ appointment }) => {
    const { data: medical_records } = usePrismic(PrismicDataType.MEDICAL_RECORDS);
    const [show_modal, setShowModal] = useState(false);
    const [document_to_download_after_code_input, setDocumentToDownloadAfterCodeInput] = useState<string | undefined>();

    const { data: documents, isLoading: is_loading } = useSWR<Document[]>(
        ['appointment-documents', appointment.user_id, appointment.id],
        () => getDocumentsByUserAndAppointment(appointment.user_id, appointment.id)
    );

    const download = async (document_id: string): Promise<void> => {
        await downloadDocument(appointment, document_id, () => {
            setShowModal(true);
            setDocumentToDownloadAfterCodeInput(document_id);
        });
    };

    const onInputCodeSuccess = () => {
        if (document_to_download_after_code_input) download(document_to_download_after_code_input);
    };

    return (
        <div className="flex w-full flex-col gap-4">
            <DocumentCodePopup setVisible={setShowModal} visible={show_modal} onInputCodeSuccess={onInputCodeSuccess} />
            <Paragraph className="text-sm uppercase text-gray-500">Austausch von Dokumenten</Paragraph>
            <section className="flex flex-col gap-2">
                {is_loading && <CircularLoader />}
                {(!documents || documents.length === 0) && <p>{medical_records?.no_documents_found[0]?.text}</p>}
                {documents?.map((document) => (
                    <DocumentExchangeItem document={document} key={document?.id} downloadDocument={download} />
                ))}
            </section>
        </div>
    );
};

export default DocumentExchange;
