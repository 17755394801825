import { Insurance, Priority } from '@/models/appointment/Appointment.ts';
import { AppointmentBookingFormValues } from '@/models/appointment/AppointmentBookingFormValues.ts';
import { AppointmentFormOptions } from '@/models/appointment/BookingFormOptions.ts';
import { EnrichedPractitionerData } from '@/models/appointment/EnrichedPractitionerData.ts';
import { book_appointment_form_schema } from '@/schemas/BookAppointmentForm.schema.ts';
import { ActionIcon, Button, FocusTrap, Loader, NumberInput, Popover, Select, Switch, Textarea } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { randomId, useToggle } from '@mantine/hooks';
import { motion } from 'framer-motion';
import { Pencil } from 'lucide-react';
import { FC, useMemo, useState } from 'react';

import { CustomAppointmentTypeSelect } from '@/components/AppointmentScheduler/CustomAppointmentSelect.tsx';
import { CustomCalendarSelect } from '@/components/AppointmentScheduler/CustomCalendarSelect.tsx';
import PriorityLabel from '@/components/AppointmentScheduler/PriorityLabel.tsx';
// import TimeslotCalendarAccordion from '@/components/AppointmentScheduler/TimeslotCalendarAccordion.tsx';
import ButtonSelection from '@/components/ButtonSelection.tsx';
import DateTimePickerInput from '@/components/DateTimePickerInput.tsx';
import OverlaySection from '@/components/OverlaySection.tsx';
import Form from '@/components/form/Form.tsx';
import FormSection from '@/components/form/FormSection.tsx';
import Paragraph from '@/components/text/Paragraph.tsx';

import useAppointmentBookingFormOptions from '@/hooks/useEnrichedPractitionerData.ts';

import { dayjs } from '@/utils/dayjsSetup.ts';
import {
    getDefaultDurationFromAppointmentTypeId,
    getFormOptions,
} from '@/utils/practitioner/bookingFormOptionsUtils.ts';
import { filterEnrichedDataBySubLocationId } from '@/utils/practitioner/cachedDataUtils.ts';
import { cn } from '@/utils/styleUtils.ts';

interface Props<T> {
    setIsFormValid: (value: boolean) => void;
    setFormData: (form_data: T) => void;
    initialValues: T;
    onDelete: () => void;
    disableCancel: boolean;
    allowTransition: boolean;
    enableSteps: boolean;
}

enum Step {
    PRACTICE = 'PRACTICE',
    INSURANCE = 'INSURANCE',
    PRACTITIONER = 'PRACTITIONER',
    APPOINTMENT_TYPE = 'APPOINTMENT_TYPE',
    TIMESLOT = 'TIMESLOT',
    ADDITIONAL_INFO = 'ADDITIONAL_INFO',
}

const AppointmentForm: FC<Props<AppointmentBookingFormValues>> = ({
    setIsFormValid,
    setFormData,
    initialValues,
    onDelete,
    disableCancel,
    allowTransition,
    enableSteps,
}) => {
    const [selected_sub_location_id, setSelectedSubLocationId] = useState<string | undefined>(
        initialValues.sub_location_doc_cirrus_id
    );
    const [selected_calendar_id, setSelectedCalendarId] = useState<string | undefined>(
        initialValues.calendar_doc_cirrus_id
    );
    const [selected_appointment_type_id, setSelectedAppointmentTypeId] = useState<string | undefined>(
        initialValues.apt_type_id_or_schedule_type_dc_id
    );
    const [active_step, toggleActiveStep] = useToggle(Object.values(Step));
    const [is_date_popover_opened, toggleDatePopover] = useToggle([false, true]);
    const [is_showing_timeslot_calendar, toggle] = useToggle([false, true]);

    const onValuesChange = (values: AppointmentBookingFormValues) => {
        setFormData(values);
    };

    // TODO: fix default practitioner
    // const default_practitioner = useMemo(
    //     () => doctor_options.find((item) => item.value === initialValues.practitioner)?.label,
    //     [doctor_options, initialValues]
    // );

    // All structured enriched data un filtered.
    const { data, is_loading } = useAppointmentBookingFormOptions();

    // Filter data by selected location
    const data_filtered_by_selected_location: EnrichedPractitionerData | undefined = useMemo(() => {
        if (!data) return undefined;

        if (!selected_sub_location_id) return data;

        return filterEnrichedDataBySubLocationId(data, selected_sub_location_id);
    }, [data, selected_sub_location_id]);

    const form_options: AppointmentFormOptions | undefined = useMemo(() => {
        if (data_filtered_by_selected_location) {
            return getFormOptions(data_filtered_by_selected_location, selected_calendar_id);
        }

        return undefined;
    }, [data_filtered_by_selected_location, selected_calendar_id]);

    const onSelectAppointmentTypeIdUpdateDefaultDuration = (
        id: string | undefined,
        form: UseFormReturnType<AppointmentBookingFormValues>
    ) => {
        if (id && form_options) {
            const duration_in_minutes = getDefaultDurationFromAppointmentTypeId(id, form_options);

            if (duration_in_minutes) {
                form.setFieldValue('duration', duration_in_minutes);
            }
        }
    };

    const form_id = useMemo(() => randomId(), []);

    const priority_options = useMemo(
        () =>
            Object.values(Priority).map((priority) => ({
                id: `${form_id}_${priority}`,
                value: priority,
                label: <PriorityLabel priority={priority} />,
            })),
        [Priority, form_id]
    );

    const [default_sub_location_value] = useMemo(() => {
        return [
            form_options?.all_sub_locations.find((item) => item.value === initialValues.sub_location_doc_cirrus_id)
                ?.value,
        ];
    }, [form_options, initialValues]);

    if (is_loading) {
        return (
            <div className="flex items-center justify-center">
                <Loader />
            </div>
        );
    }

    if (!form_options) {
        // TODO: return a proper error message
        return <div>Something went wrong</div>;
    }

    return (
        <OverlaySection className="shadow-sm">
            <motion.div
                initial={{ opacity: allowTransition ? 0 : 1 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.2, delay: 0.3 }}
                className="w-full"
            >
                <Form
                    handleSubmit={() => {}} // :(
                    initialValues={initialValues}
                    className="flex w-full flex-col gap-4"
                    schema={book_appointment_form_schema}
                    onValuesChange={onValuesChange}
                    validateInputOnChange
                    checkIfValid={setIsFormValid}
                >
                    {(form) => (
                        <>
                            <FormSection>
                                <Select
                                    classNames={{
                                        dropdown: 'shadow-sm',
                                    }}
                                    searchable
                                    allowDeselect={false}
                                    label="Betriebsstätte" // TODO: i18n
                                    placeholder="Pick value"
                                    data={form_options.all_sub_locations}
                                    defaultValue={default_sub_location_value}
                                    onOptionSubmit={(value) => {
                                        form.getInputProps('sub_location_doc_cirrus_id').onChange(value);
                                        setSelectedSubLocationId(value);
                                        if (active_step === Step.PRACTICE) {
                                            toggleActiveStep(Step.INSURANCE);
                                        }
                                    }}
                                    data-autofocus={enableSteps}
                                />
                                {/* TODO: Create reusable input that's wrapped with FocusTrap */}
                                <FocusTrap active={enableSteps && active_step === Step.INSURANCE}>
                                    <Select
                                        classNames={{
                                            dropdown: 'shadow-sm',
                                        }}
                                        searchable
                                        allowDeselect={false}
                                        label="Kostenträger" // TODO: i18n
                                        data={[...new Set(Object.values(Insurance))]}
                                        {...form.getInputProps('insurance')}
                                        onOptionSubmit={(value) => {
                                            form.getInputProps('insurance').onChange(value);
                                            if (active_step === Step.INSURANCE) {
                                                toggleActiveStep(Step.PRACTITIONER);
                                            }
                                        }}
                                    />
                                </FocusTrap>
                            </FormSection>

                            {/* Practitioner calendars and appointment type */}
                            <FormSection
                                classNames={{
                                    wrapper: cn(
                                        enableSteps &&
                                            [Step.PRACTICE, Step.INSURANCE].includes(active_step) &&
                                            'pointer-events-none opacity-0',
                                        'transition duration-500'
                                    ),
                                }}
                            >
                                <CustomCalendarSelect
                                    autoFocusActive={enableSteps && active_step === Step.PRACTITIONER}
                                    label="Facharzt" // TODO: i18n
                                    defaultCalendarDocCirrusId={selected_calendar_id}
                                    data={form_options.practitioners.map((practitioner) => ({
                                        id: practitioner.id,
                                        name: practitioner.name,
                                        calendars: practitioner.calendars.map((c) => ({
                                            doc_cirrus_id: c.value,
                                            doc_cirrus_name: c.label,
                                        })),
                                    }))}
                                    setSelectedCalendarId={(calendar_id) => {
                                        form.getInputProps('calendar_doc_cirrus_id').onChange(calendar_id);
                                        setSelectedCalendarId(calendar_id);
                                        if (active_step === Step.PRACTITIONER) {
                                            toggleActiveStep(Step.APPOINTMENT_TYPE);
                                        }
                                    }}
                                />
                                <CustomAppointmentTypeSelect
                                    autoFocusActive={enableSteps && active_step === Step.APPOINTMENT_TYPE}
                                    label="Terminart" // TODO: i18n
                                    placeholder="Select appointment"
                                    defaultAptTypeIdOrScheduleTypeId={selected_appointment_type_id}
                                    setSelectedAppointmentTypeId={(id) => {
                                        form.getInputProps('apt_type_id_or_schedule_type_dc_id').onChange(id);
                                        setSelectedAppointmentTypeId(id);
                                        onSelectAppointmentTypeIdUpdateDefaultDuration(id, form);
                                        if (active_step === Step.APPOINTMENT_TYPE) {
                                            toggleActiveStep(Step.TIMESLOT);
                                            toggleDatePopover();
                                        }
                                    }}
                                    data={[...form_options.appointment_types, ...form_options.unmapped_schedule_types]}
                                    disabled={is_showing_timeslot_calendar}
                                />
                            </FormSection>
                            <Popover
                                position="bottom"
                                shadow="md"
                                opened={is_date_popover_opened}
                                onChange={toggleDatePopover}
                                disabled={is_showing_timeslot_calendar}
                                onClose={() => {
                                    if (active_step === Step.TIMESLOT) {
                                        toggleActiveStep(Step.ADDITIONAL_INFO);
                                    }
                                }}
                                trapFocus
                            >
                                <div
                                    className={cn(
                                        'flex w-full justify-between gap-2 rounded border border-slate-300 bg-slate-100 p-2 transition duration-500',
                                        is_showing_timeslot_calendar && 'border-transparent text-slate-400',
                                        enableSteps &&
                                            [
                                                Step.PRACTICE,
                                                Step.INSURANCE,
                                                Step.PRACTITIONER,
                                                Step.APPOINTMENT_TYPE,
                                            ].includes(active_step) &&
                                            'opacity-0'
                                    )}
                                >
                                    <Paragraph className="text-sm">
                                        {`Termin am `}
                                        <span
                                            className={cn(
                                                `relative after:absolute after:bottom-0 after:left-0 after:h-[1px] after:w-full after:bg-black after:content-['']`,
                                                !form.getValues().date_time && 'invisible after:visible'
                                            )}
                                        >
                                            {dayjs(form.getValues().date_time as string).format('ddd DD MMM YYYY')}
                                        </span>
                                        {` um ${
                                            form.getValues().date_time
                                                ? dayjs(form.getValues().date_time as string).format('HH:mm')
                                                : '--:--'
                                        } für ${form.getValues().duration} min`}
                                    </Paragraph>
                                    <Popover.Target>
                                        <ActionIcon
                                            variant="transparent"
                                            aria-label="Date and Duration"
                                            onClick={() => toggleDatePopover()}
                                            disabled={is_showing_timeslot_calendar}
                                            className="disabled:bg-transparent"
                                            size="sm"
                                        >
                                            <Pencil
                                                className={cn(
                                                    'text-black',
                                                    is_showing_timeslot_calendar && 'text-slate-400'
                                                )}
                                            />
                                        </ActionIcon>
                                    </Popover.Target>
                                </div>
                                <Popover.Dropdown className="flex flex-col gap-3">
                                    <DateTimePickerInput
                                        value={form.getInputProps('date_time').defaultValue}
                                        onChange={form.getInputProps('date_time').onChange}
                                        additionalComponents={
                                            <NumberInput
                                                defaultValue={initialValues.duration}
                                                label="Dauer"
                                                {...form.getInputProps('duration')}
                                            />
                                        }
                                    />
                                </Popover.Dropdown>
                            </Popover>
                            <Switch
                                label="Alle Kalender anzeigen"
                                onChange={() => toggle()}
                                className={cn(
                                    'w-52 transition duration-500',
                                    enableSteps &&
                                        [
                                            Step.PRACTICE,
                                            Step.INSURANCE,
                                            Step.PRACTITIONER,
                                            Step.APPOINTMENT_TYPE,
                                        ].includes(active_step) &&
                                        'pointer-events-none opacity-0'
                                )}
                            />

                            {/* TODO: fix with new type structure */}
                            {/* {is_showing_timeslot_calendar && form_options.practitioners.length > 0 && (
                            <TimeslotCalendarAccordion
                                doctors={doctors}
                                onSlotSelect={(practitioner_id, slot) => {
                                    form.setFieldValue('practitioner', practitioner_id);
                                    form.setFieldValue('date_time', slot);
                                }}
                                selectedPractitioner={form.getValues().practitioner as string}
                                selectedSlot={form.getValues().date_time as string | undefined}
                            />
                        )}*/}
                            <div
                                className={cn(
                                    'flex flex-col gap-2 transition duration-500',
                                    enableSteps && active_step !== Step.ADDITIONAL_INFO && 'opacity-0'
                                )}
                            >
                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                <label className="flex flex-col gap-1 text-sm font-medium">
                                    Wichtigkeit
                                    <ButtonSelection
                                        onSelect={form.getInputProps('priority').onChange}
                                        options={priority_options}
                                        numberOfOptionsInRow={11}
                                        // TODO: fix why is this not rendered properly
                                        selected={
                                            form.getValues().priority
                                                ? `${form_id}_${form.getValues().priority}`
                                                : undefined
                                        }
                                        classNames={{ option: 'border-transparent' }}
                                    />
                                </label>
                                <Textarea
                                    label="Termin Notizen (wahlweise)"
                                    placeholder="Notizen hinzufügen"
                                    {...form.getInputProps('notes')}
                                />
                            </div>
                            {!disableCancel && <Button onClick={onDelete}>Cancel</Button>}
                        </>
                    )}
                </Form>
            </motion.div>
        </OverlaySection>
    );
};

export default AppointmentForm;
