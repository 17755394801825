import { cards } from '@/constants/cards.ts';
import { Button, Divider, TextInput } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { randomId, useListState } from '@mantine/hooks';
import { ChevronLeft, ChevronRight, Eye } from 'lucide-react';
import { FC, Fragment, useState } from 'react';

import CardFlipper from '@/components/CardFlipper.tsx';
import Clock from '@/components/Clock.tsx';
import ListSelection from '@/components/ListSelection.tsx';
import Page from '@/components/Page.tsx';
import WalkInModal from '@/components/WalkInModal.tsx';
import Heading1 from '@/components/text/Heading1.tsx';
import Heading3 from '@/components/text/Heading3.tsx';

const CalendarViewPage: FC = () => {
    const [selected_date, setSelectedDate] = useState<Date | null>(new Date());
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [search, setSearch] = useState('');
    const [walk_in_modal_opened, setWalkInModalOpened] = useState<boolean>(false);

    const handleSearchChange = (value: string) => {
        setSearch(value);
        // TODO: add search action
    };

    const [calendar_list, calendar_list_handlers] = useListState([
        { label: 'Calendar 1', checked: false, key: randomId() },
        { label: 'Calendar 2', checked: false, key: randomId() },
        { label: 'Calendar 3', checked: false, key: randomId() },
    ]);

    return (
        <Page className="gap-4 p-4">
            <div className="flex w-full items-center">
                <Heading1 className="flex-grow text-4xl font-semibold">Calendar</Heading1>
                <Clock />
                <Button variant="filled" onClick={() => setWalkInModalOpened(true)}>
                    New appointment
                </Button>
            </div>
            <div className="flex h-full w-full gap-4">
                <div className="flex w-64 flex-col gap-4">
                    <TextInput
                        rightSectionPointerEvents="none"
                        rightSection={<Eye />}
                        placeholder="Search"
                        onChange={(event) => handleSearchChange(event.target.value)}
                    />
                    <DatePicker value={selected_date} allowDeselect={false} onChange={setSelectedDate} />
                    <Divider />
                    <ListSelection
                        title="Calendar"
                        allSelectedLabel="All calendars"
                        values={calendar_list}
                        handlers={calendar_list_handlers}
                    />
                    <div className="flex flex-col">
                        <Heading3 className="mb-3 uppercase">New Appointments ({cards.length})</Heading3>
                        <CardFlipper
                            prevButton={<ChevronLeft />}
                            nextButton={<ChevronRight />}
                            classNames={{
                                controls: { container: 'absolute -top-9 right-0', disabled: 'opacity-20' },
                                card: 'rounded-xl border-2 border-gray-300 p-3',
                            }}
                            gap={8}
                        >
                            {cards.map(({ id, time, name, practitioner, type }) => (
                                <Fragment key={id}>
                                    <p className="mb-3 text-xs text-gray-500">{`${time} - ${name}`}</p>
                                    <p>{type}</p>
                                    <p>{practitioner}</p>
                                </Fragment>
                            ))}
                        </CardFlipper>
                    </div>
                </div>
                <div className="flex w-full flex-col bg-slate-200">TODO calendar section</div>
            </div>
            <WalkInModal opened={walk_in_modal_opened} onClose={() => setWalkInModalOpened(false)} />
        </Page>
    );
};

export default CalendarViewPage;
