import { EnrichedDCConfig } from '@/models/EnrichedPrismicDocuments.ts';
import { Appointment } from '@/models/appointment/Appointment.ts';

export enum ColumnType {
    EXPECTED = 'expected',
    WAITING_ROOM = 'waitingRoom',
    TREATMENT_ROOM = 'treatmentRoom',
    DISCHARGED = 'discharged',
}

export interface KanbanColumn {
    id: string;
    title: string;
    type: ColumnType;
    collapsed: boolean;
    appointments: Appointment[];
}

// TODO: remove as soon as EnrichedDCConfig is updated and will contain updated prismic data
export interface Room {
    room_id: string;
    room_name: string;
    room_type: string;
}

// TODO: remove as soon as EnrichedDCConfig is updated and will contain updated prismic data
export type EnrichedDCConfigPlusRooms = EnrichedDCConfig & {
    rooms?: Room[];
};
