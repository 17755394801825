import { api } from '@/lib/api-client.ts';
import { PractitionerDtoWrapper } from '@/models/PractitionerDto.ts';
import { Appointment } from '@/models/appointment/Appointment.ts';
import { GetAppointmentsForTodayDto } from '@/models/appointment/GetAppointmentsForTodayDto.ts';

import { buildUrlWithQueryParams } from '@/utils/apiUtils.ts';

import { ServiceUrl } from './ServiceURL.ts';

enum UserDataType {
    LINKED_USERS = 'linked_users',
    PRISMIC_PROFILES = 'prismic_profiles',
    PRISMIC_HUBS = 'prismic_hubs',
    PRISMIC_CUSTOMER_CONFIGS = 'prismic_customer_configs',
    PRISMIC_PROFESSIONAL_TYPES = 'prismic_professional_types',
    PRISMIC_SERVICES_OR_TREATMENTS = 'prismic_services_or_treatments',
}

enum UserDataTypeOption {
    INCLUDE_UNMAPPED_SCHEDULE_TYPES = 'include_unmapped_schedule_types',
}

/**
 * Fetch all practitioner infos from availability cached data.
 */
export const fetchPractitionerByCustomerId = async (customer_id: string): Promise<PractitionerDtoWrapper> => {
    try {
        const api_url = buildUrlWithQueryParams(
            `${ServiceUrl.PRACTITIONER_API_DOMAIN.SECURE_ADMIN.USER.AVAILABILITY_CACHED_DATA}`,
            {
                dataType: UserDataType.LINKED_USERS,
                [UserDataTypeOption.INCLUDE_UNMAPPED_SCHEDULE_TYPES]: 'true',
            }
        );

        // Make the API request
        const response = await api.get(api_url, {
            headers: {
                customer_id: customer_id,
            },
        });

        return response.data.data;
    } catch (error) {
        // TODO: make this better
        throw new Error(`Wrong API call: ${error}`);
    }
};

export const fetchAppointmentsForToday = async (
    customer_id: string,
    instance_id: string
): Promise<GetAppointmentsForTodayDto> => {
    try {
        if (!customer_id || !instance_id) {
            throw new Error(`missing customer id or instance id`);
        }

        const api_url = buildUrlWithQueryParams(
            `${ServiceUrl.PATIENT_API_DOMAIN.SECURE_ADMIN.USER.GET_APPOINTMENTS_FOR_TODAY}`,
            {
                instance_id: instance_id,
            }
        );

        // Make the API request
        const response: { data: GetAppointmentsForTodayDto } = await api.get(api_url, {
            headers: {
                customer_id: customer_id,
            },
        });

        return response.data;
    } catch (error) {
        // TODO: make this better
        throw new Error(`Wrong API call: ${error}`);
    }
};

export const fetchAppointmentsCheckInData = async ({
    appointments,
    customer_id,
}: {
    appointments: Appointment[];
    customer_id: string;
}) => {
    try {
        // TODO: update with new service URL properties
        const response = await Promise.allSettled(
            appointments.map((appointment) => {
                const api_url = buildUrlWithQueryParams(
                    `${ServiceUrl.PATIENT_API_DOMAIN.SECURE_ADMIN.CALL_SYSTEM_API}/users/number`,
                    {
                        user_id: appointment.data.generated_id ?? appointment.user_id,
                        appointment_id: appointment.id,
                    }
                );

                return api.get(api_url, {
                    headers: {
                        customer_id: customer_id,
                    },
                });
            })
        );

        return response.filter((result) => result.status === 'fulfilled').map((result) => result.value.data);
    } catch (error) {
        throw new Error(`Error fetching check-in data: ${error}`);
    }
};
