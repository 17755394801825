import { Appointment } from '@/models/appointment/Appointment.ts';
import { FC, useMemo } from 'react';

import { dayjs } from '@/utils/dayjsSetup.ts';

import Paragraph from './text/Paragraph.tsx';

interface Props {
    appointment: Appointment;
}

const TreatmentItem: FC<Props> = ({ appointment }) => {
    const formatted_date = useMemo(() => dayjs(appointment.start).format('DD MMM YYYY'), [appointment]);

    return (
        <div key={appointment.id} className="flex flex-col">
            <Paragraph className="text-gray-500">{formatted_date}</Paragraph>
            <Paragraph className="font-normal text-gray-900">
                {'Dr. Deez Knots' /* get the name of the professional */}
            </Paragraph>
        </div>
    );
};

export default TreatmentItem;
