import { PrismicDataType } from '@/models/PrismicHookTypes.ts';
import { Document } from '@/models/documents/Document.ts';
import { FC, useMemo, useState } from 'react';

import CircularLoader from '@/components/CircularLoader.tsx';
import ExternalLink from '@/components/ExternalLink.tsx';
import Paragraph from '@/components/text/Paragraph.tsx';

import usePrismic from '@/hooks/usePrismic.tsx';

import { dayjs } from '@/utils/dayjsSetup.ts';
import { getDocumentType } from '@/utils/formUtils.ts';

interface Props {
    document: Document;
    downloadDocument: (document_id: string) => Promise<void>;
}

const DocumentExchangeItem: FC<Props> = ({ document, downloadDocument }) => {
    const [is_loading, setIsLoading] = useState(false);
    const { data: medical_records } = usePrismic(PrismicDataType.MEDICAL_RECORDS);
    const formatted_date = useMemo(() => dayjs(document.meta.created_at).format('DD MMM YYYY'), [document]);

    const handleDownloadDocument = async (documentId: string) => {
        setIsLoading(true);
        await downloadDocument(documentId);
        setIsLoading(false);
    };

    const document_name = useMemo(
        () => `${getDocumentType(medical_records?.type_of_doc_response, document.type)} - ${document.name}`,
        [medical_records, document]
    );

    return (
        <div className="center flex w-full justify-between">
            <div className="flex gap-4">
                <ExternalLink
                    classNames={{ anchor: 'font-medium text-black', icon: 'text-black' }}
                    onClick={() => handleDownloadDocument(document.id)}
                    text={document_name}
                />
                {is_loading && <CircularLoader size="sm" />}
            </div>
            <Paragraph className="text-slate-500">{formatted_date}</Paragraph>
        </div>
    );
};

export default DocumentExchangeItem;
