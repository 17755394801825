import { Button, Divider, Select } from '@mantine/core';
import { FC, useState } from 'react';

import Paragraph from '@/components/text/Paragraph.tsx';

interface Props {
    onDelete: () => void;
    onCancel: () => void;
}

const DeleteAppointment: FC<Props> = ({ onDelete, onCancel }) => {
    const [delete_reason, setDeleteReason] = useState<string | null>(null);

    const handleCancel = () => {
        onCancel();
        setDeleteReason(null);
    };

    return (
        <div className="flex w-full flex-col items-start gap-4">
            <Divider className="w-full" />
            <div className="flex w-full flex-col gap-4">
                <Paragraph>Wählen Sie einen Grund zum Stornieren des Termins</Paragraph>
                <Select
                    label="Grund"
                    placeholder="Bitte auswählen"
                    value={delete_reason}
                    onChange={setDeleteReason}
                    data={['Vom Patienten abgesagt', 'Vom Arzt abgesagt']}
                />
                <div className="flex gap-2">
                    <Button disabled={!delete_reason} color="red" onClick={onDelete}>
                        Löschen
                    </Button>
                    <Button variant="outline" onClick={handleCancel}>
                        Abbrechen
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default DeleteAppointment;
