import { cards } from '@/constants/cards.ts';
import { mocked_dev_appointment } from '@/constants/mocked_dev_appointment.ts';
import { table } from '@/constants/table.tsx';
import { IndicatorStatus } from '@/models/IndicatorStatus.ts';
import { RoomOption } from '@/models/RoomOptions.ts';
import { Button, ComboboxItem, Table, Title } from '@mantine/core';
import { randomId, useListState } from '@mantine/hooks';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { FC, Fragment, useState } from 'react';

import AppointmentScheduler from '@/components/AppointmentScheduler/AppointmentScheduler.tsx';
import BaseModal from '@/components/BaseModal.tsx';
import CardFlipper from '@/components/CardFlipper.tsx';
import CardReader from '@/components/CardReader.tsx';
import EternoBadge from '@/components/EternoBadge.tsx';
import ListSelection from '@/components/ListSelection.tsx';
import ListViewHeader from '@/components/ListView/ListViewHeader.tsx';
import { ListViewMode } from '@/components/ListView/ListViewMode.ts';
import Page from '@/components/Page.tsx';
import PatientModal from '@/components/PatientModal/PatientModal.tsx';
import StatusLamp from '@/components/StatusLamp.tsx';
import TableRow from '@/components/TableRow.tsx';
import WalkInModal from '@/components/WalkInModal.tsx';
import FormQRCode from '@/components/form/FormQRCode.tsx';
import PatientEditDataForm from '@/components/form/PatientEditDataForm.tsx';

const PlaygroundPage: FC = () => {
    const [opened, setOpened] = useState<boolean>(false);
    const [open_patient_modal, setOpenPatientModal] = useState<boolean>(false);
    const [code_modal_opened, setCodeModalOpened] = useState<boolean>(false);
    const [walk_in_modal_opened, setWalkInModalOpened] = useState<boolean>(false);
    const [patient_edit_modal_opened, setPatientEditModalOpened] = useState<boolean>(false);
    const [appointment_scheduler_opened, setAppointmentSchedulerOpened] = useState<boolean>(false);

    // Calendar selection component
    const [long_list_values, long_list_handlers] = useListState([
        { label: 'Calendar 1', checked: false, key: randomId() },
        { label: 'Calendar 2', checked: false, key: randomId() },
        { label: 'Calendar 3', checked: false, key: randomId() },
        { label: 'Calendar 4', checked: false, key: randomId() },
        { label: 'Calendar 5', checked: false, key: randomId() },
        { label: 'Calendar 6', checked: false, key: randomId() },
        { label: 'Calendar 7', checked: false, key: randomId() },
        { label: 'Calendar 8', checked: false, key: randomId() },
    ]);

    const [short_list_values, short_list_handlers] = useListState([
        { label: 'Calendar 1 super long calendar icon long long... every inch count', checked: false, key: randomId() },
        { label: 'Calendar 2', checked: false, key: randomId() },
        { label: 'Calendar 3', checked: false, key: randomId() },
    ]);

    // ListViewHeader data
    const practitioner_options: ComboboxItem[] = [
        {
            value: '',
            label: 'Alle Behandler:in',
        },
        {
            value: 'nicht',
            label: 'nicht Behandler',
        },
    ];

    const room_options: RoomOption[] = [
        { value: '1', label: 'Zimmer 1' },
        { value: '2', label: 'Zimmer 2' },
        { value: '3', label: 'Zimmer 3' },
        { value: '4', label: 'Zimmer 4' },
        { value: '5', label: 'Zimmer 5' },
    ];

    return (
        <Page className="flex flex-col gap-4 pb-10">
            <Button variant="outline" onClick={() => setWalkInModalOpened(true)}>
                Walk In Patient
            </Button>
            <ListViewHeader
                practitionerOptions={practitioner_options}
                roomOptions={room_options}
                defaultView={ListViewMode.LIST}
            />
            <StatusLamp status={IndicatorStatus.ACTIVE} text="test" />
            <div className="h-52 w-80 bg-yellow-50">
                <ListSelection
                    title="Calendar"
                    allSelectedLabel="All calendars"
                    values={long_list_values}
                    handlers={long_list_handlers}
                />
            </div>
            <div className="mt-5 w-72 bg-yellow-50">
                <ListSelection
                    title="Calendar"
                    allSelectedLabel="All calendars"
                    values={short_list_values}
                    handlers={short_list_handlers}
                />
            </div>
            <div className="w-full">
                <Title order={2} className="mb-6">
                    Upcoming Appointments
                </Title>
                <Table layout="fixed">
                    <Table.Thead className="h-8 border-b">
                        <Table.Tr>
                            {table.columns.map((col, index) => (
                                <Table.Th
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={`appointments_col_${index}`}
                                    className="px-0 font-medium"
                                    style={{ width: `${table.widths[index]}%` }}
                                >
                                    {col}
                                </Table.Th>
                            ))}
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody className="border-b">
                        {table.rows.map((row, index) => (
                            <TableRow
                                // eslint-disable-next-line react/no-array-index-key
                                key={`appointments_table_row_${index}`}
                                row={row}
                                tableId="appointments_table"
                                rowIndex={index}
                                onHover={(e) => console.debug(e)}
                            />
                        ))}
                    </Table.Tbody>
                </Table>
            </div>
            <Button className="transition" onClick={() => setOpened(true)}>
                Show Modal
            </Button>
            <Button onClick={() => setCodeModalOpened(true)}>Show Code Modal</Button>
            <Button onClick={() => setPatientEditModalOpened(true)}>Show Patient Edit Modal</Button>
            <BaseModal
                opened={opened}
                onClose={() => setOpened(false)}
                title="Modal Title"
                footer={[
                    <Button key={1}>Button 2</Button>,
                    <div key={2} className="flex gap-2">
                        <Button>Button 3</Button>
                        <Button>Button 4</Button>
                    </div>,
                ]}
            >
                <CardReader />
            </BaseModal>
            <BaseModal
                opened={code_modal_opened}
                onClose={() => setCodeModalOpened(false)}
                title="Lesen Sie den QR-Code"
            >
                <FormQRCode code={'659743'} />
            </BaseModal>
            <EternoBadge text="Badge" size="lg" />
            <WalkInModal opened={walk_in_modal_opened} onClose={() => setWalkInModalOpened(false)} />
            <div className="my-9 w-2/6">
                <h4 className="mb-4 uppercase tracking-widest text-gray-500">New Appointments</h4>
                <CardFlipper
                    prevButton={<ChevronLeft />}
                    nextButton={<ChevronRight />}
                    classNames={{
                        controls: { container: 'absolute -top-10 right-0', disabled: 'opacity-20' },
                        card: 'rounded-xl border-2 border-gray-300 p-3',
                    }}
                    gap={8}
                >
                    {cards.map(({ id, time, name, practitioner, type }) => (
                        <Fragment key={id}>
                            <p className="mb-3 text-xs text-gray-500">{`${time} - ${name}`}</p>
                            <p>{type}</p>
                            <p>{practitioner}</p>
                        </Fragment>
                    ))}
                </CardFlipper>
            </div>
            <button onClick={() => setOpenPatientModal(true)}>Show Patient Modal</button>
            <PatientModal
                opened={open_patient_modal}
                onClose={() => setOpenPatientModal(false)}
                appointment={mocked_dev_appointment}
            />
            <BaseModal
                opened={patient_edit_modal_opened}
                onClose={() => setPatientEditModalOpened(false)}
                title="Patient Informationen bearbeiten"
                footer={[
                    <Button key="1" variant="outline">
                        Zurück
                    </Button>,
                    <Button key="2">Speichern</Button>,
                ]}
            >
                <PatientEditDataForm
                    initialValues={{
                        insurance_type: '',
                        insurance_name: '',
                        email: '',
                        country: '',
                        phone: '',
                        street: '',
                        house_number: '',
                        additional_address: '',
                        postal_code: 0,
                        city: '',
                        notes: '',
                    }}
                    handleSubmit={() => {}}
                />
            </BaseModal>
            <button onClick={() => setAppointmentSchedulerOpened(true)}>Show Appointment Scheduler</button>
            <AppointmentScheduler
                opened={appointment_scheduler_opened}
                onClose={() => setAppointmentSchedulerOpened(false)}
            />
        </Page>
    );
};

export default PlaygroundPage;
