import { EnrichedCustomer, EnrichedLocation } from '@/models/EnrichedPrismicDocuments.ts';
import { CustomerConfigDocument } from '@/models/prismicTypes.ts';

import { useConfigStore } from '@/stores/configStore.ts';

import { isCorrectWhiteLabelDomain, isEternoDomain, isLocalEnvironment } from '@/utils/envUtils.ts';

export const getCustomerId = (): string => {
    const { selected_customer } = useConfigStore.getState();
    return selected_customer?.customer_id ?? '';
};

export const getInstanceId = (): string => {
    const { selected_location } = useConfigStore.getState();
    return selected_location?.config.instance_id ?? '';
};

export const filterCustomerConfigsByDomain = (customer_configs: CustomerConfigDocument[]): CustomerConfigDocument[] => {
    if (isLocalEnvironment()) {
        return customer_configs;
    }

    if (isEternoDomain()) {
        return customer_configs.filter((entry: CustomerConfigDocument) => entry.data.is_eterno_customer);
    }

    return customer_configs.filter(
        (entry: CustomerConfigDocument) =>
            !entry.data.is_eterno_customer && entry.data.domain && isCorrectWhiteLabelDomain(entry.data.domain)
    );
};

export const filterCustomersForUser = (
    customers: EnrichedCustomer[],
    customer_id_set: Set<string>,
    instance_id_set: Set<string>,
    groups_set: Set<string>
): EnrichedCustomer[] => {
    const filtered_customers = customers.reduce((acc, customer) => {
        const has_customer_id = customer_id_set.has(customer.customer_id);
        if (!has_customer_id) return acc;

        const filtered_locations: EnrichedLocation[] = customer.locations.filter(
            (location: EnrichedLocation) =>
                instance_id_set.has(location.config.instance_id) && groups_set.has(location.group_key)
        );

        acc.push({
            ...customer,
            locations: filtered_locations,
        });
        return acc;
    }, [] as EnrichedCustomer[]);

    return filtered_customers;
};
