import { FuseResultMatch } from '@/models/Fuse.ts';
import { IndicatorStatus } from '@/models/IndicatorStatus.ts';
import { NormalizedPatient } from '@/models/Patient.ts';
import { clsx } from 'clsx';
import { FC, useMemo } from 'react';

import StatusLamp from '@/components/StatusLamp.tsx';
import Paragraph from '@/components/text/Paragraph.tsx';

import { dayjs } from '@/utils/dayjsSetup.ts';

interface Props {
    patient: NormalizedPatient;
    matches: FuseResultMatch[];
}

const PatientRow: FC<Props> = ({ patient, matches }) => {
    const formatted_date = useMemo(() => dayjs(patient.account.date_of_birth).format('DD MMM YYYY'), [patient]);

    const name_instance = `${patient.name.first_name} ${patient.name.last_name}`;

    const is_name_highlighted = useMemo(
        () => matches.some((match: FuseResultMatch) => match.value === patient._normalized.user_name),
        [patient, matches]
    );
    const is_dob_highlighted = useMemo(
        () =>
            matches.some(
                (match: FuseResultMatch) =>
                    match.value === patient._normalized.date_of_birth ||
                    match.value === dayjs(patient.account.date_of_birth).format('DD.MM.YYYY')
            ),
        [patient, matches]
    );

    return (
        <div className="flex flex-row" onMouseEnter={() => console.debug(matches)}>
            <div className="w-1/4">
                <Paragraph className={clsx('w-fit px-1', is_name_highlighted && 'bg-amber-100')}>
                    {name_instance}
                </Paragraph>
            </div>
            <div className="flex w-1/4 flex-col">
                <StatusLamp status={IndicatorStatus.INACTIVE} text="asd" />
                <StatusLamp status={IndicatorStatus.ACTIVE} text="asd" />
            </div>
            {patient.insurances && (
                <div className="flex w-1/4 flex-col">
                    {/*TODO use id*/}
                    {patient.insurances.map((insurance) => (
                        <Paragraph key={insurance.id}>{insurance.name}</Paragraph>
                    ))}
                </div>
            )}
            <div className="w-1/4">
                <Paragraph className={clsx('w-fit px-1', is_dob_highlighted && 'bg-amber-100')}>
                    {formatted_date}
                </Paragraph>
            </div>
        </div>
    );
};

export default PatientRow;
