import { Divider, Select, TextInput, Textarea } from '@mantine/core';
import { FC } from 'react';

import Form, { Props as FormProps } from './Form.tsx';
import FormSection from './FormSection.tsx';

export type EditPatientFormData = {
    insurance_type: string;
    insurance_name: string;
    email: string;
    country: string;
    phone: string;
    street: string;
    house_number: string;
    additional_address: string;
    postal_code: number;
    city: string;
    notes: string;
};

type Props = Pick<FormProps<EditPatientFormData>, 'initialValues' | 'handleSubmit'>;

const insurance_type: string[] = ['GKV', 'PUBLIC'];
const insurance_name: string[] = ['BAMER', 'TK'];
const countries: string[] = ['Deutschland +49'];

const PatientDataForm: FC<Props> = ({ initialValues, handleSubmit }) => {
    return (
        <Form initialValues={initialValues} handleSubmit={handleSubmit}>
            {(form) => (
                <>
                    <FormSection>
                        <Select
                            label="Versicherungsart"
                            key={form.key('insurance_type')}
                            data={insurance_type}
                            {...form.getInputProps('insurance_type')}
                        />
                        <Select
                            label="Krankenversicherung"
                            key={form.key('insurance_name')}
                            data={insurance_name}
                            {...form.getInputProps('insurance_name')}
                        />
                    </FormSection>
                    <Divider className="w-full" />
                    <FormSection title="Kommunikation">
                        <TextInput label="E-Mail Adresse" key={form.key('email')} {...form.getInputProps('email')} />
                    </FormSection>
                    <FormSection>
                        <Select
                            label="Land"
                            placeholder="Auswählen"
                            data={countries}
                            key={form.key('country')}
                            {...form.getInputProps('country')}
                        />
                        <TextInput label="Telefonnummer" key={form.key('phone')} {...form.getInputProps('phone')} />
                    </FormSection>
                    <FormSection>
                        <TextInput label="Straße" key={form.key('street')} {...form.getInputProps('street')} />
                        <TextInput
                            label="Hausnummer"
                            key={form.key('house_number')}
                            {...form.getInputProps('house_number')}
                        />
                    </FormSection>
                    <FormSection>
                        <TextInput
                            label="Anschriftenzusatz"
                            key={form.key('additional_address')}
                            {...form.getInputProps('additional_address')}
                        />
                    </FormSection>
                    <FormSection>
                        <TextInput label="PLZ" key={form.key('postal_code')} {...form.getInputProps('postal_code')} />
                        <TextInput label="Stadt" disabled key={form.key('city')} {...form.getInputProps('city')} />
                    </FormSection>
                    <Divider className="w-full" />
                    <FormSection>
                        <Textarea
                            label="Anmerkungen"
                            className="w-full"
                            key={form.key('notes')}
                            {...form.getInputProps('notes')}
                        />
                    </FormSection>
                </>
            )}
        </Form>
    );
};

export default PatientDataForm;
