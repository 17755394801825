import { UserGroup } from '@/models/User.ts';
import { Menu } from '@mantine/core';
import { Lock, LockOpen, User } from 'lucide-react';
import { FC, useMemo } from 'react';

import useAuth from '@/hooks/useAuth.ts';

import { signOut } from '@/services/authService.ts';
import { showErrorNotification, showSuccessNotification } from '@/services/notificationService.ts';

const UserMenu: FC = () => {
    const { groups, isUserInGroup, getIdToken } = useAuth();

    const copyIdToken = async () => {
        const token = await getIdToken();
        if (!token) {
            showErrorNotification('Failed to copy!');
            return;
        }
        await navigator.clipboard.writeText(token);
        showSuccessNotification('Copied to clipboard!');
    };

    const copyVersion = async () => {
        await navigator.clipboard.writeText(import.meta.env.VITE_VERSION);
        showSuccessNotification('Version kopiert!');
    };

    const is_engineer: boolean = useMemo(
        () => isUserInGroup([UserGroup.DEV_INTERNAL_ENGINEERS, UserGroup.PROD_INTERNAL_ENGINEERS]),
        [groups]
    );

    return (
        <Menu trigger="hover">
            <Menu.Target>
                <div className="rounded-full border border-slate-300 bg-slate-100 p-1.5">
                    <User className="size-6 cursor-pointer" />
                </div>
            </Menu.Target>

            <Menu.Dropdown>
                {is_engineer && (
                    <>
                        <Menu.Item onClick={copyIdToken} leftSection={<LockOpen className="size-5" />}>
                            ID Token
                        </Menu.Item>
                        <Menu.Divider />
                    </>
                )}
                <Menu.Item onClick={signOut} leftSection={<Lock className="size-5" />}>
                    Ausloggen
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item onClick={copyVersion}>{`Version: ${import.meta.env.VITE_VERSION}`}</Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
};

export default UserMenu;
