export const getCurrentLocation = () => {
    return mocked_locations[0];
};

export const mocked_locations = [
    {
        name: 'Hamburg - DEV',
        key: 'hamburg-123',
        instanceId: 'c019c6fa-ca3c-4a6a-8a50-99e687a07e0c',
        config: {
            instance_id: [
                {
                    type: 'heading6',
                    text: 'c019c6fa-ca3c-4a6a-8a50-99e687a07e0c',
                    spans: [],
                },
            ],
            hub: {
                id: 'YZI0IhEAACEALb_y',
                type: 'hub',
                tags: [
                    'loctaion',
                    'doctor',
                    'welcome-page-anamnesis',
                    'dev',
                    'find_an_appointment',
                    'my_appointments',
                    'header_patients',
                ],
                lang: 'de-de',
                slug: '-',
                first_publication_date: '2021-11-15T10:19:17+0000',
                last_publication_date: '2024-04-17T16:58:22+0000',
                link_type: 'Document',
                isBroken: false,
            },
            patient_call_system_ip: [
                {
                    type: 'heading6',
                    text: '10.181.10.153:1337',
                    spans: [],
                },
            ],
            office_email: [],
            pcs_printers: [
                {
                    name: [
                        {
                            type: 'paragraph',
                            text: 'Printer 1',
                            spans: [],
                        },
                    ],
                    address: [
                        {
                            type: 'heading6',
                            text: '10.181.11.153:1337',
                            spans: [],
                        },
                    ],
                    certificate: [
                        {
                            type: 'paragraph',
                            text: '-----BEGIN CERTIFICATE-----\nMIIDtzCCAp+gAwIBAgIIXsk1U7OTcYIwDQYJKoZIhvcNAQELBQAwezELMAkGA1UE\nBhMCREUxEDAOBgNVBAgTB0JhdmFyaWExDzANBgNVBAcTBk11bmljaDEXMBUGA1UE\nCgwOSWx0aXMgJiBXaWVzZWwxFzAVBgNVBAsMDklsdGlzICYgV2llc2VsMRcwFQYD\nVQQDEw5GbG9yaWFuIEF1YmVsZTAgFw0yMzA4MTgxMTA3NTNaGA8yMDUxMDEwMjEx\nMDc1M1owezELMAkGA1UEBhMCREUxEDAOBgNVBAgTB0JhdmFyaWExDzANBgNVBAcT\nBk11bmljaDEXMBUGA1UECgwOSWx0aXMgJiBXaWVzZWwxFzAVBgNVBAsMDklsdGlz\nICYgV2llc2VsMRcwFQYDVQQDEw5GbG9yaWFuIEF1YmVsZTCCASIwDQYJKoZIhvcN\nAQEBBQADggEPADCCAQoCggEBAKC0dR4WDvZ8ZN3BZDK9OKCavHTjwEmAAazXkKel\nRB5egsoqKJAkJtnuyc36f7SFnUNPMCyj1MjPNbfzPyMz2XtzG80IAka3EDhDa46U\nOe903pROqrIwX3VpPanezbKNPsSuMka7Rs5Sd65GcSGDhXFZ2gfzJ5MgtKjLipIF\ndsHWV4rRVGrT7AMS9u7UgwiqEm/i2cHMo3Rq78frcsxEb1OfG1rlyASXWNAAL4hr\nIVnjRgQ8Xz4XuHXM7oPbuBbSX3u2P7WyRJZywK1epvd3gqZXWpdlrFUTRlp6DWZ8\nieGu8hMOnDg/Qavsem50A+qqnviJt/ZxIYnjNA6bTDyxWF0CAwEAAaM9MDswHQYD\nVR0OBBYEFF1Xq1hBBfpVA/VLLfMfG88yeIxoMBoGA1UdEQQTMBGCCWxvY2FsaG9z\ndIcEwKiyLDANBgkqhkiG9w0BAQsFAAOCAQEAQIZ4ZS2lK6ZoKLAqCHs/H30H0cJS\nT3hjYUQ9BetQUuKurouGFTUBAtzUw2kL1haAqcF94vgMGFdLmg1+QsKeQcPCB1Rr\nFbaX88abCuJaShzV7fmYl0kxDE62HuYHjKfvBzjhzuJKCTuvVBz88iw8GuxmT8Es\nMjPjwAolf9whpvOcQywqOP0lDKVIIupYiTL8gaBhpjgm11AHEWZuvIs8j5+nn1+0\n2CtvcqkQA494VDK+UEaAjbB7wT/xU8XgU1l1dx4auAIDyeuPTGBnagXAKMqWdX0P\nVokU9UAxCHFUJ9uKR9B3FcrbE/4AN7yfHpjdCmszSLIAw1cxkhvjWR1lBw==\n-----END CERTIFICATE-----',
                            spans: [],
                        },
                    ],
                },
                {
                    name: [
                        {
                            type: 'paragraph',
                            text: 'Printer 2',
                            spans: [],
                        },
                    ],
                    address: [
                        {
                            type: 'heading6',
                            text: '10.181.11.154:1337',
                            spans: [],
                        },
                    ],
                    certificate: [
                        {
                            type: 'paragraph',
                            text: '-----BEGIN CERTIFICATE-----\nMIIDtzCCAp+gAwIBAgIIXsk1U7OTcYIwDQYJKoZIhvcNAQELBQAwezELMAkGA1UE\nBhMCREUxEDAOBgNVBAgTB0JhdmFyaWExDzANBgNVBAcTBk11bmljaDEXMBUGA1UE\nCgwOSWx0aXMgJiBXaWVzZWwxFzAVBgNVBAsMDklsdGlzICYgV2llc2VsMRcwFQYD\nVQQDEw5GbG9yaWFuIEF1YmVsZTAgFw0yMzA4MTgxMTA3NTNaGA8yMDUxMDEwMjEx\nMDc1M1owezELMAkGA1UEBhMCREUxEDAOBgNVBAgTB0JhdmFyaWExDzANBgNVBAcT\nBk11bmljaDEXMBUGA1UECgwOSWx0aXMgJiBXaWVzZWwxFzAVBgNVBAsMDklsdGlz\nICYgV2llc2VsMRcwFQYDVQQDEw5GbG9yaWFuIEF1YmVsZTCCASIwDQYJKoZIhvcN\nAQEBBQADggEPADCCAQoCggEBAKC0dR4WDvZ8ZN3BZDK9OKCavHTjwEmAAazXkKel\nRB5egsoqKJAkJtnuyc36f7SFnUNPMCyj1MjPNbfzPyMz2XtzG80IAka3EDhDa46U\nOe903pROqrIwX3VpPanezbKNPsSuMka7Rs5Sd65GcSGDhXFZ2gfzJ5MgtKjLipIF\ndsHWV4rRVGrT7AMS9u7UgwiqEm/i2cHMo3Rq78frcsxEb1OfG1rlyASXWNAAL4hr\nIVnjRgQ8Xz4XuHXM7oPbuBbSX3u2P7WyRJZywK1epvd3gqZXWpdlrFUTRlp6DWZ8\nieGu8hMOnDg/Qavsem50A+qqnviJt/ZxIYnjNA6bTDyxWF0CAwEAAaM9MDswHQYD\nVR0OBBYEFF1Xq1hBBfpVA/VLLfMfG88yeIxoMBoGA1UdEQQTMBGCCWxvY2FsaG9z\ndIcEwKiyLDANBgkqhkiG9w0BAQsFAAOCAQEAQIZ4ZS2lK6ZoKLAqCHs/H30H0cJS\nT3hjYUQ9BetQUuKurouGFTUBAtzUw2kL1haAqcF94vgMGFdLmg1+QsKeQcPCB1Rr\nFbaX88abCuJaShzV7fmYl0kxDE62HuYHjKfvBzjhzuJKCTuvVBz88iw8GuxmT8Es\nMjPjwAolf9whpvOcQywqOP0lDKVIIupYiTL8gaBhpjgm11AHEWZuvIs8j5+nn1+0\n2CtvcqkQA494VDK+UEaAjbB7wT/xU8XgU1l1dx4auAIDyeuPTGBnagXAKMqWdX0P\nVokU9UAxCHFUJ9uKR9B3FcrbE/4AN7yfHpjdCmszSLIAw1cxkhvjWR1lBw==\n-----END CERTIFICATE-----',
                            spans: [],
                        },
                    ],
                },
            ],
            is_new_checkin_available: true,
            is_legacy_checkin_available: true,
            is_patient_call_system_active: true,
            is_custom_anamnesis_activated: true,
            is_filtering_active: true,
        },
    },
    {
        name: 'Frankfurt - DEV',
        key: 'frankfurt-123',
        instanceId: 'c019c6fa-ca3c-4a6a-8a50-99e687a07e0c',
        config: {
            instance_id: [
                {
                    type: 'heading6',
                    text: 'c019c6fa-ca3c-4a6a-8a50-99e687a07e0c',
                    spans: [],
                },
            ],
            hub: {
                id: 'Y_d6bBIAAC4AdeCe',
                type: 'hub',
                tags: [
                    'loctaion',
                    'doctor',
                    'welcome-page-anamnesis',
                    'dev',
                    'find_an_appointment',
                    'my_appointments',
                    'header_patients',
                ],
                lang: 'de-de',
                slug: '-',
                first_publication_date: '2023-02-23T14:40:50+0000',
                last_publication_date: '2024-02-28T10:54:32+0000',
                link_type: 'Document',
                isBroken: false,
            },
            patient_call_system_ip: [
                {
                    type: 'heading6',
                    text: '10.181.10.153:1337',
                    spans: [],
                },
            ],
            office_email: [
                {
                    type: 'paragraph',
                    text: 'kaufmannshaus@eternohealth.de',
                    spans: [],
                },
            ],
            pcs_printers: [
                {
                    name: [
                        {
                            type: 'paragraph',
                            text: 'Printer 1',
                            spans: [],
                        },
                    ],
                    address: [
                        {
                            type: 'heading6',
                            text: '10.181.11.153:1337',
                            spans: [],
                        },
                    ],
                    certificate: [
                        {
                            type: 'paragraph',
                            text: '-----BEGIN CERTIFICATE-----\nMIIDtzCCAp+gAwIBAgIIXsk1U7OTcYIwDQYJKoZIhvcNAQELBQAwezELMAkGA1UE\nBhMCREUxEDAOBgNVBAgTB0JhdmFyaWExDzANBgNVBAcTBk11bmljaDEXMBUGA1UE\nCgwOSWx0aXMgJiBXaWVzZWwxFzAVBgNVBAsMDklsdGlzICYgV2llc2VsMRcwFQYD\nVQQDEw5GbG9yaWFuIEF1YmVsZTAgFw0yMzA4MTgxMTA3NTNaGA8yMDUxMDEwMjEx\nMDc1M1owezELMAkGA1UEBhMCREUxEDAOBgNVBAgTB0JhdmFyaWExDzANBgNVBAcT\nBk11bmljaDEXMBUGA1UECgwOSWx0aXMgJiBXaWVzZWwxFzAVBgNVBAsMDklsdGlz\nICYgV2llc2VsMRcwFQYDVQQDEw5GbG9yaWFuIEF1YmVsZTCCASIwDQYJKoZIhvcN\nAQEBBQADggEPADCCAQoCggEBAKC0dR4WDvZ8ZN3BZDK9OKCavHTjwEmAAazXkKel\nRB5egsoqKJAkJtnuyc36f7SFnUNPMCyj1MjPNbfzPyMz2XtzG80IAka3EDhDa46U\nOe903pROqrIwX3VpPanezbKNPsSuMka7Rs5Sd65GcSGDhXFZ2gfzJ5MgtKjLipIF\ndsHWV4rRVGrT7AMS9u7UgwiqEm/i2cHMo3Rq78frcsxEb1OfG1rlyASXWNAAL4hr\nIVnjRgQ8Xz4XuHXM7oPbuBbSX3u2P7WyRJZywK1epvd3gqZXWpdlrFUTRlp6DWZ8\nieGu8hMOnDg/Qavsem50A+qqnviJt/ZxIYnjNA6bTDyxWF0CAwEAAaM9MDswHQYD\nVR0OBBYEFF1Xq1hBBfpVA/VLLfMfG88yeIxoMBoGA1UdEQQTMBGCCWxvY2FsaG9z\ndIcEwKiyLDANBgkqhkiG9w0BAQsFAAOCAQEAQIZ4ZS2lK6ZoKLAqCHs/H30H0cJS\nT3hjYUQ9BetQUuKurouGFTUBAtzUw2kL1haAqcF94vgMGFdLmg1+QsKeQcPCB1Rr\nFbaX88abCuJaShzV7fmYl0kxDE62HuYHjKfvBzjhzuJKCTuvVBz88iw8GuxmT8Es\nMjPjwAolf9whpvOcQywqOP0lDKVIIupYiTL8gaBhpjgm11AHEWZuvIs8j5+nn1+0\n2CtvcqkQA494VDK+UEaAjbB7wT/xU8XgU1l1dx4auAIDyeuPTGBnagXAKMqWdX0P\nVokU9UAxCHFUJ9uKR9B3FcrbE/4AN7yfHpjdCmszSLIAw1cxkhvjWR1lBw==\n-----END CERTIFICATE-----',
                            spans: [],
                        },
                    ],
                },
                {
                    name: [
                        {
                            type: 'paragraph',
                            text: 'Printer 2',
                            spans: [],
                        },
                    ],
                    address: [
                        {
                            type: 'heading6',
                            text: '10.181.11.154:1337',
                            spans: [],
                        },
                    ],
                    certificate: [
                        {
                            type: 'paragraph',
                            text: '-----BEGIN CERTIFICATE-----\nMIIDtzCCAp+gAwIBAgIIXsk1U7OTcYIwDQYJKoZIhvcNAQELBQAwezELMAkGA1UE\nBhMCREUxEDAOBgNVBAgTB0JhdmFyaWExDzANBgNVBAcTBk11bmljaDEXMBUGA1UE\nCgwOSWx0aXMgJiBXaWVzZWwxFzAVBgNVBAsMDklsdGlzICYgV2llc2VsMRcwFQYD\nVQQDEw5GbG9yaWFuIEF1YmVsZTAgFw0yMzA4MTgxMTA3NTNaGA8yMDUxMDEwMjEx\nMDc1M1owezELMAkGA1UEBhMCREUxEDAOBgNVBAgTB0JhdmFyaWExDzANBgNVBAcT\nBk11bmljaDEXMBUGA1UECgwOSWx0aXMgJiBXaWVzZWwxFzAVBgNVBAsMDklsdGlz\nICYgV2llc2VsMRcwFQYDVQQDEw5GbG9yaWFuIEF1YmVsZTCCASIwDQYJKoZIhvcN\nAQEBBQADggEPADCCAQoCggEBAKC0dR4WDvZ8ZN3BZDK9OKCavHTjwEmAAazXkKel\nRB5egsoqKJAkJtnuyc36f7SFnUNPMCyj1MjPNbfzPyMz2XtzG80IAka3EDhDa46U\nOe903pROqrIwX3VpPanezbKNPsSuMka7Rs5Sd65GcSGDhXFZ2gfzJ5MgtKjLipIF\ndsHWV4rRVGrT7AMS9u7UgwiqEm/i2cHMo3Rq78frcsxEb1OfG1rlyASXWNAAL4hr\nIVnjRgQ8Xz4XuHXM7oPbuBbSX3u2P7WyRJZywK1epvd3gqZXWpdlrFUTRlp6DWZ8\nieGu8hMOnDg/Qavsem50A+qqnviJt/ZxIYnjNA6bTDyxWF0CAwEAAaM9MDswHQYD\nVR0OBBYEFF1Xq1hBBfpVA/VLLfMfG88yeIxoMBoGA1UdEQQTMBGCCWxvY2FsaG9z\ndIcEwKiyLDANBgkqhkiG9w0BAQsFAAOCAQEAQIZ4ZS2lK6ZoKLAqCHs/H30H0cJS\nT3hjYUQ9BetQUuKurouGFTUBAtzUw2kL1haAqcF94vgMGFdLmg1+QsKeQcPCB1Rr\nFbaX88abCuJaShzV7fmYl0kxDE62HuYHjKfvBzjhzuJKCTuvVBz88iw8GuxmT8Es\nMjPjwAolf9whpvOcQywqOP0lDKVIIupYiTL8gaBhpjgm11AHEWZuvIs8j5+nn1+0\n2CtvcqkQA494VDK+UEaAjbB7wT/xU8XgU1l1dx4auAIDyeuPTGBnagXAKMqWdX0P\nVokU9UAxCHFUJ9uKR9B3FcrbE/4AN7yfHpjdCmszSLIAw1cxkhvjWR1lBw==\n-----END CERTIFICATE-----',
                            spans: [],
                        },
                    ],
                },
            ],
            is_new_checkin_available: true,
            is_legacy_checkin_available: true,
            is_patient_call_system_active: true,
            is_custom_anamnesis_activated: true,
            is_filtering_active: true,
        },
    },
];
