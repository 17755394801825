import { color_mapping } from '@/constants/priority.ts';
import { Priority } from '@/models/appointment/Appointment.ts';
import { FC } from 'react';

import { cn } from '@/utils/styleUtils.ts';

interface Props {
    priority: Priority;
}

const PriorityMarker: FC<Props> = ({ priority }) => (
    <div
        className={cn(
            'absolute right-0 top-0 h-0 w-0 border-[12px]',
            priority !== Priority.NONE && `border-${color_mapping[priority]}`,
            'border-b-transparent border-l-transparent'
        )}
    />
);

export default PriorityMarker;
