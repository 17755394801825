import { PrismicDataType } from '@/models/PrismicHookTypes.ts';
import { Button, PinInput } from '@mantine/core';
import { FC, useEffect, useState } from 'react';

import BaseModal from '@/components/BaseModal.tsx';
import Paragraph from '@/components/text/Paragraph.tsx';

import useAuth from '@/hooks/useAuth.ts';
import usePrismic from '@/hooks/usePrismic.tsx';

import { generateDocumentDownloadCode, validateDocumentDownloadCode } from '@/services/documentsService.ts';
import { showErrorNotification } from '@/services/notificationService.ts';

import { getErrorMessageFromPrismicContent } from '@/utils/formUtils.ts';
import { getTextFromRichTextField } from '@/utils/prismicUtils.ts';

interface Props {
    visible: boolean;
    setVisible: (isVisible: boolean) => void;
    onInputCodeSuccess: () => void;
}

enum PopupStep {
    OTP_INTRO_TEXT,
    OTP_INPUT,
}

const TIME_TO_RESEND_SECONDS = 60;

const DocumentCodePopup: FC<Props> = ({ visible, setVisible, onInputCodeSuccess }) => {
    const { email } = useAuth();
    const [popup_step, setPopupStep] = useState<PopupStep>(PopupStep.OTP_INTRO_TEXT);
    const [is_loading, setIsLoading] = useState(false);
    const [is_resend_loading, setIsResendLoading] = useState(false);
    const [code, setCode] = useState('');
    const [resend_timer, setResendTimer] = useState(TIME_TO_RESEND_SECONDS);
    const can_resend_code = resend_timer < 0;

    const { data: medical_records } = usePrismic(PrismicDataType.MEDICAL_RECORDS);

    useEffect(() => {
        const interval = setInterval(() => {
            if (resend_timer > 0) setResendTimer((timer) => timer - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const close = () => {
        setVisible(false);
    };

    const sendCode = async () => {
        const [success, error_response] = await generateDocumentDownloadCode(email);
        if (success) {
            setResendTimer(TIME_TO_RESEND_SECONDS);
            setPopupStep(PopupStep.OTP_INPUT);
        } else {
            const error_data = error_response?.data;
            if (error_data?.error_code && medical_records) {
                showErrorNotification(getErrorMessageFromPrismicContent(error_data.error_code, medical_records));
            } else {
                console.debug({ error_response });
                showErrorNotification(`${'unexpected_error_sending_email'} ${error_response?.message}`);
            }
        }
    };

    const handleResendCode = async () => {
        setIsResendLoading(true);
        await sendCode();
        setIsResendLoading(false);
    };

    const validateCode = async () => {
        const [success, error] = await validateDocumentDownloadCode(code);

        if (success) {
            setVisible(false);
            onInputCodeSuccess();
        } else if (error && error.data) {
            const error_data = error.data;
            if (error_data.error_code && medical_records) {
                showErrorNotification(getErrorMessageFromPrismicContent(error_data.error_code, medical_records));
            } else {
                showErrorNotification(`${'An error occurred while sending the email'} ${error.message ?? ''}`);
            }
        } else {
            showErrorNotification(`${'An error occurred while sending the email'})}`);
        }
    };

    const handleConfirm = async () => {
        setIsLoading(true);

        if (popup_step === PopupStep.OTP_INTRO_TEXT) {
            await sendCode();
        } else {
            await validateCode();
        }

        setIsLoading(false);
    };

    const footer = (
        <div className="flex gap-2">
            <Button variant="default" onClick={close}>
                {medical_records?.cancel_cta[0]?.text}
            </Button>
            <Button
                onClick={handleConfirm}
                disabled={popup_step !== PopupStep.OTP_INTRO_TEXT && code.length < 6}
                loading={is_loading}
            >
                {medical_records?.request_otp_button[0]?.text}
            </Button>
        </div>
    );

    return (
        <BaseModal opened={visible} onClose={close} size="lg" title={medical_records?.title[0]?.text} footer={footer}>
            <div className="flex flex-col gap-4">
                {popup_step === PopupStep.OTP_INTRO_TEXT ? (
                    <Paragraph>{getTextFromRichTextField(medical_records?.otp_description[0])}</Paragraph>
                ) : (
                    <div className="flex flex-col gap-4">
                        <Paragraph>{medical_records?.input_description[0]?.text}</Paragraph>
                        <PinInput value={code} onChange={setCode} length={6} />
                        <Button
                            variant="default"
                            onClick={handleResendCode}
                            disabled={!can_resend_code}
                            loading={is_resend_loading}
                        >
                            {can_resend_code
                                ? medical_records?.resend_otp[0]?.text
                                : medical_records?.disabled_resend_button[0]?.text.replace(
                                      'XX',
                                      resend_timer.toString()
                                  )}
                        </Button>
                    </div>
                )}
            </div>
        </BaseModal>
    );
};

export default DocumentCodePopup;
