import { Button, Tabs } from '@mantine/core';
import { FC, useMemo, useState } from 'react';

import BaseModal from './BaseModal.tsx';
import CardReader from './CardReader.tsx';
import PatientDataForm, { PatientFormData } from './form/PatientDataForm.tsx';
import SearchPatient from './searchPatient/SearchPatient.tsx';

interface Props {
    opened: boolean;
    onClose: () => void;
    onConfirm?: () => void;
}

// TODO: START testing data ============================
const cost_center: string[] = ['opt 1', 'opt 2'];
const practices: string[] = ['practice 1', 'practice 2', 'practice 3'];
// Form test
const form_initial_values: PatientFormData = {
    cost_center: cost_center[0],
    name: '',
    surname: '',
    date_of_birth: new Date(),
    street: '',
    house_number: '',
    postal_code: 12345,
    city: '',
    email: '',
    phone: '',
    practice: practices[0],
};
// END testing data ============================

const WalkInModal: FC<Props> = ({ opened, onClose, onConfirm = () => {} }) => {
    const title = 'Make appointment'; // TODO: translation
    const [active_tab, setActiveTab] = useState<string | null>('search');

    // TODO: add proper logic for switching to appointment booking modla
    const footer_element = useMemo(() => {
        switch (active_tab) {
            case 'search':
                return (
                    <div className="flex flex-row gap-2" key={0}>
                        <Button
                            key={0}
                            variant="outline"
                            onClick={() => {
                                if (onConfirm) onConfirm();
                            }}
                        >
                            Weiter ohne eGK einlesen
                        </Button>
                        <Button key={1} variant="filled" onClick={() => {}}>
                            Weiter mit eGK einlesen
                        </Button>
                    </div>
                );
            case 'read-card':
                return [
                    <Button key={0} variant="outline" onClick={() => {}}>
                        Back
                    </Button>,
                    <Button key={1} variant="filled" onClick={() => {}}>
                        Confirm
                    </Button>,
                ];
            case 'new-patient':
                return (
                    <Button variant="filled" onClick={onConfirm}>
                        Confirm New Patient
                    </Button>
                );
            default:
                return null;
        }
    }, [active_tab]);

    return (
        <BaseModal opened={opened} onClose={onClose} size="xl" title={title} footer={footer_element}>
            <Tabs value={active_tab} onChange={setActiveTab}>
                <Tabs.List className="mb-2">
                    <Tabs.Tab value="search">Search Patient</Tabs.Tab>
                    <Tabs.Tab value="read-card">Read Card</Tabs.Tab>
                    <Tabs.Tab value="new-patient">Add new Patient</Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value="search">
                    <SearchPatient />
                </Tabs.Panel>
                <Tabs.Panel value="read-card">
                    <CardReader />
                </Tabs.Panel>
                <Tabs.Panel value="new-patient">
                    <PatientDataForm
                        handleSubmit={(values) => console.debug(values)}
                        initialValues={form_initial_values}
                    />
                </Tabs.Panel>
            </Tabs>
        </BaseModal>
    );
};

export default WalkInModal;
