import { Loader } from '@mantine/core';
import { FC } from 'react';

import Page from '@/components/Page.tsx';

interface Props {
    text?: string;
}

const LoadingPage: FC<Props> = ({ text }) => {
    return (
        <Page className="flex flex-col items-center justify-center gap-8" fullScreen>
            {text && <h2 className="text-3xl">{text}</h2>}
            <Loader />
        </Page>
    );
};

export default LoadingPage;
