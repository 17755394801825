import { Patient } from '@/models/Patient.ts';
import { Button } from '@mantine/core';
import { Pencil } from 'lucide-react';
import { FC } from 'react';

import PatientInfoItem from '@/components/PatientModal/PatientInfoItem.tsx';
import Paragraph from '@/components/text/Paragraph.tsx';

interface Props {
    patient: Patient;
}

const PatientInformation: FC<Props> = ({ patient }) => {
    return (
        <div className="flex w-full flex-col gap-4">
            <Paragraph className="text-sm uppercase text-gray-500">Patienten informationen</Paragraph>
            <div className="flex flex-col gap-2">
                <PatientInfoItem label="Versicherungsart" value={patient.insurances[0].type} />
                <PatientInfoItem label="Krankenversicherung" value={patient.insurances[0].name} />
                <PatientInfoItem label="Telefon" value={patient?.account.patient_number} />
                <PatientInfoItem
                    label="Adresse"
                    value={`${patient?.addresses[0].street} ${patient?.addresses[0].city}`}
                />
                <PatientInfoItem label="E-mail" value={patient.primary_email} />
                <PatientInfoItem label="Anmerkungen" value={''} />
            </div>
            <div>
                <Button
                    variant="transparent"
                    className="px-0 font-semibold text-black"
                    onClick={() => console.debug('show edit')}
                    leftSection={<Pencil />}
                >
                    Patient Informationen öffnen
                </Button>
            </div>
        </div>
    );
};

export default PatientInformation;
