/* eslint-disable react/jsx-key */
// TODO: This is added to demonstrate the usage of the table row component, to be removed
import { IndicatorStatus } from '@/models/IndicatorStatus.ts';
import { Anchor, Pill } from '@mantine/core';

import StatusLamp from '@/components/StatusLamp.tsx';

export const table = {
    widths: [7, 7, 25, 25, 16, 10, 10],
    columns: [
        <span className="text-sm text-gray-500">Expected</span>,
        <span className="text-sm text-gray-500">Arrived</span>,
        <span className="text-sm text-gray-500">Name</span>,
        <span className="text-sm text-gray-500">Practitioner</span>,
        <span className="text-sm text-gray-500">Requirements</span>,
        <span className="text-sm text-gray-500">DC Profile</span>,
        <span className="text-sm text-gray-500">Status</span>,
    ],
    rows: [
        [
            <span>10:40</span>,
            <span>10:45</span>,
            <span>Christina Lüdders (GKV)</span>,
            <>
                <p>Dr. med. Susanne Tolkemitt</p>
                <p className="font-bold">Preventive medical check-up</p>
            </>,
            <>
                <StatusLamp status={IndicatorStatus.ACTIVE} text="eGK scanned" />
                <StatusLamp status={IndicatorStatus.ACTIVE} text="Documents completed" />
            </>,
            <Anchor href="https://google.com" target="_blank" underline="always">
                Patient file
            </Anchor>,
            <Pill className="bg-green-100 text-green-700">Room 1</Pill>,
        ],
        [
            <span>10:40</span>,
            <span>10:45</span>,
            <span>Martha Sinders (PKV)</span>,
            <>
                <p>Margrit Riede</p>
                <p className="font-bold">Initial consultation</p>
            </>,
            <>
                <StatusLamp status={IndicatorStatus.ACTIVE} text="eGK scanned" />
                <StatusLamp status={IndicatorStatus.INACTIVE} text="Documents completed" />
            </>,
            <Anchor href="https://google.com" target="_blank" underline="always">
                Patient file
            </Anchor>,
            <Pill className="bg-green-100 text-green-700">Room 2</Pill>,
        ],
        [
            <span>10:40</span>,
            <span>10:45</span>,
            <span>Christina Lüdders (GKV)</span>,
            <>
                <p>Dr. med. Susanne Tolkemitt</p>
                <p className="font-bold">Preventive medical check-up</p>
            </>,
            <>
                <StatusLamp status={IndicatorStatus.UNKNOWN} text="eGK scanned" />
                <StatusLamp status={IndicatorStatus.UNKNOWN} text="Documents completed" />
            </>,
            <Anchor href="https://google.com" target="_blank" underline="always">
                Patient file
            </Anchor>,
            <Pill>Checking in</Pill>,
        ],
    ],
};
