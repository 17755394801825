import { MedicalRecordsDocument, ProfessionalProfileDocument } from '@/models/prismicTypes.ts';
import { ConfigDcInstanceDocument, CustomerConfigDocument, HubDocument } from '@/models/prismicTypes.ts';

export enum PrismicDataType {
    CONFIG_ADMIN_GROUPS = 'config_admin_groups',
    MEDICAL_RECORDS = 'medical_records',
    // CONFIG_ADMIN_SCOPES = 'config_admin_scopes',
    CUSTOMER_CONFIG = 'customer_config',
    HUB = 'hub',
    CONFIG_DC_INSTANCE = 'config_dc_instance',
    PROFESSIONAL_PROFILE = 'professional_profile',
    // Add other Prismic data types here as needed
}

export type Fetchers = Partial<{
    [K in PrismicDataType]: <T extends PrismicDataType>(
        type: T,
        params?: Record<string, unknown>
    ) => Promise<PrismicReturnType[K]>;
}>;

export type PrismicReturnType = {
    [PrismicDataType.CONFIG_ADMIN_GROUPS]: KeyStringArray;
    [PrismicDataType.MEDICAL_RECORDS]: MedicalRecordsDocument['data'];
    [PrismicDataType.HUB]: { [key: string]: HubDocument['data'] };
    [PrismicDataType.CONFIG_DC_INSTANCE]: { [key: string]: ConfigDcInstanceDocument['data'] };
    [PrismicDataType.CUSTOMER_CONFIG]: CustomerConfigDocument['data'][];
    [PrismicDataType.PROFESSIONAL_PROFILE]: Map<string, ProfessionalProfileDocument['data']>;
};

export type KeyStringArray = { [key: string]: string[] };

export type KeyString = { [key: string]: string };
