import { isEmail } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { FC, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import LoadingPage from '@/pages/LoadingPage.tsx';

import { magicLinkSignIn } from '@/services/authService.ts';

import { useAuthStore } from '@/stores/authStore.ts';

const MagicLinkPage: FC = () => {
    const navigate = useNavigate();
    const [search_params] = useSearchParams();
    const is_user_set = useAuthStore((state) => state.is_user_set);

    useEffect(() => {
        if (!is_user_set) return;

        const signInWithMagicLink = async (): Promise<void> => {
            const token = search_params.get('token');
            const email = search_params.get('email');

            if (!token || !email || !isEmail(email)) {
                navigate('/', { replace: true });
                return;
            }

            try {
                await magicLinkSignIn(decodeURIComponent(email), token);
            } catch (error) {
                console.debug(error);
                notifications.show({
                    title: 'Login fehlgeschlagen',
                    message: 'Ihr Login ist leider fehlgeschlagen. Bitte versuchen Sie es noch einmal.',
                    color: 'red',
                });
                navigate('/', { replace: true });
            }
        };

        signInWithMagicLink();
    }, [is_user_set]);

    return <LoadingPage text="Biite warten" />;
};

export default MagicLinkPage;
