import { AppointmentFormOptions } from '@/models/appointment/BookingFormOptions.ts';
import { EnrichedPractitionerData } from '@/models/appointment/EnrichedPractitionerData.ts';

// TODO: add unit test
export const getFormOptions = (
    data: EnrichedPractitionerData,
    selected_calendar_id: string | undefined
): AppointmentFormOptions => {
    const result: AppointmentFormOptions = {
        all_sub_locations: data.sub_locations.map((loc) => ({
            value: loc.doc_cirrus.id,
            label: loc.doc_cirrus.name,
        })),
        appointment_types: data.appointment_types.map((apt_type) => {
            const is_offered = selected_calendar_id
                ? apt_type.doc_cirrus.dc_calendar_ids.some((id) => id === selected_calendar_id)
                : undefined;
            return {
                value: apt_type.id,
                label: apt_type.name,
                is_mapped: true,
                is_offered: is_offered,
                default_duration: apt_type.duration,
            };
        }),
        unmapped_schedule_types: data.unmapped_schedule_types.map((schedule_type) => ({
            value: schedule_type.doc_cirrus.dc_schedule_type_id,
            label: schedule_type.name,
            is_mapped: false,
            is_offer: selected_calendar_id // Check if it is offered by the selected calendar if any
                ? schedule_type.doc_cirrus.dc_calendar_ids.some((id) => id === selected_calendar_id)
                : undefined,
            default_duration: schedule_type.duration,
        })),
        practitioners: data.practitioners.map((practitioner) => ({
            id: practitioner.id,
            name: practitioner.name,
            calendars: practitioner.calendars.map((calendar) => ({
                value: calendar.doc_cirrus.id,
                label: calendar.doc_cirrus.name,
            })),
        })),
    };

    return result;
};

/**
 * id: schedule_type_id or appointment_type_id
 */
export const getDefaultDurationFromAppointmentTypeId = (
    id: string,
    form_options: AppointmentFormOptions
): number | undefined => {
    const appointments = [...form_options.appointment_types, ...form_options.unmapped_schedule_types];

    const duration = appointments.find((apt) => apt.value === id)?.default_duration;

    // TODO: support different duration unit
    if (!duration || duration.unit !== 'MINUTES') {
        return undefined;
    }

    return duration.value;
};
