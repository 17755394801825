import { useInterval } from '@mantine/hooks';
import { FC, useState } from 'react';

import Paragraph from '@/components/text/Paragraph.tsx';

import { dayjs } from '@/utils/dayjsSetup.ts';

const Clock: FC = () => {
    const [time, setTime] = useState<string>(dayjs().format('dddd, DD. MMMM YYYY | HH:mm [Uhr]'));

    useInterval(() => setTime(dayjs().format('dddd, DD. MMMM YYYY | HH:mm [Uhr]')), 1000, { autoInvoke: true });

    return <Paragraph className="px-6 text-sm font-medium uppercase tracking-wider text-gray-500">{time}</Paragraph>;
};

export default Clock;
