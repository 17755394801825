import { IndicatorStatus } from '@/models/IndicatorStatus.ts';
import FormattedForm from '@/models/forms/FormattedForm.ts';

import CircularLoader from '@/components/CircularLoader.tsx';
import StatusLamp from '@/components/StatusLamp.tsx';

interface Props {
    is_loading: boolean;
    formatted_forms_for_appointment: undefined | FormattedForm[];
}

const DisplayForms = ({ is_loading, formatted_forms_for_appointment }: Props) => {
    if (is_loading)
        return (
            <div className="flex w-full justify-center overflow-y-hidden p-4">
                <CircularLoader />
            </div>
        );

    return (
        <div className="flex w-full flex-wrap gap-4 overflow-x-hidden">
            {formatted_forms_for_appointment?.map((form) => (
                <StatusLamp
                    classNames={{ text: 'w-full max-w-[200px] truncate whitespace-nowrap' }}
                    key={form.id}
                    status={form.is_completed ? IndicatorStatus.ACTIVE : IndicatorStatus.INACTIVE}
                    text={form.name}
                    tooltip
                />
            ))}
        </div>
    );
};

export default DisplayForms;
