import { Appointment } from '@/models/appointment/Appointment.ts';

export const mocked_dev_appointment: Appointment = {
    links: {
        schedule_type_id: '62b1f2cf6643e45c5270e0f8',
        calendar_id: '62b1df9252161a5c59a083e4',
        dc_instance_id: 'c019c6fa-ca3c-4a6a-8a50-99e687a07e0c',
        schedule_id: '673ca7790e0762e77374c750',
        patient_id: '6722389d81fd2cef34131746',
        room_id: '',
        practitioner_id: '62b1dcca5684655c4c17e725',
    },
    professional: {
        key: 'hh-milena-milz',
        id: 'd1c70477-b48a-469e-9a0d-ef4f3058067c',
        type_key: 'general-medicine',
    },
    meta: {
        updated_at: '2024-11-19T15:00:23.633Z',
        created_at: '2024-11-19T14:57:52.811Z',
    },
    status: 'BOOKED',
    customer_id: '73c13030-9253-44be-a530-b77f69923ebf',
    is_cancellation_confirmed: false,
    name: 'Blutabnahme',
    user_id: '76059a94-88ec-4cdd-9f61-2c508e7a3672',
    data: {
        symptoms: [],
        is_for_self: true,
        is_returning: false,
        is_with_prescription: false,
        documents: [],
        insurance_coverage_type: 'private',
        services: [
            {
                name: 'Blutabnahme',
                key: 'general-medicine-health-check-up',
            },
        ],
        is_insurance_card_active: false,
        info: '',
    },
    location_key: 'hamburg-123',
    day: '2024-11-21',
    is_mapped: true,
    is_active: true,
    end: '2024-11-19T22:00:00.000Z',
    is_online: false,
    description: '',
    id: '333240ca-9fd1-4308-bb7c-c57fcab61619',
    is_feedback_event_sent: false,
    duration: {
        value: 30,
        units: 'MINUTES',
    },
    start: '2024-11-21T21:30:00.000Z',
};
