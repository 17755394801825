import { Appointment } from '@/models/appointment/Appointment.ts';
import { ActionIcon, Divider } from '@mantine/core';
import { Pencil } from 'lucide-react';
import { Trash2 } from 'lucide-react';
import { FC, useMemo } from 'react';

import OverlaySection from '@/components/OverlaySection.tsx';

import { dayjs } from '@/utils/dayjsSetup.ts';

import Paragraph from '../text/Paragraph.tsx';
import PatientAppointmentActions from './PatientAppointmentActions.tsx';

interface Props {
    appointment: Appointment;
    onEdit: () => void;
}

const AppointmentDetails: FC<Props> = ({ appointment, onEdit }) => {
    const formatted_date = useMemo(() => dayjs(appointment.start).format('dddd, D MMMM YYYY'), [appointment]);
    const formatted_time = useMemo(() => dayjs(appointment.start).format('HH:mm'), [appointment]);
    const practitioner = { display_name: { text: 'Dr. Max Mustermann' } };

    return (
        <OverlaySection className="relative overflow-hidden p-0">
            <div className="relative flex w-full flex-col gap-4 p-4">
                <div className="flex justify-between">
                    <div className="flex flex-col gap-1">
                        <div className="flex gap-2">
                            <Paragraph className="text-lg text-slate-500">
                                {appointment.name} - {appointment.duration.value} Min
                            </Paragraph>
                        </div>
                        <div className="flex items-center gap-2">
                            <Paragraph className="font-md">
                                {formatted_date} um {formatted_time} mit {practitioner.display_name.text}
                            </Paragraph>
                        </div>
                        <div className="flex items-center gap-2">
                            <Paragraph className="font-normal italic">{appointment.data.info}</Paragraph>
                        </div>
                    </div>
                    <div className="flex items-center gap-3">
                        <ActionIcon variant="transparent" aria-label="Settings" onClick={onEdit}>
                            <Trash2 className="size-5 cursor-pointer text-red-600" />
                        </ActionIcon>
                        <ActionIcon variant="transparent" aria-label="Settings" onClick={onEdit}>
                            <Pencil className="size-5 cursor-pointer text-black" />
                        </ActionIcon>
                    </div>
                </div>
                <Divider className="w-full" />
                <PatientAppointmentActions appointment={appointment} />
            </div>
            <div className="absolute -right-6 -top-6 h-10 w-10 rotate-45 bg-red-400" />
        </OverlaySection>
    );
};

export default AppointmentDetails;
