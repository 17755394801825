import { FC, PropsWithChildren, ReactNode } from 'react';

import Paragraph from '@/components/text/Paragraph.tsx';

export interface Props extends PropsWithChildren {
    text: string;
    icon: ReactNode;
}

const VerticalIconWithText: FC<Props> = ({ text, icon, children }) => (
    <div className="flex flex-col items-center gap-8">
        {icon}
        <Paragraph>{text}</Paragraph>
        {children}
    </div>
);

export default VerticalIconWithText;
