import { Children, FC, ReactElement, ReactNode } from 'react';

import { cn } from '@/utils/styleUtils.ts';

import SelectableItem from './SelectableItem.tsx';
import Heading3 from './text/Heading3.tsx';

interface Props {
    title?: string;
    classNames?: {
        wrapper?: string;
        item?: string;
    };
    children: ReactNode;
    handleSelect: (key: string) => void;
    selectedItem: string | null;
}

const SelectableList: FC<Props> = ({ title, classNames, children, handleSelect, selectedItem }) => {
    return (
        <div className={cn('flex flex-col gap-3', classNames?.wrapper)}>
            {title && <Heading3>{title}</Heading3>}
            {Children.map(children, (child) => {
                const child_key = (child as ReactElement).key ?? '';

                return (
                    <SelectableItem
                        key={`wrapper_${child_key}`}
                        isSelected={selectedItem === child_key}
                        onClick={() => handleSelect(child_key)}
                        className={classNames?.item}
                    >
                        {child}
                    </SelectableItem>
                );
            })}
        </div>
    );
};

export default SelectableList;
