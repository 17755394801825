import { Bell, Calendar, List } from 'lucide-react';
import { Navigate } from 'react-router-dom';

import CalendarViewPage from '@/pages/CalendarViewPage.tsx';
import ListViewPage from '@/pages/ListViewPage.tsx';
import LoginPage from '@/pages/LoginPage.tsx';
import MagicLinkPage from '@/pages/MagicLinkPage.tsx';
import PlaygroundPage from '@/pages/PlaygroundPage.tsx';
import RoomViewPage from '@/pages/RoomViewPage.tsx';

export const authenticated_routes = [
    {
        path: '/',
        element: <RoomViewPage />,
        name: 'Room view',
        key: 'room-view',
        icon: <Bell />,
    },
    {
        path: '*',
        element: <Navigate to="/" replace />,
    },
    {
        path: '/list-view',
        element: <ListViewPage />,
        name: 'List View',
        key: 'list-view',
        icon: <List />,
    },
    {
        path: '/calendar-view',
        element: <CalendarViewPage />,
        name: 'Calendar View',
        key: 'calendar-view',
        icon: <Calendar />,
    },
    {
        // TODO: remove this route it is just for testing
        path: '/playground',
        element: <PlaygroundPage />,
        // name: 'Back Office',
        // key: 'back-office',
        // icon: <Layers />,
    },
];

export const unauthenticated_routes = [
    {
        path: '/',
        element: <LoginPage />,
    },
    {
        path: '/magic-link',
        element: <MagicLinkPage />,
    },
    {
        path: '*',
        element: <Navigate to="/" replace />,
    },
];
