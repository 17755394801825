import { authenticated_routes } from '@/routes.tsx';
import { AppShell, Burger, NavLink } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { AnimatePresence } from 'framer-motion';
import { FC, ReactNode, useMemo } from 'react';

import eterno_logo from '@/assets/logo.svg';

import CustomerPracticeSelection from '@/components/CustomerPracticeSelection.tsx';
import HeaderNavigation from '@/components/HeaderNavigation.tsx';
import UserMenu from '@/components/UserMenu.tsx';

import CustomerPrinterSelection from './CustomerPrinterSelection.tsx';

interface Props {
    children: ReactNode;
}

const OfficeAppShell: FC<Props> = ({ children }) => {
    const [mobile_opened, { toggle: toggleMobile }] = useDisclosure();
    const [desktop_opened, { toggle: toggleDesktop }] = useDisclosure(true);

    const filtered_routes = useMemo(() => {
        return authenticated_routes.filter((route) => route.name && route.key);
    }, []);

    return (
        <AnimatePresence mode="wait">
            <AppShell
                header={{ height: 60 }}
                navbar={{
                    width: 300,
                    breakpoint: 'sm',
                    collapsed: { mobile: !mobile_opened, desktop: !desktop_opened },
                }}
            >
                <AppShell.Header>
                    <div className="flex h-full items-center justify-between px-4">
                        <div className="flex h-full items-center gap-5">
                            <Burger opened={mobile_opened} onClick={toggleMobile} className="block size-8 sm:hidden" />
                            <Burger
                                opened={desktop_opened}
                                onClick={toggleDesktop}
                                className="hidden size-8 sm:block"
                            />
                            <img src={eterno_logo} alt="eterno" className="h-10 w-32" />
                            <CustomerPracticeSelection />
                            <CustomerPrinterSelection />
                        </div>

                        <div className="flex items-center gap-8">
                            <HeaderNavigation />
                            <UserMenu />
                        </div>
                    </div>
                </AppShell.Header>
                <AppShell.Navbar className="p-2">
                    {filtered_routes.map((route) => (
                        <NavLink key={route.key} href={route.path} label={route.name} leftSection={route.icon} />
                    ))}
                </AppShell.Navbar>
                <AppShell.Main
                    classNames={{
                        main: 'h-svh',
                    }}
                >
                    {children}
                </AppShell.Main>
            </AppShell>
        </AnimatePresence>
    );
};

export default OfficeAppShell;
