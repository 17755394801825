import { Button, TextInput } from '@mantine/core';
import { useState } from 'react';

import BaseModal from '@/components/BaseModal.tsx';

interface TicketNumberModalProps {
    handleSave: (token: string) => Promise<void>;
    opened: boolean;
    onClose: () => void;
}

const TicketNumberModal = ({ handleSave, opened, onClose }: TicketNumberModalProps) => {
    const [is_loading, setIsLoading] = useState(false);
    const [token, setToken] = useState<string>('');

    const handleConfirm = async () => {
        if (!token) return;

        setIsLoading(true);
        await handleSave(token);
        setToken('');
        onClose();
        setIsLoading(false);
    };

    return (
        <BaseModal title="Ticket ausdrucken" opened={opened} onClose={onClose}>
            <div className="flex flex-col space-y-4">
                <TextInput
                    label="Ticketnummer"
                    type="string"
                    value={token}
                    onChange={(e) => setToken(e.currentTarget.value)}
                />

                <div className="mt-6 flex space-y-2">
                    <Button variant="subtle" color="gray" className="w-full" onClick={onClose}>
                        Abbrechen
                    </Button>
                    <Button
                        variant="filled"
                        className="w-full"
                        onClick={handleConfirm}
                        disabled={!token}
                        loading={is_loading}
                    >
                        Weiter
                    </Button>
                </div>
            </div>
        </BaseModal>
    );
};

export default TicketNumberModal;
