import { Button } from '@mantine/core';
import { FC } from 'react';

import Page from '@/components/Page.tsx';

import { googleSignIn } from '@/services/authService.ts';

const LoginPage: FC = () => {
    return (
        <Page className="flex-row" fullScreen>
            <img
                className="h-screen w-[60%] object-cover"
                src="https://assets.website-files.com/61fd7628494324361585dec9/6429a8e8f5406635e276e77d_20230330-MAP_2328-p-2000.jpg"
                alt="Eterno Wartebereich"
            />
            <div className="flex flex-1 flex-col items-center justify-center gap-5">
                <h1 className="text-4xl">Office App Login</h1>
                <Button className="w-100" onClick={googleSignIn}>
                    Eterno Mitarbeiter Login mit Google
                </Button>
                {/*TODO add magic link login*/}
                <Button className="w-100" onClick={() => {}} variant="outline">
                    Externe Anmeldung
                </Button>
            </div>
        </Page>
    );
};

export default LoginPage;
