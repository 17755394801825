import { getCurrentLocation } from '@/constants/mocked_locations.ts';
import { Appointment } from '@/models/appointment/Appointment.ts';
import { Button, Divider, Select, Switch } from '@mantine/core';
import { FileScan, Mail, Printer as PrinterIcon, QrCode } from 'lucide-react';
import { FC, useState } from 'react';
import useSWR from 'swr';
import { v4 as uuidv4 } from 'uuid';

import BaseModal from '@/components/BaseModal.tsx';
import IconButton from '@/components/IconButton.tsx';
import DocumentExchange from '@/components/PatientModal/DocumentExchange.tsx';
import TicketNumberModal from '@/components/PatientModal/TicketNumberModal.tsx';
import FormQRCode from '@/components/form/FormQRCode.tsx';

import { usePatientAppointmentRequiredForms } from '@/hooks/usePatientAppointmentRequiredForms.ts';

import { generateCode, getCodeByAppointment } from '@/services/formDataService.ts';
import { showErrorNotification, showWarningNotification } from '@/services/notificationService.ts';
import { isPrinterLive } from '@/services/printerService.ts';

import { usePrinterStore } from '@/stores/printerStore.ts';

import { prepareGenerateCodeBody } from '@/utils/formUtils.ts';
import { markCheckinAsDone, printTicket } from '@/utils/printUtils.ts';

import PatientAppointmentForms from './PatientAppointmentForms.tsx';

interface Props {
    appointment: Appointment;
}

const PatientAppointmentActions: FC<Props> = ({ appointment }) => {
    const [show_code_modal, setShowCodeModal] = useState(false);
    const [show_checkin_buttons, setShowCheckinButtons] = useState(true);
    const [is_auto_checkin, setIsAutoCheckin] = useState(true);
    const [show_ticket_modal, setShowTicketModal] = useState(false);
    const [is_saving_checkin, setIsSavingCheckin] = useState(false);
    const [form_code, setFormCode] = useState<string>();

    const location = getCurrentLocation();
    const is_call_system_active = location?.config.is_patient_call_system_active;

    const { selected_printer } = usePrinterStore();
    const { formatted_forms_for_appointment } = usePatientAppointmentRequiredForms(appointment);

    const { data: is_printer_live } = useSWR<boolean>(
        ['printer-live', selected_printer[location.key]?.ip_address],
        async () => {
            if (!selected_printer[location.key]?.ip_address) return false;
            return await isPrinterLive(selected_printer[location.key]?.ip_address);
        }
    );

    const checkin = async (manual_ticket?: string | undefined) => {
        if (!formatted_forms_for_appointment) return;

        setIsSavingCheckin(true);
        let ticket: string | undefined = manual_ticket?.toString();

        if (!ticket && is_call_system_active && selected_printer[location.key]) {
            if (is_printer_live) {
                const [success, response] = await printTicket(location, selected_printer[location.key]);
                if (success) {
                    ticket = response as string;
                } else {
                    showErrorNotification('Some issue happened while printing');
                    return;
                }
            } else {
                showWarningNotification('Printer is not live!');
            }
        }
        const is_checkin_marked = await markCheckinAsDone(appointment, location, ticket ?? uuidv4());
        if (!is_checkin_marked) {
            showErrorNotification('Some issue happened while checking');
            return;
        }
        const generate_code_body = prepareGenerateCodeBody(location, appointment, formatted_forms_for_appointment);
        const generated_forms_code = await generateCode(generate_code_body);
        if (generated_forms_code) handleOpenCodeModal(generated_forms_code);
        setIsSavingCheckin(false);
    };

    const handleOpenCodeModal = (code: string) => {
        setFormCode(code);
        setShowCodeModal(true);
    };

    const handleCheckin = async () => {
        if (!is_auto_checkin) {
            setShowTicketModal(true);
            return;
        } else {
            await checkin();
        }
    };

    const handleShowQr = async () => {
        const code = await getCodeByAppointment(
            appointment.id,
            appointment.user_id,
            appointment.location_key,
            appointment.sub_loc_key
        );
        if (code) {
            handleOpenCodeModal(code);
        } else {
            showErrorNotification('No qr code generated yet');
        }
    };

    return (
        <div className="flex flex-col gap-4">
            <Select
                label="Zimmer"
                placeholder="Zimmer wählen"
                // data={roomOptions}
                defaultValue="1"
                className="w-full"
            />
            <PatientAppointmentForms
                appointment={appointment}
                isEditingFormsCallback={(is_editing_form) => setShowCheckinButtons(!is_editing_form)}
            />
            <TicketNumberModal
                opened={show_ticket_modal}
                handleSave={(ticket) => checkin(ticket)}
                onClose={() => setShowTicketModal(false)}
            />
            {show_checkin_buttons && (
                <div className="flex w-full flex-col gap-5">
                    {is_call_system_active && (
                        <div className="flex items-center gap-2">
                            <Switch
                                checked={is_auto_checkin}
                                onChange={() => setIsAutoCheckin((checked) => !checked)}
                                size="md"
                                label="Automatic check-in"
                            />
                        </div>
                    )}
                    <div className="flex w-full justify-between gap-2">
                        <Button
                            className="w-full"
                            loading={is_saving_checkin || !formatted_forms_for_appointment}
                            onClick={handleCheckin}
                        >
                            {`${is_auto_checkin ? '' : 'Manual '}Patient aufnehmen`}
                        </Button>
                    </div>
                </div>
            )}
            <Divider className="w-full" />
            <section className="flex w-full justify-around pt-4">
                <IconButton icon={FileScan} text="Datei einscannen" disabled tooltip="Coming soon" />
                <IconButton icon={Mail} text="Nachricht senden" disabled tooltip="Coming soon" />
                <IconButton icon={PrinterIcon} text="Ticket ausdrucken" />
                <IconButton onClick={handleShowQr} icon={QrCode} text="QR-Code anzeigen" />
            </section>
            <Divider className="w-full" />
            <DocumentExchange appointment={appointment} />
            <BaseModal
                opened={show_code_modal && !!form_code}
                onClose={() => setShowCodeModal(false)}
                title="Lesen Sie den QR-Code"
            >
                <FormQRCode code={form_code!} />
            </BaseModal>
        </div>
    );
};

export default PatientAppointmentActions;
