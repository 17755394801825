import { Appointment } from '@/models/appointment/Appointment.ts';

export const getInsuranceTypeFromAppointment = (appointment: Appointment) => {
    return appointment.data.insurance_coverage_type?.toLowerCase() === 'selfpayer'
        ? 'SELF_PAYER'
        : (appointment.data?.insurance_coverage_type?.toUpperCase() ?? '');
};

export const joinAppointmentServicesKeys = (appointment: Appointment) => {
    return appointment.data.services.map((service) => service.key).join();
};

export const getUserIdFromAppointment = (appointment: Appointment) => {
    if (appointment.user_id && appointment.user_id !== '-') {
        return appointment.user_id;
    }
    return appointment.links.patient_id ?? '-';
};

export const generateAppointmentKey = (instance_id?: string, appointment_id?: string) =>
    `${instance_id}_${appointment_id}`;
