import { getCurrentLocation } from '@/constants/mocked_locations.ts';
import Printer from '@/models/Printer.ts';
import { Button } from '@mantine/core';
import { Blockquote } from '@mantine/core';
import { Pencil } from 'lucide-react';
import { CircleAlert } from 'lucide-react';
import { FC, useEffect, useState } from 'react';

import { usePrinterStore } from '@/stores/printerStore.ts';

import { formatCustomerPrinters } from '@/utils/prismicUtils.ts';

import BaseModal from './BaseModal.tsx';
import SelectableList from './SelectableList.tsx';

const CustomerPrinterSelection: FC = () => {
    const [modal_opened, setModalOpened] = useState(false);
    const location = getCurrentLocation();
    const { selected_printer, setSelectedPrinter } = usePrinterStore();

    useEffect(() => {
        if (!selected_printer[location.key]) {
            setModalOpened(true);
        }
    }, [selected_printer, location.key]);

    const printers: Printer[] = formatCustomerPrinters(location.config.pcs_printers);

    const handlePrinterSelect = (printerId: string) => {
        const printer = printers.find((printer) => printer.ip_address === printerId);
        if (printer) {
            setSelectedPrinter(location, printer);
        }
    };

    const handleSave = () => {
        setModalOpened(false);
    };

    const modal_footer = (
        <div className="flex w-full justify-between">
            <Button variant="outline" onClick={() => setModalOpened(false)}>
                Cancel
            </Button>
            <Button onClick={handleSave}>Save</Button>
        </div>
    );

    return (
        <>
            <Button variant="outline" onClick={() => setModalOpened(true)} rightSection={<Pencil size={16} />}>
                {selected_printer[location.key] ? selected_printer[location.key]?.name : 'Select Printer'}
            </Button>
            <BaseModal
                opened={modal_opened}
                onClose={() => setModalOpened(false)}
                title="Drucker auswählen"
                size="lg"
                footer={modal_footer}
            >
                <div className="flex flex-col gap-4">
                    {!selected_printer[location.key] && (
                        <Blockquote color="red" icon={<CircleAlert />} mt="xl">
                            Es sieht so aus, als hätten Sie keinen Drucker ausgewählt.
                        </Blockquote>
                    )}
                    <SelectableList
                        handleSelect={handlePrinterSelect}
                        selectedItem={selected_printer[location.key]?.ip_address || null}
                        classNames={{
                            wrapper: 'grid grid-cols-2 gap-4',
                            item: 'flex items-center p-1',
                        }}
                    >
                        {printers.map((printer) => (
                            <div key={printer.ip_address} data-key={printer.ip_address} className="flex-1 p-4">
                                {printer.name}
                            </div>
                        ))}
                    </SelectableList>
                </div>
            </BaseModal>
        </>
    );
};

export default CustomerPrinterSelection;
