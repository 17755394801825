import { clsx } from 'clsx';
import { Children, FC, ReactElement, ReactNode } from 'react';

import Heading2 from '../text/Heading2.tsx';

export interface FormSectionProps {
    title?: string;
    children: ReactNode;
    classNames?: {
        title?: string;
        wrapper?: string;
        children?: string;
    };
}

const FormSection: FC<FormSectionProps> = ({ title, children, classNames }) => (
    <>
        {title && (
            <div>
                <Heading2 className={classNames?.title}>{title}</Heading2>
            </div>
        )}
        <div className={clsx('flex flex-wrap justify-between gap-2', classNames?.wrapper)}>
            {Children.map(children, (child) => {
                const element_key = (child as ReactElement).key ?? '';

                return (
                    <div className={clsx('flex-[0_0_49%]', classNames?.children)} key={`wrapper_${element_key}`}>
                        {child}
                    </div>
                );
            })}
        </div>
    </>
);

export default FormSection;
