import { Appointment } from '@/models/appointment/Appointment.ts';
import { FC } from 'react';

import TreatmentItem from '@/components/TreatmentItem.tsx';
import Paragraph from '@/components/text/Paragraph.tsx';

interface Props {
    appointments: Appointment[];
}

const AppointmentHistory: FC<Props> = ({ appointments }) => {
    return (
        <div className="flex w-full flex-col gap-4">
            <Paragraph className="text-sm uppercase text-gray-500">Buchungshistorie</Paragraph>
            <div className="flex flex-col gap-4">
                {appointments.map((appointment) => (
                    <TreatmentItem key={appointment.id} appointment={appointment} />
                ))}
            </div>
        </div>
    );
};

export default AppointmentHistory;
