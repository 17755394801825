import { Appointment } from '@/models/appointment/Appointment.ts';
import { UserAppointmentDto } from '@/models/call-system/dtos/user-appointment-dto.ts';
import { FC } from 'react';

import PendingAppointmentCard from '@/components/room-view/AppointmentCard/PendingAppointmentCard.tsx';
import ResolvedAppointmentCard from '@/components/room-view/AppointmentCard/ResolvedAppointmentCard.tsx';

interface Props {
    isPending: boolean;
    appointment: Appointment;
    checkInData?: UserAppointmentDto;
    onClick: (appointment: Appointment) => void;
}

const AppointmentCard: FC<Props> = ({ isPending, ...restProps }) => {
    if (isPending) return <PendingAppointmentCard {...restProps} />;

    return <ResolvedAppointmentCard {...restProps} />;
};

export default AppointmentCard;
