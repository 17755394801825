import { Divider, MantineSize, Modal } from '@mantine/core';
import { clsx } from 'clsx';
import { X } from 'lucide-react';
import { FC, ReactNode } from 'react';

interface Props {
    opened: boolean;
    onClose: () => void;
    title?: ReactNode;
    children: ReactNode;
    size?: MantineSize;
    classNames?: {
        header: string;
    };
    footer?: ReactNode;
}

// TODO: create BaseModalBody and BaseModalFooter components
const BaseModal: FC<Props> = ({ opened, onClose, title, children, size = 'xl', classNames, footer }) => {
    return (
        <Modal
            opened={opened}
            onClose={onClose}
            size={size}
            centered
            withCloseButton
            closeButtonProps={{
                icon: <X className="size-5/6" />,
            }}
            classNames={{
                header: clsx('bg-gradient-to-r from-primaryLight to-primary py-5 text-slate-100', classNames?.header),
                title: 'text-lg font-bold',
                content: 'rounded-md shadow-2xl',
                body: 'bg-slate-100 p-0 pt-2',
                close: 'text-slate-100 transition hover:text-black',
            }}
            title={title}
            overlayProps={{
                className: 'bg-black bg-opacity-30 backdrop-blur',
            }}
        >
            <div className="px-4">{children}</div>
            {footer && (
                <div className="relative bg-slate-200">
                    <Divider className="mt-3" />
                    <div
                        className={clsx(
                            'flex h-full w-full flex-row items-center p-4',
                            Array.isArray(footer) ? 'justify-between' : 'justify-end'
                        )}
                    >
                        {footer}
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default BaseModal;
