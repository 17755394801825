import { AuthUserHook } from '@/models/Hooks.ts';
import { AuthSession, fetchAuthSession } from 'aws-amplify/auth';

import { useAuthStore } from '@/stores/authStore.ts';

const useAuth = (): AuthUserHook => {
    const {
        user: auth_user,
        is_admin,
        user_email: email,
        user_scopes: scopes,
        user_groups: groups,
        user_instance_ids: instance_ids,
        user_customer_ids: customer_ids,
    } = useAuthStore((state) => state);

    const getIdToken = async (): Promise<string | undefined> => {
        const session: AuthSession = await fetchAuthSession();

        return session.tokens?.idToken?.toString();
    };

    const getAccessToken = async (): Promise<string | undefined> => {
        const session: AuthSession = await fetchAuthSession();

        return session.tokens?.accessToken?.toString();
    };

    const getSession = async (): Promise<AuthSession> => {
        const session: AuthSession = await fetchAuthSession();

        return session;
    };

    const isUserInGroup = (groups_to_check: string | string[]): boolean => {
        if (is_admin) return true;

        const groups_array: string[] = Array.isArray(groups_to_check) ? groups_to_check : [groups_to_check];
        const user_groups_set = new Set(groups);

        return groups_array.some((group) => user_groups_set.has(group));
    };

    return {
        auth_user,
        is_admin,
        email,
        scopes,
        groups,
        instance_ids,
        customer_ids,
        getIdToken,
        getAccessToken,
        getSession,
        isUserInGroup,
    };
};

export default useAuth;
