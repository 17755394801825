import { Appointment } from '@/models/appointment/Appointment.ts';
import FormattedForm from '@/models/forms/FormattedForm.ts';
import { FormTemplate } from '@/models/forms/backend-types/form-template/FormTemplate.ts';
import { Button } from '@mantine/core';
import { FC, useState } from 'react';

import EternoChip from '@/components/EternoChip.tsx';

import { saveSelectedFormsForAppointment } from '@/services/formDataService.ts';

import { filterAndFormatFormsToSave, getFormNameFromFormattedForm, toggleSelectedForm } from '@/utils/formUtils.ts';

interface Props {
    appointment: Appointment;
    professional_form_templates: FormTemplate[];
    formatted_forms_for_appointment: FormattedForm[];
    onSave: () => Promise<void>;
    onCancel: () => void;
}

const EditSelectedForms: FC<Props> = ({
    appointment,
    professional_form_templates,
    formatted_forms_for_appointment,
    onSave,
    onCancel,
}) => {
    const [is_saving, setIsSaving] = useState(false);
    const [selected_forms, setSelectedForms] = useState<string[]>(
        formatted_forms_for_appointment?.map((form) => form.id) ?? []
    );

    const handleSaveForms = async () => {
        setIsSaving(true);
        const saved_forms = await saveSelectedFormsForAppointment({
            appointment_id: appointment.id,
            user_id: appointment.user_id,
            data: {
                forms: filterAndFormatFormsToSave(professional_form_templates, selected_forms),
            },
        });
        if (saved_forms) await onSave();
        setIsSaving(false);
    };

    return (
        <div className="flex w-full flex-wrap gap-4">
            {professional_form_templates?.map((form) => (
                <EternoChip
                    key={form.id}
                    checked={selected_forms.includes(form.id)}
                    onChange={() => setSelectedForms((forms) => toggleSelectedForm(forms, form.id))}
                    label={getFormNameFromFormattedForm(form)}
                />
            ))}
            <div className="flex w-full justify-between gap-2">
                <Button className="w-1/2" disabled={is_saving} onClick={onCancel} variant="outline">
                    Abbrechen
                </Button>
                <Button className="w-1/2" loading={is_saving} onClick={handleSaveForms}>
                    Speichern
                </Button>
            </div>
        </div>
    );
};

export default EditSelectedForms;
