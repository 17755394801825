import { EnrichedCustomer, EnrichedLocation } from '@/models/EnrichedPrismicDocuments.ts';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type ConfigStore = {
    selected_customer: EnrichedCustomer | null;
    selected_location: EnrichedLocation | null;
    user_customers: EnrichedCustomer[];
};

export const useConfigStore = create<ConfigStore>()(
    persist(
        () =>
            ({
                selected_customer: null,
                selected_location: null,
                user_customers: [],
            }) as ConfigStore,
        {
            name: 'config_store',
        }
    )
);
