import { AuthUser, getCurrentUser } from 'aws-amplify/auth';
import { useEffect } from 'react';

import { loadUserProperties } from '@/services/authService.ts';

import { useAuthStore } from '@/stores/authStore.ts';

const useLoadUser = () => {
    useEffect(() => {
        const loadUser = async () => {
            let user: AuthUser | null = null;
            try {
                user = await getCurrentUser();
            } catch (error) {
                console.debug({ error });
            }

            await loadUserProperties();
            useAuthStore.setState({ user: user, is_user_set: true });
        };
        loadUser();
    }, []);
};

export default useLoadUser;
