import { ComboboxItem, MultiSelect, SegmentedControl, Select, TextInput } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { Calendar, List, Search, SquareKanban } from 'lucide-react';
import { FC, useState } from 'react';

import { ListViewMode } from '@/components/ListView/ListViewMode.ts';

interface Props {
    practitionerOptions: ComboboxItem[];
    roomOptions: ComboboxItem[];
    defaultView: ListViewMode;
}

const ListViewHeader: FC<Props> = ({ practitionerOptions, roomOptions, defaultView }) => {
    const [practitioner, setPractitioner] = useState<ComboboxItem>(practitionerOptions[0]);
    const [rooms, setRooms] = useState<string[]>([]);
    const [date, setDate] = useState<Date | null>(new Date());

    return (
        <div className="flex gap-5">
            <TextInput
                placeholder="Name, Geb. Datum, Email, Telefon, DC ID"
                rightSectionPointerEvents="none"
                rightSection={<Search className="size-6" />}
                className="flex-grow"
            />
            <Select
                value={practitioner.value}
                onChange={(_value, option) => setPractitioner(option)}
                data={practitionerOptions}
                className="flex-grow"
            />
            <MultiSelect
                value={rooms}
                data={roomOptions}
                onChange={setRooms}
                placeholder="Please select"
                className="flex-grow"
            />
            <DatePickerInput
                value={date}
                onChange={setDate}
                rightSection={<Calendar />}
                rightSectionPointerEvents="none"
                className="w-48"
            />
            <SegmentedControl
                value={defaultView}
                disabled
                data={[
                    { label: <List />, value: ListViewMode.LIST },
                    { label: <SquareKanban />, value: ListViewMode.KANBAN },
                ]}
            />
        </div>
    );
};

export default ListViewHeader;
