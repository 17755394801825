import { BadgeProps, Badge as MantineBadge } from '@mantine/core';
import { FC } from 'react';

interface StatusBadgeProps {
    text: string;
    size?: BadgeProps['size'];
    backgroundColor?: string;
}

const Badge: FC<StatusBadgeProps> = ({ text, size = 'md', backgroundColor }) => {
    return (
        <MantineBadge
            size={size}
            variant="light"
            color={backgroundColor}
            classNames={{
                label: 'font-medium normal-case',
            }}
        >
            {text}
        </MantineBadge>
    );
};

export default Badge;
