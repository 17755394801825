import { Appointment } from '@/models/appointment/Appointment.ts';
import { CheckInDataMap } from '@/models/call-system/dtos/user-appointment-dto.ts';
import { useMemo } from 'react';
import useSWR from 'swr';

import useConfig from '@/hooks/useConfig.ts';

import { fetchAppointmentsCheckInData } from '@/services/appointmentService.ts';

import { generateAppointmentKey } from '@/utils/appointmentUtils.ts';

const useCheckInData = (appointments?: Appointment[]) => {
    const { customer_id } = useConfig();

    const { data, isLoading: is_loading } = useSWR(
        appointments
            ? { key: `checkin-data-for-${customer_id}-today`, appointments: appointments, customer_id: customer_id }
            : null,
        fetchAppointmentsCheckInData
    );

    const check_in_data: CheckInDataMap = useMemo(
        () =>
            data
                ? new Map(data.map((cur) => [generateAppointmentKey(cur.instance_id, cur.appointment_id), cur]))
                : undefined,
        [data]
    );

    return { check_in_data, is_loading };
};

export default useCheckInData;
